import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-dialog-datos-guardados',
  templateUrl: './dialog-datos-guardados.component.html',
  styleUrls: ['./dialog-datos-guardados.component.css']
})
export class DialogDatosGuardadosComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
