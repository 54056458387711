import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { User } from '../_models/user.model';
import { Router } from '@angular/router';
import { AppComponent } from '../app.component';
import { Concepto, ConceptoAdapter } from '../_models/concepto.model';
import { Adapter } from '../_adapters/adapter';

const httpOptions = {
  headers: new HttpHeaders({})
};

@Injectable({ providedIn: 'root' })

export class BalanceService {

    baseUrl: string;

    constructor(
        private http: HttpClient,
        private adapter: ConceptoAdapter,
        private router: Router) {
          
            this.baseUrl = AppComponent.baseUrl;
    }


    cargarConceptosBalance(){
        return this.http.get<Concepto[]>(this.baseUrl + '/enfermeria/seguro/obtener-conceptos-balanceH-vigentes').pipe(
            // Adapt each item in the raw data array
            
            map((data: any[]) => data.map(item => this.adapter.adapt(item))),
          );
    }

    registrar(form){
        return this.http.post<any>(this.baseUrl + '/enfermeria/seguro/registrar-balanceH', form)
    }

    borrarRegistro(registro){
        return this.http.post<any>(this.baseUrl + '/enfermeria/seguro/borrar-balanceH', registro)
    }

    cargarRegistrosDeConcepto(concepto, carpeta){
        return this.http.post<any>(this.baseUrl + '/enfermeria/seguro/obtener-registros-balanceH-de-concepto/' + carpeta, concepto)
    }
  
}
