import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { UntypedFormGroup, Validators, UntypedFormBuilder } from '@angular/forms';
import { InternacionService } from '../_services/internacion.service';
import { AuthenticationService } from '../_services/authentication.service';
import { CamasService } from '../_services/camas.service';
import * as moment from 'moment';
import { DialogMensajeComponent } from '../dialog-mensaje/dialog-mensaje.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-dialog-traslado-paciente',
  templateUrl: './dialog-traslado-paciente.component.html',
  styleUrls: ['./dialog-traslado-paciente.component.css']
})

export class DialogTrasladoPacienteComponent implements OnInit {
 
  trasladoForm: UntypedFormGroup;
  pabellones;
  pabellonElegido: any;
  submitted = false;
  traslado;
  currentUser;

  listaEstados= [
    {nombre: 'Libre', id: 1,checked: false},
    {nombre: 'En Limpieza', id: 7,checked: true}
  ]

  camasLibres: any[];

  constructor(
    private internacionService: InternacionService,
    public dialog: MatDialogRef<DialogTrasladoPacienteComponent>,
    public dialogo: MatDialog,
    private formBuilder: UntypedFormBuilder,
    private authenticationService: AuthenticationService,
    private camasService: CamasService,
    @Inject(MAT_DIALOG_DATA) public data
  ){

    }

  async ngOnInit() {
    this.crearFormularioTraslado();
    this.currentUser = this.authenticationService.currentUserValue;
    await this.cargarPabellones();
  }

  crearFormularioTraslado(){
    this.trasladoForm = this.formBuilder.group({
      paciente: [{value: this.data.paciente, disabled: true},
                  Validators.required],
      camaAct: [{value: this.data.pieza + ' - ' + this.data.cama, disabled: true },
            Validators.required],
      estadoCamaAct: [{nombre: 'En Limpieza', id: 7,checked: true}, Validators.required],
      camaNueva: [null, Validators.required],
      pabellonElegido: [null]
    });
  }

  async cargarPabellones(){
    await this.internacionService.obtenerPabellones().toPromise().then(
      (data) => {
        if(data.ok){
          this.pabellones = data.paginador.elementos;
          if (sessionStorage.getItem('pabellon')) {
          
             this.trasladoForm.get('pabellonElegido').setValue(
                this.pabellones.find(
                  (pabe) => pabe.pabellon == JSON.parse(sessionStorage.getItem('pabellon')).pabellon
                )
            );
          } else {
            this.trasladoForm.get('pabellonElegido').setValue(this.pabellones[0]);
          }
          this.getCamasLibres(this.trasladoForm.get('pabellonElegido').value);
        }
      },
      (error) => {
        console.log(error);
      }
    );
  }

  getCamasLibres(pabellonElegido){

    this.camasService.getCamasPorFiltro(pabellonElegido.pabellon,this.data.sexo,1).subscribe((data) => {
      //console.log(data);
      this.camasLibres = data;
      if(this.camasLibres.length>0){
        this.trasladoForm.get('camaNueva').setValue(this.camasLibres[0]);
      } else {
        this.trasladoForm.get('camaNueva').setValue(null);
      }
      // console.log(this.trasladoForm.get('pabellonElegido'));
    });
  }

  cerrarDialogo(): void {
    this.dialog.close(false);
  }

  confirmado(): void {
    this.dialog.close(true);
  }

  onSubmit() {
      this.submitted = true;
      if (!this.trasladoForm.valid) {
        
        return;
      }
    
      this.traslado = {
        carpeta: this.data.carpeta,
        fechaIngreso:  moment(new Date()).format('DD-MM-YYYY HH:mm:ss'),
        pieza: this.trasladoForm.get('camaNueva').value.pieza,
        cama: this.trasladoForm.get('camaNueva').value.cama,
        acompanante: this.data.acompanante,
        usuarioAdminsion: this.currentUser.usuario ,
        estado: this.trasladoForm.get('estadoCamaAct').value.id
      };

      // console.log('onSubmit -> this.traslado', this.traslado);

      this.internacionService.setTrasladoPaciente(this.traslado).subscribe(
        (data) => {
          
          if (data.ok) {
            this.submitted = false;
          }
          this.abrirDialogMensaje(data);
        },
        (error) => {
          console.log(error);
          this.cerrarDialogo();
        }
      );
  }

  getErrorMessage(nomCampo) {
    this.trasladoForm.get(nomCampo).markAsTouched;
    return this.trasladoForm.get(nomCampo)
    .hasError('required') ? 'Este campo es obligatorio' : '';
  }

  abrirDialogMensaje(resp) {
    this.dialogo.open(DialogMensajeComponent, {
      width: '500px',
      height: '100px',
      data: resp,
    }).afterClosed()
    .subscribe(
      (confirmado: boolean) => {
        if (confirmado) {
          this.confirmado();
        } else {
          this.cerrarDialogo();
        }
      },
      (error) => {
        console.log(error);
      }
    );
  }

}