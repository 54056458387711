<button mat-icon-button style="float: right;top:-24px;right:-24px;" [mat-dialog-close]="false">
    <mat-icon class="close-icon" color="warn">close</mat-icon>
</button>
<h1 mat-dialog-title style="padding-left: 0%;padding-right: 3.5%;">Buscar paciente</h1>
<div mat-dialog-content style="padding-left: 5%;padding-right: 5%;">
    <div class="row">
        <div class="col-5">
            <mat-form-field>
                <mat-label>Num. Documento</mat-label>
                <input matInput [(ngModel)]="nroDoc">
            </mat-form-field>
        </div>
        <div class="col-5">
            <mat-form-field>
                <mat-label>Nombre y Apellido</mat-label>
                <input matInput [(ngModel)]="nombre">
            </mat-form-field>
            
        </div>
        
        <div class="col-2">
            <button mat-icon-button  (click)="buscarPaciente()">
                <mat-icon >search</mat-icon>
            </button>
        </div>
    </div>
    <div>
        
    </div>
</div>
<mat-divider></mat-divider>
<div class="d-flex justify-content-center" style="margin-top:10%;" *ngIf="loading">
    <mat-spinner [diameter]="30"></mat-spinner>
</div>
<div *ngIf="!loading">
    <div *ngIf="this.pacientes.length == 0 && searched " style="text-align: center; margin-top: 2%;">
        <h6  >
            No se encontraron pacientes
        </h6>
        <button mat-icon-button (click)="openCrearPacienteDialog()" >
            <mat-icon color="primary">person_add</mat-icon>
        </button>
    </div>
    <mat-list [ngClass]="{'searcher' : this.pacientes.length > 0}">
        <mat-list-item *ngFor="let p of pacientes">
            <span matListAvatar style="margin-top:4%">
                <div>
                    <mat-icon>person</mat-icon>
                </div>
            </span>
            <h5 matLine>{{p.apellido | toTitleCase}}
            </h5>
            <p matLine>
                <span style="padding:0" mat-subheader>{{p.nroDocumento}} - {{ edadPaciente(p)}} {{p.sexo}}</span>
            </p>

            <mat-action-list *ngIf="!data.verPaciente" style=" width:40%; text-align: right;">
                <button mat-icon-button [mat-dialog-close]="p" color="primary">
                    <mat-icon style="font-size:25px;">insert_invitation</mat-icon>
                </button>
            </mat-action-list>
            <mat-action-list *ngIf="data.verPaciente" style=" width:40%; text-align: right;">
                <button mat-icon-button [mat-dialog-close]="p" color="primary">
                    <mat-icon matTooltip="Crear Triage" style="font-size:25px;">add</mat-icon>
                </button>
            </mat-action-list>
            <br>
            <mat-divider></mat-divider>
           
        </mat-list-item>
        <mat-action-list *ngIf="searched && this.pacientes.length > 0" style="text-align: center; margin-top: 2%;">
            <h6>Agregar Paciente</h6>
            <button mat-icon-button (click)="openCrearPacienteDialog()" >
             <div>
                <mat-icon color="primary">person_add</mat-icon>
            </div>
            </button>
        </mat-action-list>
    </mat-list>
</div>