import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { User } from '../_models/user.model';
import { Router } from '@angular/router';
import { AppComponent } from '../app.component';

const httpOptions = {
  headers: new HttpHeaders({})
};

@Injectable({ providedIn: 'root' })

export class AuthenticationService {

    baseUrl: string;

    private currentUserSubject: BehaviorSubject<User>;

    public currentUser: Observable<User>;

    constructor(
        private http: HttpClient,
        private router: Router) {
            this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(sessionStorage.getItem('currentUser')));
            this.currentUser = this.currentUserSubject.asObservable();
            this.baseUrl = AppComponent.baseUrl;
    }

    public get currentUserValue(){
        return this.currentUserSubject.value;
    }

    

    login(username: string, pass: string) {
        httpOptions.headers.append('Accept', 'application/json');
        httpOptions.headers.append('Content-Type', 'application/json;charset=utf8');
        
        return this.http.post<any>(this.baseUrl + '/login/doLogin',
        JSON.stringify({
            usuario: username,
            password: pass,
            numeroSitio: 3
        }
            ))
            .pipe(map( resp => {
                if (resp.user && resp.user.token) {
                    sessionStorage.setItem('currentUser', JSON.stringify(resp.user));
                    
                    this.currentUserSubject.next(resp.user);
                }

                return resp;
            }));
    }

    logout() {
        sessionStorage.removeItem('currentUser');
        this.currentUserSubject.next(null);
    }
}
