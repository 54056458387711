import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { UntypedFormGroup } from '@angular/forms';
import { Validators } from '@angular/forms';
import { PacienteService } from '../_services/paciente.service';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { AlertService } from '../_services/alert.service';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-signos-vitales',
  templateUrl: './signos-vitales.component.html',
  styleUrls: ['./signos-vitales.component.css']
})
export class SignosVitalesComponent implements OnInit {
  @Input() impresionFormHijo: UntypedFormGroup;
  time = new Date();
  signosForm: UntypedFormGroup;
  signosFormAux: any;
  currentPatient: any;
  patientData:any;
  submitted: boolean = false;
  loading: boolean = false;
  searched: boolean = false;
  rangoTemperatura = true;
  habilitarAvance: boolean = false;
  escalaGlasgow = [
    { value: 3 },
    { value: 4 },
    { value: 5 },
    { value: 6 },
    { value: 7 },
    { value: 8 },
    { value: 9 },
    { value: 10 },
    { value: 11 },
    { value: 12 },
    { value: 13 },
    { value: 14 },
    { value: 15 }
  ];
  
  constructor(private formBuilder: UntypedFormBuilder,
    private pacienteService: PacienteService,
    private alertService: AlertService,
    public dialog: MatDialog,
    private router: Router) { 
      this.currentPatient = this.pacienteService.getCurrentPatientLocal(); //me guardo el paciente actual
      // console.log('current patient',this.currentPatient)
      this.signosFormAux = this.pacienteService.getCurrentSignosFormLocal();
    
    }
     
   
  ngOnInit() {
    // this.buildForm(); 
    
  }
 
 
  guardarSignosVitales(){
    
    this.submitted = true;
          if (!this.signosForm.valid) {
            // console.log('no es valido')
            return;
          }
          this.loading = true;
          // console.log(this.signosForm.value)
          this.pacienteService
            .finTriage(
             this.signosForm.value).subscribe(
              (data) => {
                // console.log(data);
                  if (data.ok) {
                    // console.log('todo ok')
                    this.pacienteService.setCurrentSignosFormLocal(this.signosForm);
                    this.submitted=false;
                    // console.log(data);
                  }
        
                  },
                  
  
              (error) => {
                // this.alertService.error('Se genero un error');
                console.log(error);
                this.loading = false;
                this.signosForm.reset();
              }
              
            );
        //    si alguno de los valores ingresados esta fuera de rango 
      if(this.sintomaFueradeRango()){
        //  console.log('Valor de isUrgencia', this.sintomaFueradeRango())
          this.signosUrgencia(this.signosForm);
      }else{
       // console.log(this.currentPatient)
       this.sintomasPaciente(this.currentPatient); //si no es prioridad 1, redirecciono a la siguiente vista
      
    }
    

 
  }

  volver() {
    this.router.navigate(['/crear-triage']);
  }
  getErrorMessage(Campo) {
    return this.signosForm.get(Campo)
    .hasError('pattern') ? 'Solo se aceptan numeros' : null;
  }


  sintomasPaciente(paciente){
    // console.log(paciente);
    this.pacienteService.setCurrentPatientLocal(paciente);
    this.pacienteService.setCurrentSignosFormLocal(this.signosForm);
    this.router.navigate(['/sintomas-paciente']);
  }


//con esta funcion verifico los valores ingresados y si alguno esta fuera de rango es urgencia 
    sintomaFueradeRango(){
       let isUrgencia: boolean = false;
       
      
       //presionSistolica
       if( this.signosForm.value.presionSis != null && (this.signosForm.value.presionSis < 70 || this.signosForm.value.presionSis > 200) && this.signosForm.value.presionSis != ''){
        this.signosForm.value.subcatTriage.prioridad.idPrioridad = 1
        isUrgencia = true;
       }
       //presionDiastolica
       if(this.signosForm.value.presionDias != null && (this.signosForm.value.presionDias < 40 || this.signosForm.value.presionDias  > 110 && this.signosForm.value.presionDias != '')){
        this.signosForm.value.subcatTriage.prioridad.idPrioridad = 1
         isUrgencia = true;
       }
       //pulso
       if(this.signosForm.value.pulsoCardiaco != null && (this.signosForm.value.pulsoCardiaco < 40 || this.signosForm.value.pulsoCardiaco > 150 && this.signosForm.value.pulsoCardiaco != '')){
        this.signosForm.value.subcatTriage.prioridad.idPrioridad = 1
         isUrgencia = true;
       }
       //temperatura
       if(this.signosForm.value.tempCorporal != null &&(this.signosForm.value.tempCorporal < 30 || this.signosForm.value.tempCorporal> 45 && this.signosForm.value.tempCorporal != '')){
        this.signosForm.value.subcatTriage.prioridad.idPrioridad = 1
         isUrgencia = true;
       }
       //saturacion
       if(this.signosForm.value.saturacion != null && (this.signosForm.value.saturacion < 90 )&& this.signosForm.value.saturacion != ''){
        this.signosForm.value.subcatTriage.prioridad.idPrioridad = 1
        isUrgencia = true;
       }
       //glasgow
       if(this.signosForm.value.glasgow != null && (this.signosForm.value.glasgow < 9 )&& this.signosForm.value.glasgow != ''){
        this.signosForm.value.subcatTriage.prioridad.idPrioridad = 1
        isUrgencia = true;
       }
      if(isUrgencia){
        this.habilitarAvance =false;
      }
       
      //  console.log(isUrgencia);
       return isUrgencia
     }


     //cuando algun signo esta fuera de rango seteo el form con los datos y muestro la info
     
     signosUrgencia(signosForm){
      this.pacienteService.setCurrentSignosFormLocal(signosForm);      
      this.router.navigate(['/visualizacion-paciente-triage']);
     }

 //esta funcion es para verificar que se completo al menos un campo del formulario
   ingresoDato(change){
    // console.log(change.target.value)
    if(change.target.value != '')
     this.habilitarAvance = true;
     }

  ingresoTemperatura(evento) {
    if (evento.target.value) {
      if (evento.target.value < 30 || evento.target.value > 45) {
        this.rangoTemperatura = false;
      }
      else {
        this.rangoTemperatura = true;
      }
    } else {
      this.rangoTemperatura = true;
    }
  }

}
