import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { UntypedFormGroup, Validators, UntypedFormBuilder } from '@angular/forms';
import { InternacionService } from '../_services/internacion.service';
import { AuthenticationService } from '../_services/authentication.service';
import { CamasService } from '../_services/camas.service';
import * as moment from 'moment';
import { DialogMensajeComponent } from '../dialog-mensaje/dialog-mensaje.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-dialog-asignacion-cama',
  templateUrl: './dialog-asignacion-cama.component.html',
  styleUrls: ['./dialog-asignacion-cama.component.css']
})
export class DialogAsignacionCamaComponent implements OnInit {
 
  asignacionForm: UntypedFormGroup;
  pabellones;
  pabellonElegido: any;
  submitted = false;
  
  currentUser;
  paciente;

  camas: any[];

  constructor(
    private internacionService: InternacionService,
    public dialog: MatDialogRef<DialogAsignacionCamaComponent>,
    public dialogo: MatDialog,
    private formBuilder: UntypedFormBuilder,
    private authenticationService: AuthenticationService,
    private camasService: CamasService,
    @Inject(MAT_DIALOG_DATA) public data
  ){

    }

  async ngOnInit() {
    this.crearFormularioTraslado();
    this.currentUser = this.authenticationService.currentUserValue;
    await this.cargarPabellones();
  }

  crearFormularioTraslado(){
    this.asignacionForm = this.formBuilder.group({
      camaNueva: [null, Validators.required],
      pabellonElegido: [null]
    });
  }

  async cargarPabellones(){
    await this.internacionService.obtenerPabellones().toPromise().then(
      (data) => {
        if(data.ok){
          this.pabellones = data.paginador.elementos;
          if (this.data.pabellon !== '0') {
             this.asignacionForm.get('pabellonElegido').setValue(
                this.pabellones.find(
                  (pabe) => pabe.pabellon == this.data.pabellon
                )
            );
          } else {
            this.asignacionForm.get('pabellonElegido').setValue(this.pabellones[0]);
          }
          this.getCamas(this.asignacionForm.get('pabellonElegido').value);
        }
      },
      (error) => {
        console.log(error);
      }
    );
  }

  getCamas(pabellonElegido){

    this.camasService.getCamasPorFiltro(pabellonElegido.pabellon,null,null).subscribe((data) => {
      this.camas = data;
      if (this.data.cama !== '' && this.data.pieza !=='') {
          
        this.asignacionForm.get('camaNueva').setValue(
           this.camas.find(
             (cama) => (cama.cama == this.data.cama && cama.pieza == this.data.pieza)
           )
       );
     } else {
       this.asignacionForm.get('camaNueva').setValue(null);
     }
    });
  }

  cerrarDialogo(): void {
    this.dialog.close(false);
  }

  confirmado(): void {
    this.dialog.close(true);
  }

  onSubmit() {
      this.submitted = true;
      if (!this.asignacionForm.valid) {
        
        return;
      }
    
      this.paciente = {
        fechaInternacion:  this.data.fechaInternacion,
        orden: this.data.orden,
        cama: this.asignacionForm.get('camaNueva').value.cama,
        pieza: this.asignacionForm.get('camaNueva').value.pieza,
        usuario: this.currentUser.usuario
      };

      // console.log('onSubmit -> this.paciente', this.paciente);

       this.internacionService.setCamaPacienteEnEspera(this.paciente).subscribe(
        (data) => {
          
          if (data.ok) {
            this.submitted = false;
          }
          this.abrirDialogMensaje(data);
        },
        (error) => {
          console.log(error);
          this.cerrarDialogo();
        }
      );
  }

  getErrorMessage(nomCampo) {
    this.asignacionForm.get(nomCampo).markAsTouched;
    return this.asignacionForm.get(nomCampo)
    .hasError('required') ? 'Este campo es obligatorio' : '';
  }

  abrirDialogMensaje(resp) {
    this.dialogo.open(DialogMensajeComponent, {
      width: '500px',
      height: '100px',
      data: resp,
    }).afterClosed()
    .subscribe(
      (confirmado: boolean) => {
        if (confirmado) {
          this.confirmado();
        } else {
          this.cerrarDialogo();
        }
      },
      (error) => {
        console.log(error);
      }
    );
  }

}
