import { Component, OnInit } from '@angular/core';
import { PacienteService } from '../_services/paciente.service';
import { UntypedFormBuilder, FormGroupDirective, FormGroup, FormControl, Validators } from '@angular/forms';
import { AlertService } from '../_services/alert.service';
import { AuthenticationService } from '../_services/authentication.service';
import { MedicacionService } from '../_services/medicacion.service';

@Component({
  selector: 'app-historial-indicacion-medica',
  templateUrl: './historial-indicacion-medica.component.html',
  styleUrls: ['./historial-indicacion-medica.component.css']
})
export class HistorialIndicacionMedicaComponent implements OnInit {
  currentPatient: any;
  currentUser: any
  indicaciones: any;

  constructor(private pacienteService: PacienteService, 
              private formBuilder: UntypedFormBuilder,
              private medicacionService: MedicacionService,   
              private alertService: AlertService,
              private authenticationService: AuthenticationService) { 

          this.pacienteService.currentPatient.subscribe(
            x => {
              this.currentPatient =  x  
            }
          )

          this.authenticationService.currentUser.subscribe(
            x => {
             this.currentUser = x; 
            }
          )

  }

  ngOnInit() {

    let respuesta
  
    this.medicacionService.historialIndicaciones(this.currentPatient.carpeta).subscribe(
       x => {
         this.indicaciones = x.paginador.elementos
       }
    )

  }

}
