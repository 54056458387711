import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { AppComponent } from '../app.component';

const httpOptions = {
  headers: new HttpHeaders({})
};

@Injectable({
  providedIn: 'root'
})
export class PedidosService {

  baseUrl: string;

  constructor(
    private http: HttpClient,
    private router: Router
  ) { 
    this.baseUrl = AppComponent.baseUrl;
  }

  getPedidosGenerico(pedido) {
    // console.log(pedido);
    return this.http.post<any>(this.baseUrl + '/historiaClinica/pedidos/seguro/getPedidosGenericoSinFechas', JSON.stringify(pedido));
  }

  getCirugiasGenerico(cirugia) {
    return this.http.post<any>(this.baseUrl + '/cirugias/seguro/getCirugiasGenerico', JSON.stringify(cirugia));
  }
}