<div class="container" style="margin-top:1%" *ngIf="!selected">
  
  <div class="row" style="margin-top:3%; justify-content: center; ">
    
    <div class="col-3">
     
    </div>
    <div id="header" class="col-3" >       
      <mat-form-field class="example-full-width">
        <mat-label>Elija una fecha</mat-label>
        <input matInput [(ngModel)]="fechaSeleccionada" [max]="maxDate" [matDatepicker]="picker" (dateChange)="onDateChange($event)" disabled>
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker disabled="false"></mat-datepicker>
      </mat-form-field>
    </div>
  </div>  
  <div class="d-flex justify-content-center" style="margin-top:10%"  *ngIf="loading">
    <mat-spinner [diameter]="60"></mat-spinner>   
  </div>
  <alert style="font-size: 12px"></alert>
  <div class="d-flex justify-content-center" style="margin-top:10%"  *ngIf="loadingReportes">
    <mat-spinner [diameter]="60"></mat-spinner>   
  </div>
  <div class="col-md-6 offset-md-3" *ngIf="!loadingReportes">
    <div style="text-align: center; vertical-align: middle; margin-top: 15%;" *ngIf="reportes && reportes.length==0">
      <h6>No hay reportes para esta fecha</h6>
    </div>
    
    <mat-list *ngIf="reportes && reportes.length>0">
      <mat-list-item *ngFor="let reporte of reportes">         
        <mat-icon mat-list-icon>note</mat-icon>
        <h5 matLine>Carpeta: {{reporte.carpeta}}</h5>
        <p  matLine><span style="padding:0" mat-subheader>{{reporte.fechaHasta}}</span> </p>
              
        <mat-action-list style=" width:50%; text-align: right;">
          <button mat-icon-button color="primary" (click)="imprimirReporteSeleccionado(reporte.idReporte)" ><mat-icon>folder</mat-icon></button>
        </mat-action-list>   
      </mat-list-item> 
    </mat-list>
  </div>
    
  
</div>

<div class="container-fluid" *ngIf="selected">
    <button   mat-button  (click)="salirPdf()" ><mat-icon  >keyboard_backspace</mat-icon></button>

    <div class="row">
        
    </div>
    <br>
    <div class="container">             
        <embed type="application/pdf" [src]="selectedUrl | safeUrl" width="103.5%;"  height="500px;"  >
    </div>
</div>