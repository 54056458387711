<div class="container" *ngIf="!selected">
    <br />

    <div class="row">
        <div id="datosPaciente" class="col-md">
            <button mat-raised-button color="primary" class="float-right" (click)="back()">Volver</button>
            <h3>{{currentPatient.paciente}}</h3>
            <div>
                <b>Fecha ingreso:</b> {{ currentPatient.fechaIngreso }}, {{ currentPatient.cantDias }} días internado
            </div>
            <div>
                <b>Medico cabecera:</b> {{ currentPatient.profCabecera | toTitleCase }}
            </div>
            <div>
                <b>Mutual:</b> {{ currentPatient.mutuales | toTitleCase }}
            </div>
            <div>
                <b>Ubicacion:</b> {{currentPatient.pieza}} - {{currentPatient.cama}}
            </div>
            <span (click)="irAlHistorial()" style="cursor: pointer"><b>Carpeta:</b><a href="javascript:;"> {{ currentPatient.carpeta }} </a></span>
            <div>

                <span>
          <mat-icon
            *ngIf="false"
            style="
              -webkit-text-fill-color: green;
              float: right;
              margin-right: 10%;
              cursor: pointer;
            "
            (click)="imprimirReportePorTurno()"
            >assignment</mat-icon
          >
          <mat-icon
            title="Resumen diario"
            [matMenuTriggerFor]="menuResumen"
            style="
              -webkit-text-fill-color: rgb(10, 61, 110);
              float: right;
              margin-right: 10%;
              cursor: pointer;
            "
            >assignment</mat-icon
          >
          <mat-menu #menuResumen="matMenu" yPosition="below">
            <mat-form-field style="width: 90%; text-align: center">
              <mat-label>Elija una fecha</mat-label>
              <input
                matInput
                [(ngModel)]="fechaSeleccionada"
                (dateChange)="imprimirPaciente($event.value)"
                [max]="maxDate"
                [matDatepicker]="pickerResumen"
                disabled
              />
              <mat-datepicker-toggle
                matSuffix
                [for]="pickerResumen"
              ></mat-datepicker-toggle>
              <mat-datepicker #pickerResumen disabled="false"></mat-datepicker>
            </mat-form-field>
          </mat-menu>
          <mat-icon
            title="Indicacion medica"
            style="
              -webkit-text-fill-color: red;
              float: right;
              margin-right: 1%;
              cursor: pointer;
            "
            (click)="imprimirHojaEnfermeria()"
            >picture_as_pdf</mat-icon
          >
        </span>
            </div>
            <div style="margin-top: 1%">
                <mat-chip-list aria-label="Etiquetas paciente">
                    <mat-chip *ngFor="let e of etiquetasPaciente" color="accent" selected>{{ e.descripcion }}
                    </mat-chip>
                </mat-chip-list>
            </div>
        </div>
    </div>
    <br />

    <ng-template #LoadingRegTemplate>
      <div *ngIf="errorConexion">
        <span class="d-flex justify-content-center" style="color:red">“Error de sistema. La aplicación de medicamento no fue registrada. Reintente".</span>
      </div>
        <div class="d-flex justify-content-center" style="margin-bottom: 7.5%" >
            <mat-spinner [diameter]="30" [hidden]="errorConexion || errorConexion2"></mat-spinner>
           
        </div>
       
    </ng-template>

    <div class="d-flex justify-content-center">
        <div id="formularios">
            <mat-accordion>
                <mat-expansion-panel>
                    <mat-expansion-panel-header style="
              background: linear-gradient(
                180deg,
                rgba(255, 255, 255, 1) 95%,
                rgba(183, 198, 218, 0.3) 5%
              );
            ">
                        <mat-panel-title> Planes </mat-panel-title>
                    </mat-expansion-panel-header>
                    <br />
                    <div *ngIf="this.planes && this.planes.length == 0">
                        <p>No posee planes actualmente</p>
                    </div>
                    <!-- <div class="planMedicacionHeader" *ngFor="let plan of this.planes">
            {{ plan.practica.descripcion | toTitleCase }} |
            {{ plan.goteo.goteo }}
          </div> -->

                    <div *ngFor="let groupedbyPlan of groupedPlans; let groupIndex = index">
                        <div>
                            <strong>Plan {{ groupedbyPlan[0].value.planId }}</strong>
                        </div>

                        <div *ngFor="let formMed of groupedbyPlan; let regIndex = index">
                            <form [formGroup]="formMed" style="margin-bottom: 1.5%" *ngIf="!formMed.pending; else LoadingRegTemplate">
                                <div class="row planMedicacion">
                                    <div class="col-5" style="padding-right: 0; border: 0px solid red">
                                        <div>
                                            {{ formMed.value.descripcion }}
                                            <span *ngIf="formMed.value.frecuencia">
                        c/ {{ formMed.value.frecuencia }}
                      </span>
                                            <span *ngIf="formMed.value.goteo">{{
                        formMed.value.goteo
                      }}</span>
                                        </div>
                                        <div>
                                            <span *ngIf="formMed.value.indiAdicional != ''">Ind. Adicional: {{ formMed.value.indiAdicional }}</span>
                                        </div>
                                        <span *ngIf="
                        formMed.value.ultimoRegistro != '' &&
                          !this.suministroUnicaVez(formMed);
                        else noRegisterTemplate
                      ">
                      Ultima administración:
                      {{ formMed.value.ultimoRegistro | toLocalDate }}
                    </span>
                                        <span *ngIf="
                        formMed.value.ultimoEnfermero != '' &&
                        !this.suministroUnicaVez(formMed)
                      ">
                      Por: {{ formMed.value.ultimoEnfermero }}
                      <span>
                        <button
                          mat-button
                          (click)="deleteReg(formMed.value.idRegistro, formMed)"
                          type="button"
                          *ngIf="
                            formMed.value.ultimoEnfermero ==
                            this.currentUser.usuario
                          "
                        >
                          <mat-icon style="-webkit-text-fill-color: red"
                            >close</mat-icon
                          >
                        </button></span
                      >
                    </span>
                                        <ng-template #noRegisterTemplate><span>No se le ha suministrado este medicamento aun</span
                      ></ng-template
                    >
                  </div>

                  <div class="col" style="border: 0px solid blue;">
                    <mat-checkbox
                      color="primary"
                      formControlName="ayer"
                      labelPosition="after"
                      style="font-size: 12px"
                      >Ayer</mat-checkbox
                    >
                  </div>

                  <div class="col horario" style="border: 0px solid yellow;">
                    <mat-form-field style="width: 25%;">
                      <input
                        matInput
                        type="text"
                        formControlName="cantidad"
                        placeholder="Cantidad"
                      />
                    </mat-form-field>
                  </div>

                  <div class="col horario" style="border: 0px solid green;">
                    <ngx-timepicker-field
                      formControlName="fechaUsuario"
                      [format]="24"
                    ></ngx-timepicker-field>
                  </div>
                  <div class="col-1 horario">
                    <button
                      mat-icon-button
                      (click)="submitReg(formMed, groupIndex, regIndex)"
                    >
                      <mat-icon
                        style="
                          -webkit-text-fill-color: green;
                          vertical-align: middle;
                        "
                        >check_circle_outline</mat-icon
                      >
                    </button>
                  </div>
                </div>
              </form>
            </div>

          </div>

        </mat-expansion-panel>
        <mat-expansion-panel>
          <mat-expansion-panel-header
            style="
              background: linear-gradient(
                180deg,
                rgba(255, 255, 255, 1) 95%,
                rgba(251, 230, 171, 0.65) 5%
              );
            "
          >
            <mat-panel-title> Medicación </mat-panel-title>
          </mat-expansion-panel-header>
          <br />
          <div *ngIf="!this.loadingIndicacion; else LoadingRegTemplate">
            <div *ngIf="groups.length == 0">
              <p>No posee una indicacion medica</p>
            </div>

            <div *ngFor="let groupedby of grouped; let groupIndex = index">

              <div>
                <strong>{{ groupedby[0].value.via }}</strong>
              </div> 

              <div *ngFor="let formMed of groupedby; let regIndex = index">
                <form
                  [formGroup]="formMed"
                  style="margin-bottom: 1.5%"
                  *ngIf="!formMed.pending; else LoadingRegTemplate"
                >
                  <div class="row planMedicacion">
                    <div class="col-5" style="padding-right: 0">
                      <div>{{ formMed.value.descripcion }}
                        <span *ngIf="formMed.value.frecuencia">
                          c/ {{ formMed.value.frecuencia }}
                        </span>
                                            <span *ngIf="formMed.value.goteo">{{formMed.value.goteo}}</span>
                                    </div>
                                    <div>
                                        <span *ngIf="formMed.value.indiAdicional != '' && formMed.value.indiAdicional != null">Ind. Adicional: {{ formMed.value.indiAdicional }}</span>
                                    </div>

                                    <span *ngIf="formMed.value.ultimoRegistro != '' && !this.suministroUnicaVez(formMed); else noRegisterTemplate">

                        <span  style="margin-left :-5%;" *ngIf="!formMed.value.verUlt24hs && formMed.value.ult24hs[0].fechaUsuario!= ''"> 
                          <button mat-icon-button color="primary" type="button" 
                            style="align-items: center;box-shadow: none !important;outline:none"
                            (click)="formMed.value.verUlt24hs = true">
                            <mat-icon style="margin-left :-10%;margin-right: -50% ;font-size: 20px;">keyboard_arrow_down</mat-icon>
                            </button>
                        </span>

                                    <span style="margin-left :-5%;" *ngIf="formMed.value.verUlt24hs && formMed.value.ult24hs[0].fechaUsuario!= ''">
                          <button  mat-icon-button color="primary" type="button" 
                            style="align-items: center; box-shadow: none !important;outline:none;"
                            (click)="formMed.value.verUlt24hs = false">
                          <mat-icon style="margin-left :-10%;margin-right: -50% ;font-size: 20px;">keyboard_arrow_up</mat-icon>
                          </button>
                        </span>

                                    <span>Ultima administración:</span>
                                    <span> {{ formMed.value.ultimoRegistro | toLocalDate }} </span>
                                    </span>
                                    <span *ngIf="formMed.value.ultimoEnfermero != '' && !this.suministroUnicaVez(formMed)">
                        Por: {{ formMed.value.ultimoEnfermero }}
                      </span>
                                    <span>
                        <button
                          mat-button
                          style="max-width: 10% !important;"
                          (click)="deleteReg(formMed.value.idRegistro, formMed)"
                          type="button"
                          *ngIf="
                            formMed.value.ultimoEnfermero ==
                            this.currentUser.usuario
                          "
                        >
                          <mat-icon style="-webkit-text-fill-color: red"
                            >close</mat-icon
                          >
                        </button>
                      </span>
                                    <!--  <button
                        *ngIf = "!formMed.value.verUlt24hs && formMed.value.ult24hs[0].fechaUsuario!= '' "
                        style=" width:70px;font-size: 10px;margin-left: 2%"
                        (click)="formMed.value.verUlt24hs=true"
                        type="button"
                        class="btn btn-xs btn-outline-secondary"
                      >
                        Ver más
                      </button>
                      <button
                        *ngIf = "formMed.value.verUlt24hs && formMed.value.ult24hs[0].fechaUsuario!= '' "
                        style="width:70px;font-size: 10px; margin-left: 2%"
                        (click)="formMed.value.verUlt24hs=false"
                        type="button"
                        class="btn btn-xs btn-outline-secondary"
                      >
                        Ocultar
                      </button>-->
                                    <!--  <span>

                      </span>-->

                                    <mat-list style="width: 70%;" *ngIf="formMed.value.verUlt24hs">
                                        <span style="font-size: 14px;color:grey; ">Suministros últimas 24hs:</span>
                                        <mat-list-item style="font-size: 14px" *ngFor="let reg of formMed.value.ult24hs" value="reg.idRegistro">
                                            <mat-divider></mat-divider>
                                            <span *ngIf="reg.fechaUsuario!= ''">
                          {{ reg.fechaUsuario | toLocalDate }}
                          por {{ reg.enfermero }}
                          </span>
                                            <span>
                            <button
                              mat-button
                              (click)="deleteReg(reg.idRegistro,formMed)"
                              type="button"
                              *ngIf="
                                reg.enfermero ==
                                this.currentUser.usuario
                              "
                            >
                              <mat-icon style="-webkit-text-fill-color: red"
                                >close</mat-icon
                              >
                            </button></span
                          >
                          </mat-list-item>
                        </mat-list>
                    <!-- </mat-form-field>-->
                      <ng-template #noRegisterTemplate
                        ><span
                          >No se le ha suministrado este medicamento aun</span
                        ></ng-template
                      >
                    </div>
                    <br />

                    <div class="col">
                      <mat-checkbox
                        color="primary"
                        formControlName="ayer"
                        labelPosition="after"
                        style="font-size: 12px"
                        >Ayer</mat-checkbox
                      >
                    </div>

                    <div class="col horario">
                      <mat-form-field style="width: 25%;">
                        <input
                          matInput
                          type="text"
                          formControlName="cantidad"
                          placeholder="Cantidad"
                        />
                      </mat-form-field>
                    </div>

                    <div class="col horario" style="height:fit-content;">
                      <ngx-timepicker-field style="margin-top: -10px"
                        formControlName="fechaUsuario"
                        [format]="24"
                      ></ngx-timepicker-field>
                    </div>
                    <div class="col-1 horario">
                      <button
                        mat-button
                        (click)="submitReg(formMed, groupIndex, regIndex)"
                        type="button"
                      >
                        <mat-icon
                          style="
                            -webkit-text-fill-color: green;
                            vertical-align: middle;
                          "
                          >check_circle_outline</mat-icon
                        >
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
            
            <div *ngIf="this.indicacion && this.indicacion.complemento.soporteNutricional">
              <strong>Soporte nutricional</strong> 
              <p>{{this.indicacion.complemento.soporteNutricional}}</p>
            </div>
            <div *ngIf="this.indicacion && this.indicacion.complemento.indicacionesEnfermeria">
              <strong>Indicacion enfermeria</strong>
              <span [innerHtml]="this.indicacion.complemento.indicacionesEnfermeria"></span>
                                </div>

                        </div>

                        <!-- <div >
                            <strong> Medicamento no vigente </strong>
                            <button  mat-button (click)="this.desplegarRegistroPerdido = true"><mat-icon style="-webkit-text-fill-color:rgb(3, 75, 184); vertical-align: middle; ">control_point</mat-icon></button>
                        </div>

                        <div  *ngIf="desplegarRegistroPerdido">
                            <form  [formGroup]="registroPerdidoForm" *ngIf="!registroPerdidoForm.pending; else LoadingRegTemplate">
                                <mat-form-field >
                                    <mat-label>Seleccionar medicamento</mat-label>
                                    <mat-select  formControlName="medicamento">
                                        <mat-option *ngFor="let medicamento of medicamentos" 
                                                    [value]="medicamento">{{medicamento.practica.descripcion}}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                                <mat-form-field style="margin-left: 5%;">
                                    <mat-label>
                                        Seleccionar fecha
                                    </mat-label>
                                    <input matInput type="datetime-local" formControlName="fechaUsuario">
                                </mat-form-field>
                                <button style="width: 70px; font-size: 12px;" (click)="submitRegOut(registroPerdidoForm)"   type="button" class="btn btn-xs"  ><mat-icon style="-webkit-text-fill-color:orange; vertical-align: middle; ">check_circle_outline</mat-icon></button>
                            </form>
                        </div>
                        <button style="width: 70px; font-size: 12px; float: right; margin-left: 2%;" routerLink="/historial-indicacion-medica"  type="button" class="btn btn-xs  btn-outline-success" >Historial</button>-->
                </mat-expansion-panel>
                <mat-expansion-panel>
                    <mat-expansion-panel-header style="
              background: linear-gradient(
                180deg,
                rgba(255, 255, 255, 1) 95%,
                rgba(219, 187, 138, 0.3) 5%
              );
            ">          
             
                        <mat-panel-title> Evolución </mat-panel-title>
                    </mat-expansion-panel-header>
                    <br />
                    <alert [hidden]="!submittedEvolucionEnfermeria" style="font-size: 12px"></alert>
                    <form [formGroup]="evolucionEnfermeriaForm">
                        <div class="row">
                            <div class="col-sm checkbox-evolucion">
                                <mat-checkbox formControlName="toleranciaOral" labelPosition="after" style="font-size: 12px">Tolerancia oral</mat-checkbox>
                            </div>
                            <div class="col-sm checkbox-evolucion">
                                <div>
                                    <mat-checkbox formControlName="tto_vo" labelPosition="after" style="font-size: 12px">TTO vo</mat-checkbox>
                                </div>
                            </div>

                            <div class="col-sm checkbox-evolucion">
                                <mat-checkbox formControlName="avp" labelPosition="after" style="font-size: 12px">AVP</mat-checkbox>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-sm checkbox-evolucion">
                                <mat-checkbox formControlName="deambulacion" labelPosition="after" style="font-size: 12px">Deambulación</mat-checkbox>
                            </div>
                            <div class="col-sm checkbox-evolucion">
                                <mat-checkbox formControlName="peritaltismo" labelPosition="after" style="font-size: 12px">Peritaltismo</mat-checkbox>
                            </div>

                            <div class="col-sm">
                              <mat-checkbox formControlName="avc" style="font-size: 12px; margin-right: 5%" (change)="changeAvc()">AVC</mat-checkbox>
                              <mat-form-field>
                                <mat-label>Ingrese la ubicación de las vías para AVC</mat-label>
                                <mat-select formControlName="formAvc" [attr.disabled]="!this.evolucionEnfermeriaForm.value.avc" multiple> <!--falta formControlName=***-->
                                  <mat-option *ngFor="let dato of this.listaViasUbicacion" [value]="dato">
                                    {{ dato }}
                                  </mat-option>
                                </mat-select>
                              </mat-form-field>
                            </div>

                            <div class="col-sm">
                              <mat-checkbox formControlName="cateterDialisis" style="font-size: 12px; margin-right: 5%" (change)="changeCpd()">Catéter para diálisis</mat-checkbox>
                              <mat-form-field>
                                <mat-label>Ingrese la ubicación de las vías de catéter para diálisis</mat-label>
                                <mat-select formControlName="formCateterDialisis" [attr.disabled]="!this.evolucionEnfermeriaForm.value.cateterDialisis" multiple> <!--falta formControlName=***-->
                                  <mat-option *ngFor="let dato of this.listaViasUbicacion" [value]="dato">
                                    {{ dato }}
                                  </mat-option>
                                </mat-select>
                              </mat-form-field>
                            </div>
                            
                        </div>
                        <div class="row">
                            <div class="col-sm checkbox-evolucion">
                                <mat-checkbox formControlName="heridaQx" labelPosition="after" style="font-size: 12px; margin-right: 5%">Herida QX</mat-checkbox>
                                <mat-form-field>
                                    <input matInput formControlName="lugarHeridaQx" placeholder="Ubicacion" />
                                </mat-form-field>
                            </div>
                            <div class="col-sm"></div>
                            <div class="col"></div>
                        </div>
                        <mat-divider></mat-divider>
                        <br />
                        <div class="container-fluid">
                            <div class="row">
                                <div class="col-sm-auto col-md-auto col-lg-auto col-xl-auto">
                                    <mat-form-field>
                                        <mat-label>Oxigeno</mat-label>
                                        <mat-select formControlName="oxigeno">
                                            <mat-option *ngFor="let o of this.opcionesOxigeno" [value]="o">
                                                {{ o | toTitleCase }}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                                <div class="col-sm-auto col-md-auto col-lg-auto col-xl-auto">
                                    <mat-form-field>
                                        <mat-label>Modo ventilatorio</mat-label>
                                        <mat-select formControlName="modoVentilatorio">
                                            <mat-option *ngFor="let element of this.modosVentilatorios" [value]="element">
                                                {{ element }}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                                <div class="col-sm-4 col-md-4 col-lg-4 col-xl-4">
                                    <mat-checkbox formControlName="habilitarFechaTraqueotomia" labelPosition="after" style="font-size: 12px; margin-right: 5%">Traqueotomia</mat-checkbox>
                                    <mat-form-field>
                                        <mat-label>Fecha dd/mm/aaaa</mat-label>
                                        <input autocomplete="off" matInput formControlName="fechaTraqueotomia" [max]="maxDate" [matDatepicker]="picker" />
                                        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                                        <mat-datepicker #picker [attr.disabled]="datePickerDisabled"></mat-datepicker>
                                    </mat-form-field>
                                </div>
                                <div class="col-sm-4 col-md-4 col-lg-4 col-xl-3">
                                    <mat-checkbox formControlName="habilitarFechaARM" labelPosition="after" style="font-size: 12px; margin-right: 5%">ARM</mat-checkbox>
                                    <mat-form-field>
                                        <mat-label>Fecha dd/mm/aaaa</mat-label>
                                        <input autocomplete="off" matInput formControlName="fechaARM" [max]="maxDate" [matDatepicker]="pickerARM" />
                                        <mat-datepicker-toggle matSuffix [for]="pickerARM"></mat-datepicker-toggle>
                                        <mat-datepicker #pickerARM [attr.disabled]="datePickerDisabledARM"></mat-datepicker>
                                    </mat-form-field>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-auto">
                                    <mat-form-field>
                                        <input matInput formControlName="vt" placeholder="VT" />
                                    </mat-form-field>
                                </div>
                                <div class="col-auto">
                                    <mat-form-field>
                                        <input matInput formControlName="peep" placeholder="PEEP" />
                                        
                                    </mat-form-field>
                                </div>
                                <div class="col-auto">
                                    <mat-form-field>
                                        <input matInput formControlName="pi" placeholder="PI" />
                                    </mat-form-field>
                                </div>
                                <div class="col-auto">
                                    <mat-form-field>
                                        <input matInput formControlName="fio2" placeholder="FIO2" />
                                    </mat-form-field>
                                </div>
                            </div>
                        </div>
                        <mat-divider></mat-divider>
                        <br />
                        <mat-form-field style="width: 100%">
                            <textarea class="texto-evolucion" matInput type="text" placeholder="Ingrese una evolución" formControlName="evolucion"></textarea>
                            <button mat-button *ngIf="evo['evolucion']" matSuffix mat-icon-button aria-label="Clear" (click)="evo['evolucion'].reset()">
                <mat-icon>close</mat-icon>
              </button>
                        </mat-form-field>
                        <mat-divider></mat-divider>
                        <br />
                        <mat-accordion>
                            <mat-expansion-panel>
                                <mat-expansion-panel-header>
                                    <mat-panel-title> Estado </mat-panel-title>
                                </mat-expansion-panel-header>
                                <label>Dolor: </label>
                                <div class="row">
                                    <mat-radio-group style="width: 100%" formControlName="dolor">
                                        <mat-radio-button labelPosition="after" style="font-size: 12px; margin-left: 2%" value="leve">Leve</mat-radio-button>
                                        <mat-radio-button labelPosition="after" style="font-size: 12px; margin-left: 2%" value="moderado">Moderado</mat-radio-button>
                                        <mat-radio-button labelPosition="after" style="font-size: 12px; margin-left: 2%" value="severo">Severo</mat-radio-button>
                                        <button mat-button matSuffix mat-icon-button aria-label="Clear" (click)="evo['dolor'].reset()">
                      <mat-icon style="line-height: 16px">close</mat-icon>
                    </button>
                                    </mat-radio-group>
                                </div>
                                <mat-divider></mat-divider>
                                <br />
                                <label>Estado:</label>
                                <br />
                                <div class="row">
                                    <mat-radio-group style="width: 100%" formControlName="estado">
                                        <mat-radio-button labelPosition="after" style="font-size: 12px; margin-left: 2%" value="vigil">Vigil</mat-radio-button>
                                        <mat-radio-button labelPosition="after" style="font-size: 12px; margin-left: 2%" value="somnoliento">Somnoliento</mat-radio-button>
                                        <button mat-button matSuffix mat-icon-button aria-label="Clear" (click)="evo['estado'].reset()">
                      <mat-icon style="line-height: 16px">close</mat-icon>
                    </button>
                                    </mat-radio-group>
                                </div>
                                <div class="container-fluid">
                                    <div class="row">
                                        <mat-form-field>
                                            <input matInput formControlName="otros" placeholder="Otros" />
                                        </mat-form-field>
                                    </div>
                                </div>
                            </mat-expansion-panel>
                        </mat-accordion>
                        <mat-divider></mat-divider>
                        <br />
                        <mat-accordion>
                            <mat-expansion-panel>
                                <mat-expansion-panel-header>
                                    <mat-panel-title> UPP </mat-panel-title>
                                </mat-expansion-panel-header>

                                <div class="row">
                                    <div class="col">
                                        <mat-slide-toggle [(ngModel)]="tieneUPP" [ngModelOptions]="{standalone: true}" color="primary" (change)="changed()">
                                            <span *ngIf="tieneUPP">Si</span>
                                            <span *ngIf="!tieneUPP">No</span>
                                        </mat-slide-toggle>
                                    </div>
                                </div>
                                <div class="contenedor-tabla" style="width: 100%;">
                                  <table
                                    mat-table
                                    [dataSource]="listaUPPmostrar"
                                    *ngIf="listaUPP.length>0"
                                    style="width: 100%;">
                                    <div style="text-align: center; ">
                                      <ng-container matColumnDef="zona">
                                        <th mat-header-cell *matHeaderCellDef>Zona</th>
                                        <td mat-cell *matCellDef="let upp">{{ upp.zona }}<td>
                                      </ng-container>
                                    </div>
                                    <div style="text-align: center; ">
                                      <ng-container matColumnDef="grado">
                                        <th mat-header-cell *matHeaderCellDef>Grado</th>
                                        <td mat-cell *matCellDef="let upp">{{ upp.grado }}<td>
                                      </ng-container>
                                    </div>
                                    <div style="text-align: center; ">
                                      <ng-container matColumnDef="riesgo">
                                        <th mat-header-cell *matHeaderCellDef>Riesgo</th>
                                        <td mat-cell *matCellDef="let upp">{{ upp.riesgo }}<td>
                                      </ng-container>
                                    </div>
                                    <div style="text-align: center; ">
                                      <ng-container matColumnDef="accion">
                                        <th mat-header-cell *matHeaderCellDef>Acción</th>
                                        <td mat-cell *matCellDef="let upp"><button mat-button (click)="eliminarUpp(upp)"><mat-icon style="color: red;">close</mat-icon></button><td>
                                      </ng-container>
                                    </div>

                                    <tr mat-header-row *matHeaderRowDef="columnasUpp; sticky: true"></tr>
                                    <tr mat-row *matRowDef="let row; columns: columnasUpp"></tr>
                                  </table>
                                </div>
                                  
                                
                                
                                <form [formGroup]="uppForm">
                                  <div class="row">
                                    <div class="col-12 col-sm-6 col-md-6 col-xl-3">
                                        <mat-form-field>
                                          <mat-select matInput formControlName="zona"  placeholder="Zona" (selectionChange)= "chequeoValor($event)">                    
                                            <mat-option *ngFor = 'let z of listaZonas' [value]="z.value" >
                                              {{z.value}}
                                            </mat-option>
                                          </mat-select>
                                        </mat-form-field>
                                          <br>
                                          
                                          <mat-form-field [hidden]="!this.otroValor">
                                          <input matInput  placeholder="Ingrese otra zona" [(ngModel)]="otraZona" [ngModelOptions]="{standalone: true}" (change)="agregarZona(otraZona)">
                                          <!-- <button mat-button mat-icon-button matSuffix (click)="agregarZona(otraZona)" type="submit" id="addZona" matTooltip="Agregar zona"> <mat-icon>add</mat-icon></button>  -->
                                          </mat-form-field>
                                        
                                        
                                      
                                    </div>
                                    <div class="col-12  col-sm-6 col-md-6 col-xl-3">
                                        <mat-form-field>
                                          <mat-select  matInput formControlName="grado" placeholder = "Grado">
                                             <mat-option *ngFor="let grado of gradovalores" [value] ="grado" >
                                              {{grado}}
                                          </mat-option>
                                          </mat-select>
                                        </mat-form-field>
                                  
                                    </div>
                                    <div class="col-12  col-sm-6 col-md-6 col-xl-3">
                                        <mat-form-field>
                                            <input matInput formControlName="riesgo" placeholder="Riesgo" />
                                        </mat-form-field>
                                    </div>
                                    <div class="col-8 col-sm-2 col-md-2 col-xl-3">
                                      <button *ngIf="tieneUPP" [attr.disabled]="! uppForm.value.zona || ! uppForm.value.grado"  (click)="guardarUpp()" 
                                      style="width: 100px; font-size: 12px; float: right" type="button" class="btn btn-xs btn-outline-info">
                                        Agregar UPP
                                      </button>
                                    </div>
                                  </div>
                                </form>
                            </mat-expansion-panel>
                        </mat-accordion>
                        <mat-divider></mat-divider>
                        <br />
                        <mat-accordion>
                            <mat-expansion-panel>
                                <mat-expansion-panel-header>
                                    <mat-panel-title> Sonda </mat-panel-title>
                                </mat-expansion-panel-header>

                                <div class="row">
                                    <div class="col-8">
                                        <mat-form-field>
                                            <input matInput formControlName="sv" placeholder="SV" />
                                        </mat-form-field>
                                    </div>
                                    <div class="col-sm">
                                        <mat-checkbox formControlName="lavado" style="margin-top: 17%; font-size: 12px;" labelPosition="before">Lavado</mat-checkbox>
                                    </div>
                                </div>
                                <div class="container-fluid">
                                    <div class="row">
                                        <mat-form-field>
                                            <input matInput formControlName="sng" placeholder="SNG" />
                                        </mat-form-field>
                                    </div>
                                    <div class="row">
                                        <mat-form-field>
                                            <input matInput formControlName="k108" placeholder="K108" />
                                        </mat-form-field>
                                    </div>
                                    <div class="row">
                                        <mat-form-field>
                                            <input matInput formControlName="so" placeholder="SO" />
                                        </mat-form-field>
                                    </div>
                                    <div class="row">
                                        <mat-form-field>
                                            <input matInput formControlName="sg" placeholder="SG" />
                                        </mat-form-field>
                                    </div>
                                </div>
                            </mat-expansion-panel>
                        </mat-accordion>
                        <br />
                        <mat-accordion>
                            <mat-expansion-panel>
                                <mat-expansion-panel-header>
                                    <mat-panel-title> Control de infecciones </mat-panel-title>
                                </mat-expansion-panel-header>
                                    
                                <div class="row">
                                  <div class="col-auto" style="width: 100%">
                                    <label>Higiene de Manos:</label>
                                    <mat-radio-group formControlName="higieneManos" style="width: 100%" >
                                      <mat-radio-button labelPosition="after"  style="font-size: 12px;margin-left: 2%" [value]="true">Si</mat-radio-button>
                                      <mat-radio-button labelPosition="after"  style="font-size: 12px;margin-left: 2%" [value]="false">No</mat-radio-button>
                                      <mat-radio-button labelPosition="after"  style="font-size: 12px;margin-left: 2%" [value]="null">N/A</mat-radio-button>
                                  </mat-radio-group>
                                </div>
                                  <br>
                                  <div class="col-auto" style="width: 100%">
                                  <label>Higiene Bucal:</label>
                                  <mat-radio-group formControlName="higieneBucal" style="width: 100%" >
                                    <mat-radio-button labelPosition="after"  style="font-size: 12px;margin-left: 2%" [value]="true">Si</mat-radio-button>
                                    <mat-radio-button labelPosition="after"  style="font-size: 12px;margin-left: 2%" [value]="false">No</mat-radio-button>
                                    <mat-radio-button labelPosition="after"  style="font-size: 12px;margin-left: 2%" [value]="null">N/A</mat-radio-button>
                                   </mat-radio-group>
                                 </div>
                                </div>
                                 <br>
                                <div class="row">
                                  <div class="col-auto"style="width: 100%">
                                      <label>Cabecera elevada entre 30° y 45°</label>
                                       <mat-radio-group formControlName="cabeceraElevada" style="width: 100%" >
                                          <mat-radio-button labelPosition="after"  style="font-size: 12px;margin-left: 2%" [value]="true">Si</mat-radio-button>
                                          <mat-radio-button labelPosition="after"  style="font-size: 12px;margin-left: 2%" [value]="false">No</mat-radio-button>
                                          <mat-radio-button labelPosition="after"  style="font-size: 12px;margin-left: 2%" [value]="null">N/A</mat-radio-button>
                                        </mat-radio-group>
                                   </div>
                                   
                                    <div class="col-auto" style="width: 100%">
                                         <label>Aspiración subglótica</label>
                                        <mat-radio-group formControlName="aspiracionSubglotica" style="width: 100%" >
                                          <mat-radio-button labelPosition="after"  style="font-size: 12px;margin-left: 2%" [value]="true">Si</mat-radio-button>
                                          <mat-radio-button labelPosition="after"  style="font-size: 12px;margin-left: 2%" [value]="false">No</mat-radio-button>
                                          <mat-radio-button labelPosition="after"  style="font-size: 12px;margin-left: 2%" [value]="null">N/A</mat-radio-button>
                                        </mat-radio-group>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-auto"style="width: 100%">
                                        <label>Control de la presión del cuff (neumotaponamiento a 20-30 cm h2o)</label>
                                        <mat-radio-group formControlName="controlPresionCuff" style="width: 100%" >
                                          <mat-radio-button labelPosition="after"  style="font-size: 12px;margin-left: 2%" [value]="true">Si</mat-radio-button>
                                          <mat-radio-button labelPosition="after"  style="font-size: 12px;margin-left: 2%" [value]="false">No</mat-radio-button>
                                          <mat-radio-button labelPosition="after"  style="font-size: 12px;margin-left: 2%" [value]="null">N/A</mat-radio-button>
                                      </mat-radio-group>
                                    </div>
                                    <div class="col-auto"style="width: 100%">
                                        <mat-form-field>
                                            <input matInput placeholder="Observación" formControlName="observacionPresionCuff" />
                                        </mat-form-field>
                                    </div>
                                </div>
                                <div class="row">
                                  <div class="col-auto" style="width: 100%">
                                  <label>Suspensión de sedación:</label>
                                  <mat-radio-group formControlName="suspensionSedacion" style="width: 100%" >
                                      <mat-radio-button labelPosition="after"  style="font-size: 12px;margin-left: 2%" [value]="true">Si</mat-radio-button>
                                      <mat-radio-button labelPosition="after"  style="font-size: 12px;margin-left: 2%" [value]="false">No</mat-radio-button>
                                      <mat-radio-button labelPosition="after"  style="font-size: 12px;margin-left: 2%" [value]="null">N/A</mat-radio-button>
                                  </mat-radio-group>
                                 </div>
                                </div>
                                  
                              
                            </mat-expansion-panel>
                        </mat-accordion>
                        <br />
                        <alert *ngIf="submittedEvolucionEnfermeria" style="font-size: 12px"></alert>

                        <mat-slide-toggle *ngIf="tieneEstadoIngresoHabitacion" 
                                          (change)="guardarEstadoIngreso()" 
                                          [checked]="estadoIngresoHabitacion=='APROBADO'"
                                          [attr.disabled]="estadoIngresoHabitacion=='RECHAZADO' || cargandoIngreso">
                          <span *ngIf="estadoIngresoHabitacion=='APROBADO'">Ingresado en habitación: SÍ</span>
                          <span *ngIf="estadoIngresoHabitacion!='APROBADO'">Ingresado en habitación: NO</span> 
                        </mat-slide-toggle>
                        <div *ngIf="cargandoIngreso">
                          <mat-spinner [diameter]="30" ></mat-spinner>
                        </div>
                        
                        <button style="
                width: 70px;
                font-size: 12px;
                float: right;
                margin-left: 2%;
              " routerLink="/historial-evolucion-enfermeria" type="button" class="btn btn-xs btn-outline-success">
              Ver más
            </button>
                        <button style="width: 70px; font-size: 12px; float: right" (click)="submitEvo()" [disabled]="
                loadingEvolucionEnfermeria ||
                !this.evolucionEnfermeriaForm.dirty
              " type="button" class="btn btn-xs btn-outline-info" type="submit">
              Guardar
            </button>
                    </form>
                </mat-expansion-panel>
                <mat-expansion-panel>
                    <mat-expansion-panel-header style="
              background: linear-gradient(
                180deg,
                rgba(255, 255, 255, 1) 96%,
                rgba(120, 30, 26, 0.3) 4%
              );
            ">
                        <mat-panel-title> Signos vitales </mat-panel-title>
                    </mat-expansion-panel-header>
                    <alert [hidden]="!submittedSignosVitales" style="font-size: 12px"></alert>
                    <form [formGroup]="signosVitalesForm">
                        <div class="row">
                            <div class="col-sm">
                                <mat-form-field>
                                    <input matInput placeholder="FC" formControlName="frecuenciaCardiaca" />
                                </mat-form-field>
                            </div>

                            <div class="col-sm">
                                <mat-form-field>
                                    <input matInput placeholder="TA" formControlName="tensionArterial" />
                                </mat-form-field>
                            </div>

                            <div class="col-sm">
                                <mat-form-field>
                                    <input matInput placeholder="T en C°" formControlName="temperatura" />
                                </mat-form-field>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-sm">
                                <mat-form-field>
                                    <input matInput placeholder="FR" formControlName="frecuenciaRespiratoria" />
                                </mat-form-field>
                            </div>

                            <div class="col-sm">
                                <mat-form-field>
                                    <input matInput placeholder="HGT" formControlName="hemoglucotest" />
                                </mat-form-field>
                            </div>

                            <div class="col-sm">
                                <mat-form-field>
                                    <input matInput placeholder="SPO2" formControlName="saturacion" />
                                </mat-form-field>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-sm">
                                <mat-form-field>
                                    <mat-label>Rass</mat-label>
                                    <mat-select formControlName="rass">
                                        <mat-option *ngFor="let element of this.escalaRass" [value]="element.value">
                                            {{ element.value }} | {{ element.descripcion }}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>

                            <div class="col-sm">
                                <mat-form-field>
                                    <mat-label>Camicu</mat-label>
                                    <mat-select formControlName="camicu">
                                        <mat-option *ngFor="let element of this.escalaCamicu" [value]="element.value">
                                            {{ element.value }} | {{ element.descripcion }}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>
                            <div class="col-sm"></div>
                        </div>
                        <div class="container text-right">
                            <button style="width: 70px; font-size: 12px" (click)="submitSv()" [disabled]="
                            this.loadingSignosVitales || !this.signosVitalesForm.dirty" type="button" class="btn btn-xs btn-outline-info" type="submit">
                              Guardar
                            </button>
                            <button style="width: 70px; font-size: 12px; margin-left: 2%" (click)="resetSv()" type="button" class="btn btn-xs btn-outline-danger">
                Resetear
              </button>
                            <button style="width: 70px; font-size: 12px; margin-left: 2%" routerLink="/historial-signos-vitales" type="button" class="btn btn-xs btn-outline-success">
                Ver más
              </button>
                        </div>
                    </form>
                </mat-expansion-panel>
                <mat-expansion-panel>
                    <mat-expansion-panel-header style="
              background: linear-gradient(
                180deg,
                rgba(255, 255, 255, 1) 96%,
                rgba(100, 19, 192, 0.3) 4%
              );
            ">
                        <mat-panel-title> Balance hídrico </mat-panel-title>
                    </mat-expansion-panel-header>
                    <h6>Registro</h6>
                    <form [formGroup]="ingresosEgresosForm">
                        <div class="row">
                            <div class="col-sm">
                                <mat-form-field>
                                    <mat-label>Concepto</mat-label>
                                    <mat-select formControlName="concepto">
                                        <mat-option *ngFor="let concepto of this.conceptos" [value]="concepto">
                                            {{ concepto.concepto | toTitleCase }}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>
                            <div class="col-sm">
                                <mat-form-field>
                                    <input matInput placeholder="Volumen en cm3 o ml" formControlName="volumen" />
                                </mat-form-field>
                            </div>
                            <div class="col-sm">
                                <mat-form-field>
                                    <input matInput placeholder="Observaciones" formControlName="observaciones" />
                                </mat-form-field>
                            </div>
                            <div class="col-sm">
                                <button style="width: 70px; font-size: 12px" (click)="submitIngresosEgresos()" [disabled]="this.loadingIngresosEgresos || !this.ingresosEgresosForm.valid" type="button" class="btn btn-xs btn-outline-info" type="submit">
                  Guardar
                </button>
                            </div>
                        </div>
                    </form>
                    <hr />
                    <div>
                        <h6>Ingresos</h6>
                    </div>
                    <div class="container-fluid">
                        <div class="row">
                            <div *ngFor="let concepto of this.conceptosIngresos" class="col">
                                <h6 style="text-align: center">
                                    {{ concepto.concepto | toTitleCase }}
                                </h6>
                                <div *ngIf="!concepto.loading">
                                    <div *ngIf="concepto.registros.length == 0" style="text-align: center; font-size: 12px">
                                        No posee registros de este concepto
                                    </div>
                                    <div *ngFor="let registro of concepto.registros" class="row" style="font-size: 11px">
                                        <span class="col-2">
                      {{ this.onlyHora(registro.fechaHoraRegistro) }}
                    </span>
                                        <span class="col-1">
                      {{ registro.volumen }}
                    </span>
                                        <span class="col-3">
                      {{ registro.observaciones }}
                    </span>
                                        <span class="col-3" style="text-align: center">
                      {{ registro.usuarioRegistro }}
                    </span>
                                        <span class="col-1">
                      <mat-icon
                        *ngIf="
                          registro.usuarioRegistro == this.currentUser.usuario
                        "
                        (click)="this.borrarRegistroDeConcepto(registro)"
                        style="-webkit-text-fill-color: red; cursor: pointer"
                        >close</mat-icon
                      >
                    </span>
                                    </div>
                                    <div *ngIf="concepto.registros.length != 0" style="text-align: center; margin-top: 1%" class="container">
                                        <div>
                                            <strong>Total:</strong> {{ this.totalDeConcepto(concepto) }} cm3 o ml
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <hr />
                    <div>
                        <h6>Egresos</h6>
                    </div>
                    <div class="container-fluid">
                        <div class="row">
                            <div *ngFor="let concepto of this.conceptosEgresos" class="col">
                                <h6 style="text-align: center">
                                    {{ concepto.concepto | toTitleCase }}
                                </h6>
                                <div *ngIf="!concepto.loading">
                                    <div *ngIf="concepto.registros.length == 0" style="text-align: center; font-size: 12px">
                                        No posee registros de este concepto
                                    </div>
                                    <div *ngFor="let registro of concepto.registros" class="row" style="font-size: 11px">
                                        <span class="col-2">
                      {{ this.onlyHora(registro.fechaHoraRegistro) }}
                    </span>
                                        <span class="col-1">
                      {{ registro.volumen }}
                    </span>
                                        <span class="col-3">
                      {{ registro.observaciones }}
                    </span>
                                        <span class="col-3" style="text-align: center">
                      {{ registro.usuarioRegistro }}
                    </span>
                                        <span style="text-align: right" class="col-1">
                      <mat-icon
                        *ngIf="
                          registro.usuarioRegistro == this.currentUser.usuario
                        "
                        (click)="this.borrarRegistroDeConcepto(registro)"
                        style="-webkit-text-fill-color: red; cursor: pointer"
                        >close</mat-icon
                      >
                    </span>
                                    </div>
                                    <div *ngIf="concepto.registros.length != 0" style="text-align: center; margin-top: 1%" class="container">
                                        <div>
                                            <strong>Total:</strong> {{ this.totalDeConcepto(concepto) }} o ml
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <hr />
                    <div>
                        <h6>Total BH</h6>
                    </div>
                    <div>
                        <div class="row">
                            <div class="col-2"><strong>Total ingresos</strong></div>
                            <div class="col-2"><strong>Total egresos</strong></div>
                            <div class="col-2"><strong>Total</strong></div>
                        </div>
                        <div class="row">
                            <div *ngIf="this.conceptosIngresos" class="col-2">
                                {{ this.totalDeIngresos() }} cm3 o ml
                            </div>
                            <div *ngIf="this.conceptosEgresos" class="col-2">
                                {{ this.totalDeEgresos() }} cm 3 o ml
                            </div>
                            <div *ngIf="this.conceptosIngresos && this.conceptosEgresos" class="col-2">
                                {{ this.totalDeIngresos() - this.totalDeEgresos() }} cm3 o ml
                            </div>
                        </div>
                    </div>
                </mat-expansion-panel>
                <mat-expansion-panel [expanded]="currentPatient.expanded">
                    <mat-expansion-panel-header style="background: linear-gradient(180deg, rgba(255, 255, 255, 1) 96%, rgba(5, 92, 143, 0.322) 4%);">
                        <mat-panel-title>Solicitudes pendientes ({{ listaPedidosDxI.length }})</mat-panel-title>
                    </mat-expansion-panel-header>
                    <!-- <h3 mat-subheader>Estudios</h3> -->
                    <p id="parrafoEstCir" *ngIf="listaPedidosDxI.length == 0">No posee estudios solicitados/programados.</p>
                    <div class="table-responsive"><br>
                        <table class="table table-borderless" id="tableEstudios">
                            <thead>
                                <th id="colEstudios"><strong>Estudios</strong></th>
                                <th id="colEstudios"><strong>Servicio</strong></th>
                                <th id="colEstudios"><strong>Estado</strong></th>
                                <th id="colEstudios"><strong>Fecha-Hora</strong></th>
                                <th id="colEstudios"><strong>Profesional Efector</strong></th>
                            </thead>
                            <tbody>
                                <tr *ngFor="let pedido of listaPedidosDxI">
                                    <th id="colEstudios">
                                        <p mat-line *ngFor="let estudio of pedido.estudiosPaciente" id="parrafoEstCir">{{estudio.nombreEstudio}}
                                            <a href="{{ estudio.linkPreparacion }}" target="_blank" [hidden]="!estudio.linkPreparacion">
                                                <mat-icon style="font-size: 22px; cursor: pointer; color: #0a3d6e" matTooltip="Ver Preparacion">picture_as_pdf</mat-icon>
                                            </a>
                                        </p>
                                    </th>
                                    <th id="colEstudios">
                                        <p mat-line id="parrafoEstCir">{{pedido.nombreServicio | toTitleCase}}</p>
                                    </th>
                                    <th id="colEstudios">
                                        <p mat-line id="parrafoEstCir" *ngIf="pedido.estado == 'SOL'">Solicitado</p>
                                        <p mat-line id="parrafoEstCir" *ngIf="pedido.estado == 'PRO'">Programado</p>
                                    </th>
                                    <th id="colEstudios">
                                        <p mat-line id="parrafoEstCir" *ngIf="pedido.estado == 'PRO'">{{pedido.fechaHoraProg}}</p>
                                    </th>
                                    <th id="colEstudios">
                                        <p mat-line id="parrafoEstCir" *ngIf="pedido.estado == 'PRO'">{{pedido.actuante | toTitleCase}}</p>
                                    </th>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <mat-divider></mat-divider>
                    <mat-list>
                        <h3 mat-subheader style="color: black;">Cirug&iacute;as</h3>
                        <!-- <p id="parrafoEstCir"><strong>Cirug&iacute;as</strong></p> -->
                        <p id="parrafoEstCir" *ngIf="listaCirugiasProg.length == 0">No posee cirugias programadas.</p>
                        <mat-list-item *ngFor="let cirugia of listaCirugiasProg">
                            <div class="container-fluid">
                                <div class="row">
                                    <div class="col">
                                        <p mat-line id="parrafoEstCir">{{cirugia.cirugiaProgramada}}</p>
                                        <p mat-line id="parrafoEstCir"><strong>Fecha: </strong>{{cirugia.fecha}}</p>
                                        <p mat-line id="parrafoEstCir"><strong>Inicio-Fin: </strong>{{cirugia.horaInicio}} - {{cirugia.horaFin}}</p>
                                    </div>
                                    <div class="col">
                                        <p mat-line id="parrafoEstCir">{{cirugia.nomCirujano | toTitleCase}}</p>
                                        <p mat-line id="parrafoEstCir"><strong>Cirujano/a</strong></p>
                                    </div>
                                </div>
                            </div>
                        </mat-list-item>
                    </mat-list>
                </mat-expansion-panel>
            </mat-accordion>
            </div>
        </div>
    </div>
    <div class="container-fluid" *ngIf="selected">
        <button mat-button (click)="salirPdf()">
    <mat-icon>keyboard_backspace</mat-icon>
  </button>

        <div class="row"></div>
        <br />
        <div class="container">
            <embed type="application/pdf" [src]="selectedUrl | safeUrl" width="103.5%;" height="500px;" />
        </div>
    </div>

    <!-- Modals 

<div  class="modal fade" id="confirmarRegistroModal" data-backdrop="static" tabindex="-1" role="dialog" aria-labelledby="confirmarRegistroModalLabel" >
    <div class="modal-dialog" role="document">
      <div  class="modal-content">
            <div class="modal-header">
            <h5 class="modal-title" >Confirmar registro</h5>
            
            </div>
            <div class="modal-body" *ngIf=bloqueElegido >
                <p>Usted ha quiere suministrar el siguiente medicamento:</p>
            </div>
            <div class="modal-footer">
                    <button (click)=confirmar(registroElegido) type="button" class="btn btn-secondary" data-dismiss="modal">Confirmar</button>
                    <button (click)=cancelar(registroElegido) type="button" class="btn btn-secondary" data-dismiss="modal">Cancelar</button>
            </div>
        </div>
    </div>
  </div>
-->