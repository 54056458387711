import { Component, OnInit } from '@angular/core';
import { InternacionService } from '../_services/internacion.service';
import { AlertService } from '../_services/alert.service';
import { PacienteService } from '../_services/paciente.service';
import { Router } from '@angular/router';
import { MedicacionService } from '../_services/medicacion.service';
import * as moment from 'moment';

@Component({
  selector: 'app-seguidos',
  templateUrl: './seguidos.component.html',
  styleUrls: ['./seguidos.component.css']
})
export class SeguidosComponent implements OnInit {
  loading = false;
  internados;
  indicacion: any;

  constructor( private pacienteService: PacienteService,
               private router: Router,
               private internacionService: InternacionService,
               private alertService: AlertService,
               private medicacionService: MedicacionService ) {
                this.pacienteService.setCurrentPatient(null);
               }

  ngOnInit() {
    this.internadosPorPabellon();
  }

  internadosPorPabellon() {
    this.alertService.clear();
    this.loading = true;
    this.internacionService.obtenerInternadosDePabellon({pabellon: 0, denominacion: 'todos'}).subscribe(
      x => {
        this.internados = x.paginador.elementos;
        this.cargarFechaIndicaciones();
        this.loading = false;
      },
      error => {
        this.loading = false;
        this.alertService.error('Hubo un error en la conexion');
      }
    );
  }

  cargarFechaIndicaciones() {
    this.internados.forEach(internado => {
      this.medicacionService
      .indicacionActual(internado.carpeta)
      .subscribe(
        (x) => {
          if (x.paginador.elementos.length > 0) {
            this.indicacion = x.paginador.elementos[0];
            internado.fechaUltIndicacion =  moment(this.indicacion.fechaPedida, 'DD/MM/YYYY HH:mm:ss A ');
            internado.fechaUltIndicacion = internado.fechaUltIndicacion.format('DD/MM/YYYY HH:mm');
          }
        });
    });

  }


  agregarMisPacientes(internado) {
    this.internacionService.agregarMisPacientes(internado).subscribe(
      x => {
        this.internadosPorPabellon();
      },
      error => {
        console.log(error);
      }
    );
  }

  sacarMisPacientes(internado) {
    this.internacionService.sacarMisPacientes(internado).subscribe(
      x => {
        this.internadosPorPabellon();
      },
      error => {
        console.log(error);
      }
    );
  }


  seleccionarPaciente(internado) {
    this.pacienteService.setCurrentPatient(internado);

    this.router.navigate(['/paciente']);
  }

  indicacionReciente(fechaUltIndicacion) {
    // console.log(fechaUltIndicacion);
     const fechaPedida = moment(fechaUltIndicacion, 'DD/MM/YYYY HH:mm');
     const diferencia = moment.duration(moment().diff(fechaPedida));
     return (diferencia.asHours() < 1);
   }


}
