import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from '../_services/authentication.service';
import { InternacionService } from '../_services/internacion.service';
import { Router } from '@angular/router';
import { MatTableDataSource } from '@angular/material/table';

@Component({
  selector: 'app-estudios-pendientes-por-pabellon',
  templateUrl: './estudios-pendientes-por-pabellon.component.html',
  styleUrls: ['./estudios-pendientes-por-pabellon.component.css']
})
export class EstudiosPendientesPorPabellonComponent implements OnInit {
  pabellonSeleccionado;
  pabellones: any;
  hayPabellon = false
  loading = false;
  hayPedidos;
  currentUser;
  dataSourceItems: any;
  displayedColumnasItems: string[] = ["paciente", "ubicacion", "estudios", "servicio", "estado", "fechaHora", "actuante"];

  constructor(
    private authenticationService: AuthenticationService,
    private internacionService: InternacionService,
    private router: Router,
    ) {}

  async ngOnInit() {
    this.authenticationService.currentUser.subscribe((x) => {
      this.currentUser = x;
    });
    await this.cargarPabellones();
    await this.cargarSolicitudes(this.pabellonSeleccionado); 
  }

  async cargarPabellones(){
    if (sessionStorage.getItem('pabellon')){
      this.pabellonSeleccionado = JSON.parse(sessionStorage.getItem('pabellon'));
      this.hayPabellon = true;
    } else {
      await this.internacionService.obtenerPabellones().toPromise().then((x) => {
        this.pabellones = x.paginador.elementos;
        this.pabellonSeleccionado = this.pabellones[0];
        this.hayPabellon = true;
      })
    }
  }

  async cargarSolicitudes(pabellon) {
    this.loading = true;
    await this.internacionService.getSolicitudesPendientesPorPabellon(pabellon).toPromise().then(respuesta => {
      if (respuesta) {
        if (respuesta.ok) {
          this.dataSourceItems = new MatTableDataSource(respuesta.pedidos);
          this.hayPedidos = true;
        } else {
          this.hayPedidos = false;
        }
        this.loading = false;
      }
    }, (error) => {
      console.log(error);
    })
  }

  back(){    
    sessionStorage.setItem('pabellon', JSON.stringify(this.pabellonSeleccionado));
    this.router.navigateByUrl('/internados');
  }

}





