import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AppComponent } from '../app.component';


@Injectable({ providedIn: 'root' })
export class UrlResolverService { 
    private baseUrl: string;

    constructor(private http: HttpClient) {  
        this.baseUrl = AppComponent.baseUrl    
    }

    
    getHorusfullUrl(): Observable<any>{
       return this.http.get<any>(this.baseUrl + '/utils/horus-url')
    }

    

    
}