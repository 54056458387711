import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { AppComponent } from '../app.component';

const httpOptions = {
  headers: new HttpHeaders({})
};

@Injectable({
  providedIn: 'root'
})
export class CamasService {

    baseUrl: string;

    constructor(
        private http: HttpClient,
        private router: Router) {
          
            this.baseUrl = AppComponent.baseUrl;
    }

    getCamasPorPabellon(idPabellon){
        return this.http.get<any>(this.baseUrl + '/camas/getCamasPorPabellon/'+ idPabellon)
    }

    setEstadoCama(cama,pieza,idEstado, usuario){
        const body = {
            cama: cama,
            pieza: pieza,
            estadoActual: idEstado,
            usuario: usuario
          };
        return this.http.post<any>(this.baseUrl + '/camas/setEstadoCama', body)
    }

    getCamasPorFiltro(pabellon,sexo,idEstado){
        const body = {
            pabellon: pabellon,
            sexo: sexo,
            estadoActual: idEstado,
          };
        return this.http.post<any>(this.baseUrl + '/camas/getCamasPorFiltro', body)
    }
    
}
