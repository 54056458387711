<div class="container-fluid" style="margin-top: 1%">
  <mat-card [tabindex]="0" class="card-principal">
    <div class="container-fluid">
        
        <h3>
          Camas
        </h3>
     
        <div
          class="d-flex justify-content-center"
          style="margin-top: 10%"
          *ngIf="loading">
          <mat-spinner [diameter]="60"></mat-spinner>
          
        </div>
        

       <div [hidden]="loading" class="row">
         <div class="col"> 
          <div class="row" >
             <div class="col-12 col-sm-12 col-md-7 col-lg-7 col-xl-7">
              <mat-form-field style="width: 40%;">
                <mat-label>Pabellón</mat-label>
                <mat-select  [(value)]="pabellonSeleccionado" (selectionChange)="cargarCamas(pabellonSeleccionado)" >
                    <mat-option *ngFor="let pabellon of this.pabellonesFiltrados" [value]="pabellon">
                        {{ pabellon.denominacion | toTitleCase }}
                    </mat-option>

                </mat-select>
              </mat-form-field>
             </div>
           </div> 
         </div>  
      </div> 

      <mat-card-content>
        
      <div  class="row" style="margin-top: 1%;">
        <div [hidden]="loading" class="col" style="overflow: auto;">
          
          <div style="text-align: center; margin-top: 5%;  " *ngIf="sinCamas">
            <mat-card class="sin-camas-card">
              <h5 style="color:#3F51B5" >No se encontraron camas</h5>
            </mat-card>
          </div>

          <mat-form-field *ngIf="!sinCamas">
            <mat-label>Buscar</mat-label>
            <input matInput (keyup)="applyFilter($event)" autocomplete="off" />
          </mat-form-field>
          <span style="margin-left:1%;"></span>
          <!-- Boton refrescar -->
          <button mat-raised-button 
          color="primary" 
          (click)="actualizarPagina()"
          style="border-radius: 5px;width: 140px; height: 12%; ">
          <mat-icon>refresh</mat-icon>
          <span [hidden]="mobileDevice">Actualizar</span> 
        </button>
          <div class="contenedor-tabla">
          <table
            mat-table
            [dataSource]="dataSourceItems"
            #tablaCamas
            class="mat-elevation-z8"
            *ngIf="!sinCamas"
          >
            <!-- Columna Habitación -->
            <div style="text-align: center; margin-right: 1px">
              <ng-container matColumnDef="habitacion">
                <th mat-header-cell *matHeaderCellDef>Habitación</th>
                
                <td mat-cell *matCellDef="let cama">
                  {{ cama.pieza }} 
                </td>
               
              </ng-container>
            </div>

            <!-- Columna Cama -->
            <div style="text-align: center; margin-right: 1px">
              <ng-container matColumnDef="cama">
                <th mat-header-cell *matHeaderCellDef>Cama</th>
                
                <td mat-cell *matCellDef="let cama">
                  {{ cama.cama}} 
                </td>
               
              </ng-container>
            </div>

            <!-- Columna Estado Actual -->
            <div style="text-align: center; margin-right: 1px">
              <ng-container matColumnDef="estadoActual">
                <th mat-header-cell *matHeaderCellDef>Estado actual</th>
                
                <td mat-cell *matCellDef="let cama">
                  {{ cama.estadoActual}} 
                </td>
               
              </ng-container>
            </div>

            <!-- Columna Hora Cirugía -->
            <div style="text-align: center; margin-right: 1px">
              <ng-container matColumnDef="horaCirugia">
                <th mat-header-cell *matHeaderCellDef>Hora Cirugía</th>
                
                <td mat-cell *matCellDef="let cama">
                  {{ cama.horaCirugia}} 
                </td>
               
              </ng-container>
            </div>

            <!-- Columna Cambiar estado -->
            <div style="text-align: center; margin-right: 1px">
              <ng-container matColumnDef="action">
                <th mat-header-cell *matHeaderCellDef>Cambiar estado a:</th>
                <td mat-cell *matCellDef="let cama" class="action-link">

                   <!-- Botón Libre  comentado -> && cama.estadoActual != 'Bloqueada' -->
                   <button 
                      [hidden]="cama.estadoActual != 'Acondicionada'" 
                     
                      mat-raised-button
                      class="liberar-button"
                      (click)="cambiarEstadoCama(cama,1)"
                    >
                      <mat-icon>flag</mat-icon>
                      <span  [hidden]="mobileDevice" >Libre</span>
                      <!--   Libre -->
                    </button>

                  <!-- Botón En Limpieza -->
                  <button 
                    [hidden]="cama.estadoActual != 'Libre'"
                    mat-raised-button 
                    color="primary" 
                    (click)="cambiarEstadoCama(cama,7)"
                  >
                   <mat-icon>room_service</mat-icon>
                   <span  [hidden]="mobileDevice" >En Limpieza</span> 
                  <!--   En Limpieza -->
                  </button>

                  <!-- Botón  Acondicionada -->
                  <button 
                    [hidden]="cama.estadoActual != 'En Limpieza'"
                    mat-raised-button 
                    class="acondicionada-button"
                    (click)="cambiarEstadoCama(cama,8)"
                  >
                  <mat-icon>spa</mat-icon>
                  <span  [hidden]="mobileDevice" >Acondicionada</span>
                  <!--   En Limpieza -->
                  </button>

                  <!-- Botón Bloquear -->
                  <!-- <span style="margin-left:2%">
                      <button 
                        [hidden]="cama.estadoActual != 'Libre'"
                        mat-raised-button 
                        color="warn" 
                        (click)="cambiarEstadoCama(cama,3)"
                      >
                        <mat-icon>block</mat-icon>
                          Bloqueada
                      </button>
                  </span> -->
                  
                </td>
              </ng-container>
            </div>

            <tr mat-header-row *matHeaderRowDef="displayedColumnasItems; sticky: true"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumnasItems"></tr>
          </table>
        </div>

         <!--  <mat-paginator *ngIf="!sinCamas" [pageSizeOptions]="[5]" style="margin-left:5% ;width: 80%;"></mat-paginator>
 -->
        </div>
      </div>
    </mat-card-content>

    </div>
  </mat-card>
</div>
