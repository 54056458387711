import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import * as moment from 'moment';

@Pipe({
  name: 'toLocalDate',
})
export class toLocalDatePipe implements PipeTransform {
  constructor(private _domSanitizer: DomSanitizer) {}
  transform(value: string): any {
    const date = moment(value, 'DD/MM/YYYY HH:mm').toDate();
    return (
      date.toLocaleDateString() +
      ' ' +
      date.getHours() +
      ':' +
      this.minutes_with_leading_zeros(date)
    );
  }

  minutes_with_leading_zeros(dt) {
    return (dt.getMinutes() < 10 ? '0' : '') + dt.getMinutes();
  }
}
