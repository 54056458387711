
<div class="container" style="margin-top:1%">
  
     
    <div class="d-flex justify-content-center" style="margin-top:10%"  *ngIf="loading">
      <mat-spinner [diameter]="60"></mat-spinner>   
    </div>
    <alert style="font-size: 12px"></alert>
    <div class="container" >
      <div class="row d-flex justify-content-center" style="width: 100%;">
        <div class="col-xs-12 col-sm-12 col-md-10 col-lg-7 col-xl-6 " *ngIf="!loading">
          <div style="text-align: center; margin-top:35%" *ngIf="internados && internados.length==0">
            <h6>No esta siguiendo a ningun paciente</h6>
          </div>
          <mat-list *ngIf="internados && internados.length>0">
              <mat-list-item *ngFor="let internado of internados">
                      <span matListAvatar>
                        <div><mat-icon >person</mat-icon></div>
                        <div style="font-size: 11px;">{{internado.pieza}} - {{internado.cama}}</div>
                      </span>             
                      <h5 matLine>{{ internado.paciente | toTitleCase }}</h5>
                      <p matLine>
                        <span style="padding:0" mat-subheader >{{internado.diagnostico | toTitleCase}}</span>
                      </p>
                      <div style = "min-width: 30% !important; max-height: 100% !important;margin-right:0 !important;">
                        <mat-action-list style="  text-align: right;">
                          <button *ngIf="internado.seguido" (click)=sacarMisPacientes(internado) style="margin-right:6%">
                            <mat-icon class="yellow-icon">star</mat-icon>
                          </button>
                          <button *ngIf="!internado.seguido" (click)=agregarMisPacientes(internado) style="margin-right:6%">
                              <mat-icon >star_border</mat-icon>
                            </button>
                          <button style="width:35px; height:35px " mat-mini-fab color="primary" (click)=seleccionarPaciente(internado)  >
                          <mat-icon [inline]=true>edit</mat-icon>
                          </button>
                        </mat-action-list>
                        <p *ngIf = "internado.fechaUltIndicacion" style = "margin: 0 0 0 15%;">
                          <span *ngIf = "!indicacionReciente(internado.fechaUltIndicacion) else elseRojo" 
                                style="padding:0px;"  mat-subheader >
                                  {{internado.fechaUltIndicacion }}</span>
                          <ng-template #elseRojo>
                            <span style="padding:0px; color: red"  mat-subheader>
                              {{internado.fechaUltIndicacion }}</span>
                          </ng-template>
                        </p>
                      </div>
              </mat-list-item>

            </mat-list>
        </div>
      </div>
    </div>
  </div>