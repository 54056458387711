import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from '../_services/authentication.service';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { DialogCambiarContrasenaComponent } from '../dialog-cambiar-contrasena/dialog-cambiar-contrasena.component';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {
  currentUser:any;

  constructor(
    private authService :AuthenticationService,
    public dialog: MatDialog,
    private router: Router
  ) { }

  ngOnInit() {
    this.currentUser = this.authService.currentUserValue;

    if (new Date().getTime() > new Date(this.currentUser.fechaExpiracionContrasena).getTime()) {
      this.openDialogCambiarContrasena(this.currentUser);
    }
  }

  openDialogCambiarContrasena(objUsuarioLog) {
    this.dialog.open(DialogCambiarContrasenaComponent, {
      width: '500px',
      data: objUsuarioLog,
      hasBackdrop: true,
      disableClose: true
    }).afterClosed().subscribe(logout => {
      if (logout) {
        this.authService.logout();
        this.router.navigate(['/login']);
      }
    });
  }

}
