import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { User } from '../_models/user.model';
import { Router } from '@angular/router';
import { AppComponent } from '../app.component';
import * as moment from 'moment';

const httpOptions = {
  headers: new HttpHeaders({})
};

@Injectable({ providedIn: 'root' })

export class PacienteService {

    baseUrl: string;



    private currentPatientSubject: BehaviorSubject<any>;

    public currentPatient: Observable<any>;

    constructor(
        private http: HttpClient,
        private router: Router) {
            
            this.baseUrl = AppComponent.baseUrl;
            this.currentPatientSubject = new BehaviorSubject<any>(JSON.parse(sessionStorage.getItem('currentPatient')))
            this.currentPatient = this.currentPatientSubject.asObservable()
            
    }


    setCurrentPatient(paciente){
        sessionStorage.setItem('currentPatient', JSON.stringify(paciente));
        this.currentPatientSubject.next(paciente)
       
      }

    datosPaciente(numeroDoc, tipoDoc) {
        return this.http.get<any>(
          this.baseUrl +
            '/pacientes/seguro/datosDePaciente/' +
            numeroDoc +
            '/' +
            tipoDoc
        );
      }

    getEtiquetaPaciente(paciente) {
        const body = JSON.stringify(paciente);
        return this.http.post<any>(
          this.baseUrl + '/pacientes/seguro/getetiquetaspaciente',
          body
        );
      }

    removeCurrentPatient(){
        sessionStorage.removeItem('currentPatient');
        this.currentPatientSubject.next(null);
    }
    
    getCurrentPatient(){
        return  this.currentPatientSubject.getValue()
    }
 
    buscarPaciente(nombre, apellido, numeroDoc) {
      return this.http.get<any>(
        this.baseUrl +
          '/pacientes/seguro/buscarPacientesPorDniApellido/' +
          apellido +
          '/' +
          nombre +
          '/' +
          numeroDoc
      );
    }

    crearPaciente(
      nombre,
      apellido,
      sexo,
      fechaNac,
      tel,
      email,
      domicilio,
      loc,
      cod,
      usr,
      paswd,
     
     ) {
      const body = {
      nombre:nombre,
      apellido:apellido,
      sexo:sexo,
      fechaNacimiento:fechaNac,
      telefono:tel,
      mail:email,
      domicilio:domicilio,
      localidad:loc,
      codigoPostal:cod,
      usuario:usr,
      password:paswd,
      
      
      };
      // console.log(body);
     
      return this.http.post<any>(
        this.baseUrl + '/pacientes/seguro/crear_paciente',
        body,
        httpOptions
      );
    
  }

  //a partir de aca todo es de triage, deberia armar otro servicio 



    //filtro Triage
    getTriagesPorFiltro(formTriage) {
      // console.log(formTriage)
      if(formTriage.subCategoria.prioridad.idPrioridad == '4'){
        formTriage.subCategoria.prioridad.idPrioridad= null;
      }
      return this.http.post<any>(this.baseUrl + '/pacientes/seguro/getTriagePorFiltro',formTriage);
       }


  crearSignosVitales(
    presionSis,
    presionDias,
    pulso,
    frecRespiratoria,
    temperatura,
    saturacion,
    glasgow,
    hora,
    fecha
   ) {
    const body = {
      presionSis: presionSis,
      presionDias:presionDias,
      pulso:pulso,
      frecRespiratoria:frecRespiratoria,
      temperatura:temperatura,
      saturacion:saturacion,
      glasgow:glasgow, 
      hora:hora,
      fecha:fecha
    
    };
   {
    return this.http.post<any>(
      this.baseUrl + '/pacientes/seguro/crear_signosVitales',
      body,
      httpOptions
    );
  }
}

//seteo y recupero el paciente actual (local) 
setCurrentPatientLocal(paciente){
  // console.log(paciente)
  localStorage.setItem('pacienteActual',JSON.stringify(paciente));

}
getCurrentPatientLocal(){
  // console.log(JSON.parse(localStorage.getItem('pacienteActual')))
  return JSON.parse(localStorage.getItem('pacienteActual'));
}
deleteCurrentPatientLocal(){
  localStorage.removeItem('pacienteActual');
}
cargarCategorias(){
  return this.http.get<any>(this.baseUrl + '/pacientes/getTipoCategoria');
}

cargarPrioridades(){
return this.http.get<any>(this.baseUrl + '/pacientes/getPrioridadTriage');
}

cargarSubCategorias(idCategoria){
  return this.http.get<any>(this.baseUrl + '/pacientes/getTipoSubCategoria/'+ idCategoria);
}
finTriage(impresionForm){
  // console.log('Form que llega al finTriage:',impresionForm)
  const body = {
    
    paciente: {
      nroDocumento:impresionForm.paciente.nroDocumento,
      tipoDocumento:impresionForm.paciente.tipoDocumento,
    },
    codigoServicio: impresionForm.codigoServicio,
    fechaIngreso: impresionForm.fecha,
    horaIngreso: impresionForm.hora,
    categoria: {
      nombre:impresionForm.catTriage.nombre,
      id:impresionForm.catTriage.id
    },
    subCategoria: {
      nombreSubCat:impresionForm.subcatTriage.nombreSubcat,
      idSubCat: impresionForm.subcatTriage.idSubCat,
      prioridad:{
        id: impresionForm.prioridadSeleccionada
      }
    },
    presionSistolica: impresionForm.presionSis,
    presionDiastolica:impresionForm.presionDias,
    pulso:impresionForm.pulsoCardiaco,
    frecuenciaRespiratoria:impresionForm.frecRespiratoria,
    temperatura:impresionForm.tempCorporal,
    saturacion:impresionForm.saturacion,
    glasgow: impresionForm.glasgow,
    observacion:impresionForm.observacion,
    servicio: {
      id:impresionForm.servicio.id,
      nombre:impresionForm.servicio.nombre
    
    },
    respiratorio: impresionForm.respiratorio,
    modoArribo: {
      nombre: impresionForm.modoArribo.nombre,
      id: impresionForm.modoArribo.id
    },
    medDerivado: {
      apellido:impresionForm.medico.apellido,
      matricula: impresionForm.medico.nroMatricula
    
    },
    color: {
      idColor:impresionForm.color.idColor,
      nombre:impresionForm.color.nombre
     },
    idTriage: impresionForm.idTriage,
    enfermero: impresionForm.enfermero.nombreCompleto,
    estado:{
      estado:impresionForm.estado.estado,
      desc:impresionForm.estado.desc
    }
  };
  {
    // console.log(body)
    return this.http.post<any>(
      this.baseUrl + '/pacientes/seguro/crear_pacienteTriage',body, httpOptions
    );
  }

}
setCurrentSignosFormLocal(impresionInicial){
  // console.log(impresionInicial)
  localStorage.setItem('signosActual', JSON.stringify(impresionInicial.value));
 
}
getCurrentSignosFormLocal(){
 
  return JSON.parse(localStorage.getItem('signosActual'));
}

edadPaciente(paciente){
  let resul=0;
  
  const edad = new Date(paciente.fechaNacimiento);
  // console.log(edad)
  if(paciente.fechaNacimiento != null){
    
    const timeDiff =  Math.abs(Date.now() - edad.getTime());  
        
    resul = Math.floor((timeDiff / (1000 * 3600 * 24))/365);
   
  }
  else{
    console.log('El campo fecha de nacimiento es nulo');
  }
  // console.log(resul)
  return resul
}

cargarServicios(){
  return this.http.get<any>(this.baseUrl + '/pacientes/getServicioDerivacion');

}

cargarArribos(){
  return this.http.get<any>(this.baseUrl + '/pacientes/getModoArribo');

}

getProfesional(matricula,servicio ,especialidad , condicion , estado){
  return this.http.get<any>(
    this.baseUrl + '/profesionales/seguro/profesionales-filtro/'+ matricula +'/'+ servicio + '/' + especialidad +'/'+ condicion +'/'+estado);
}


cambiarEstado(paciente){
  // console.log(paciente);
  const body = {
    paciente:{
      nroDocumento:paciente.paciente.nroDocumento,
      tipoDocumento: paciente.paciente.tipoDocumento
    },
    idTriage: paciente.idTriage,
    estado:{
      estado:paciente.estado.estado,
      desc: paciente.estado.desc

    },
    color:{
      idColor: paciente.color.idColor,
      nombre: paciente.color.nombre
    },
    servicio:{
      id: paciente.servicio.id,
      nombre: paciente.servicio.nombre
    }
  }
  // console.log(body);
return this.http.post<any>(this.baseUrl + '/pacientes/setEstadoAtencion', body);

}
getEstadosTriage(){
  return this.http.get<any>(this.baseUrl + '/pacientes/getEstadosTriage');
}
}
