import { Component, OnInit } from '@angular/core';
import { PacienteService } from '../_services/paciente.service';
import { EvolucionService } from '../_services/evolucion.service';
import { AlertService } from '../_services/alert.service';
import { UntypedFormGroup, Validators, UntypedFormBuilder } from '@angular/forms';
import { SignosVitalesEvo } from '../_models/signosVitalesEvo.model';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import * as moment from 'moment';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MAT_MOMENT_DATE_FORMATS, MomentDateAdapter } from '@angular/material-moment-adapter';

@Component({
  selector: 'app-historial-signos-vitales',
  templateUrl: './historial-signos-vitales.component.html',
  styleUrls: ['./historial-signos-vitales.component.css'],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
    },
    { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS },
  ],
})
export class HistorialSignosVitalesComponent implements OnInit {
  currentPatient;
  historialSignosVitales;
  signosVitalesForms: UntypedFormGroup[] = [];
  loadingSignosVitales = false;
  submittedSignosVitales = false;

  loadingListado = false;

  escalaRass = [
  {value: null, descripcion: 'Sin rass'},
  {value: -5, descripcion: 'No estimulable'},
  {value: -4, descripcion: 'Sedación profunda'},
  {value: -3, descripcion: 'Sedación moderada'},
  {value: -2, descripcion: 'Sedación ligera'},
  {value: -1, descripcion: 'Somnoliento'},
  {value: 0, descripcion: 'Alerta y tranquilo'},
  {value: 1, descripcion: 'Inquieto'},
  {value: 2, descripcion: 'Agitado'},
  {value: 3, descripcion: 'Muy agitado'},
  {value: 4, descripcion: 'Combativo'},
];

escalaCamicu = [
  {value: null, descripcion: 'Sin camicu'},
  {value: 1, descripcion: 'Comienzo agudo o curso fluctuante'},
  {value: 2, descripcion: 'Disminución de la atención'},
  {value: 3, descripcion: 'Alteración de la conciencia'},
  {value: 4, descripcion: 'Alteraciones cognitivas'}];
  maxDate: Date;
  fechaSeleccionada: any;

  constructor(
    private pacienteService: PacienteService,
    private evolucionService: EvolucionService,
    private formBuilder: UntypedFormBuilder,
    private alertService: AlertService,
    private _dateAdapter: DateAdapter<any>

  ) {
    this.maxDate = new Date();
    this.fechaSeleccionada = moment(new Date());
    this.pacienteService.currentPatient.subscribe((x) => {
      this.currentPatient = x;
    });
  }

  ngOnInit() {
    this._dateAdapter.setLocale('es_AR');
    this.obtenerListado(this.fechaSeleccionada.format('DD/MM/YYYY'));
  }

  onDateChange(event) {
    this.fechaSeleccionada = event.value;
    this.obtenerListado(this.fechaSeleccionada.format('DD/MM/YYYY'));
   }

  obtenerListado(fecha) {
    this.signosVitalesForms = [];
    this.loadingListado = true;
    this.evolucionService
      .obtenerNotasPublicasPorTipo({
        idTipoFicha: 6,
        tipoDocumento: this.currentPatient.tipoDniPaciente,
        numeroDocumento: this.currentPatient.dniPaciente,
        fecha
      })
      .subscribe(
        (x) => {
          this.historialSignosVitales = x.paginador.elementos;
          this.historialSignosVitales.forEach((element, index) => {
            this.signosVitalesForms.push(
                this.formBuilder.group({
                id: [element.id],
                version: [element.version],
                usuario: [element.usuario],
                fecha: [element.fecha],
                frecuenciaCardiaca: [
                  this.checkField(element.signosVitales.frecuenciaCardiaca),
                ],
                frecuenciaRespiratoria: [
                  this.checkField(element.signosVitales.frecuenciaRespiratoria),
                ],
                hemoglucotest: [
                  this.checkField(element.signosVitales.hemoglucotest),
                ],
                saturacion: [this.checkField(element.signosVitales.saturacion)],
                temperatura: [
                  this.checkField(element.signosVitales.temperatura),
                  [Validators.pattern('[0-9]+(?:\\.[0-9]+)?')],
                ],
                tensionArterial: [
                  this.checkField(element.signosVitales.tensionArterial),
                ],
                rass: [parseInt(element.signosVitales.rass, 10)],
                camicu: [parseInt(element.signosVitales.camicu, 10)],
              })
            );
          });
          // this.signosVitalesForms[0].get('rass').setValue(this.escalaRass[0]);
          //console.log(this.signosVitalesForms);
          this.loadingListado = false;
        },
        (error) => {
          console.log(error);
        }
      );
  }

  prettyPrintFecha(fecha: string){
    return moment(fecha, 'MMM DD, YYYY, HH:mm:ss A').format('DD/MM/YYYY HH:mm');
   }

  checkField(field) {
    if (field === '') {
      return null;
    } else {
      return field;
    }
  }
  // get sv() { return this.signosVitalesForm.controls; }

  submitSv(form: UntypedFormGroup) {
    this.submittedSignosVitales = true;
    this.loadingSignosVitales = true;
    if (form.invalid) {
      this.alertService.error('Hubo un error, revise los campos');
      this.loadingSignosVitales = false;
      setTimeout(() => {
        this.alertService.clear();
      }, 5000);

      return;
    }

    Object.keys(form.controls).forEach((control) => {
      if (form.get(control).value == '') {
        form.get(control).reset();
      }
    });

    const id = form.value.id;
    const version = form.value.version;
    const sv = new SignosVitalesEvo(form.value);

    sv.nombreMedico = JSON.parse(
      sessionStorage.getItem('currentUser')
    ).nombreCompleto;
    sv.numeroDocumento = this.currentPatient.dniPaciente;
    sv.tipoDocumento = this.currentPatient.tipoDniPaciente;
    sv.carpeta = this.currentPatient.carpeta;
    sv.version = version;
    sv.id = id;

    this.evolucionService.enviarSignosVitales(sv).subscribe(
      (x) => {
        this.loadingSignosVitales = false;

        this.alertService.success('Signos vitales cargados correctamente');
        //  window.location.reload()
      },
      (error) => {
        console.log(error);
        this.alertService.error('Ocurrio un error');
      }
    );
  }

  deleteSv(formgroup) {
    this.evolucionService.borrarSignosVitales(formgroup.value).subscribe(
      (x) => {
        this.obtenerListado(this.fechaSeleccionada.format('DD/MM/YYYY'));
      },
      (error) => {
        console.log(error);
      }
    );
  }

  eraseSubmittions() {
    this.submittedSignosVitales = false;
  }

  habilitarEditar(nota) {
    const diferenciaHoraria =
      (Date.now().valueOf() - new Date(nota.value.fecha).valueOf()) / 3600000;

    return (
      JSON.parse(sessionStorage.getItem('currentUser')).usuario ===
        nota.value.usuario && diferenciaHoraria < 24
    );
  }
}
