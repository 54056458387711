import { Evolucion } from './Evolucion.model';
import { SignosVitales } from './signosVitales.model';

export class SignosVitalesEvo extends Evolucion {
  public constructor(init?: Partial<SignosVitales>) {
    super();
    this.tipo = { id: 6 };
    this.fechaTurnoSt = new Date().toLocaleString();
    this.privada = false;
    this.signosVitales = new SignosVitales(init);
  }

  signosVitales: {};
}
