<h1 mat-dialog-title class="mb-1">Recuperar clave</h1>
<div mat-dialog-content>
    <p>Ingrese su usuario y a la brevedad le enviaremos un correo con indicaciones a la cuenta registrada en el momento de darse de alta como usuario.</p>
    <form class="ui form" [formGroup]="recuperarForm">
        <mat-form-field appearance="outline" class="example-full-width">
            <mat-label>Nombre de usuario</mat-label>
            <input matInput formControlName="nombreUsuario">
            <mat-error *ngIf="recuperarForm.get('nombreUsuario').hasError('required')">Debe ingresar un nombre de usuario</mat-error>
        </mat-form-field>
    </form>
    <p [class]="respRecupClave.ok ? 'alert alert-success' : 'alert alert-danger'" style="margin-top: 1%;" [hidden]="!mostrarMsje">{{ respRecupClave.mensaje }}</p>
</div>
<mat-dialog-actions align="end">
    <button mat-flat-button mat-dialog-close color="warn">Cerrar</button>
    <button mat-flat-button color="primary" (click)="guardar()" [disabled]="this.loadingSubmit || respRecupClave.ok" [class.spinnerButton]="loadingSubmit">Enviar</button>
</mat-dialog-actions>