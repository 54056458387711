import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import {MatToolbarModule} from '@angular/material/toolbar';
import { User } from '../_models/user.model';
import { BehaviorSubject } from 'rxjs';
import { AuthenticationService } from '../_services/authentication.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {

  currentUser: User;
  userData: any;
  userDataSubject: BehaviorSubject<any>;
  constructor(private router: Router, private authenticationService: AuthenticationService) {
    
    this.authenticationService.currentUser.subscribe(
      x => {
        
        this.currentUser = x;
     
        
      }
    ) 
  }

  ngOnInit() {
  }

}
