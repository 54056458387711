export class Evolucion {
    tipo:any;
    fechaTurnoSt:string;
    carpeta:number;
    nombreMedico:string;
    numeroDocumento:number;
    tipoDocumento:number
    privada: boolean
    id:number;
    version:number;
}