<div class="container-fluid" style="margin-top: 1%">
  <mat-card [tabindex]="0" class="card-principal">
    <div class="container-fluid">
        
        <h3>
          Bienvenidos al Sitio Enfermería
        </h3>
        
    <mat-card-content>
        

    </mat-card-content>

    </div>
  </mat-card>
</div>

