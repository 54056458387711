<!--The content below is only a placeholder and can be replaced.-->


    <div class="example-container">
        <app-manager class="w-100"></app-manager>
        <mat-sidenav-container class="example-sidenav-container">
            <mat-sidenav #sidenav fixedTopGap="64">
            <p>
                <app-menu [inputSideNav]="sidenav"></app-menu>
            </p>
            </mat-sidenav>
      
            <mat-sidenav-content (click)="sidenav.close()" >
                <router-outlet></router-outlet>
            </mat-sidenav-content>
        </mat-sidenav-container>
    </div>



   

<!--<app-footer></app-footer>-->