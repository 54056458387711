import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './login/login.component';
import { ManagerComponent} from './manager/manager.component';
import { InternadosComponent } from './internados/internados.component';
import { SeguidosComponent } from './seguidos/seguidos.component';
import { ReportesComponent } from './reportes/reportes.component';
import { PacienteComponent } from './paciente/paciente.component';
import { CrearTriageComponent } from './crear-triage/crear-triage.component';
import { HistorialSignosVitalesComponent } from './historial-signos-vitales/historial-signos-vitales.component';
import { HistorialEvolucionEnfermeriaComponent } from './historial-evolucion-enfermeria/historial-evolucion-enfermeria.component';
import { HistorialIndicacionMedicaComponent } from './historial-indicacion-medica/historial-indicacion-medica.component';
import { CamasComponent } from './camas/camas.component';
import { HomeComponent } from './home/home.component';
import { AuthGuard } from './_guards/auth.guard';
import { TriageEnfermeriaComponent } from './triage-enfermeria/triage-enfermeria.component';
import { SignosVitalesComponent } from './signos-vitales/signos-vitales.component';
import { SintomasPacienteComponent } from './sintomas-paciente/sintomas-paciente.component';
import { VisualizacionPacienteTriageComponent } from './visualizacion-paciente-triage/visualizacion-paciente-triage.component';
import { PacientesParaInternarComponent } from './pacientes-para-internar/pacientes-para-internar.component';
import { EstudiosPendientesPorPabellonComponent } from './estudios-pendientes-por-pabellon/estudios-pendientes-por-pabellon.component';
import { CirugiasProgramadasComponent } from './cirugias-programadas/cirugias-programadas.component';
import { CajasCirugiasComponent } from './cajas-cirugias/cajas-cirugias.component';
import { ReservaCajasComponent } from './reserva-cajas/reserva-cajas.component';

const routes: Routes = [
  { path: '', pathMatch: 'full', redirectTo: 'login' },
  { path: 'login', component: LoginComponent },
  
  
  { path: 'triageEnfermeria', component: TriageEnfermeriaComponent, canActivate: [AuthGuard]},
  { path: 'crear-triage', component: CrearTriageComponent, canActivate: [AuthGuard]},
  { path: 'signos-vitales', component: SignosVitalesComponent, canActivate: [AuthGuard]},
  { path: 'sintomas-paciente', component: SintomasPacienteComponent, canActivate: [AuthGuard]},
  { path: 'visualizacion-paciente-triage', component: VisualizacionPacienteTriageComponent, canActivate: [AuthGuard]}
  ,{ 
    path: 'internados', 
    component:  InternadosComponent, 
    canActivate: [AuthGuard] 
  },
  { 
    path: 'seguidos', 
    component:  SeguidosComponent, 
    canActivate: [AuthGuard] 
  },
  { 
    path: 'reportes',  
    component: ReportesComponent, 
    data: { animation: 'isRight' }, 
    canActivate: [AuthGuard] 
  },
  { 
    path: 'paciente', 
    component: PacienteComponent, 
    data: { animation: 'isLeft' }, 
    canActivate: [AuthGuard] 
  },
  { 
    path: 'historial-signos-vitales', 
    component: HistorialSignosVitalesComponent, 
    canActivate: [AuthGuard] 
  },
  { 
    path: 'historial-evolucion-enfermeria', 
    component: HistorialEvolucionEnfermeriaComponent, 
    canActivate: [AuthGuard] 
  },
  { 
    path: 'historial-indicacion-medica', 
    component: HistorialIndicacionMedicaComponent, 
    canActivate: [AuthGuard]
  },
  { 
    path: 'camas',  
    component: CamasComponent, 
    canActivate: [AuthGuard] 
  },
  { 
    path: 'home',  
    component: HomeComponent, 
    canActivate: [AuthGuard] 
  },
  { 
    path: 'pacientesEnEspera',  
    component: PacientesParaInternarComponent, 
    canActivate: [AuthGuard] 
  },
  { 
    path: 'estudios-pendientes-por-pabellon',  
    component: EstudiosPendientesPorPabellonComponent, 
    canActivate: [AuthGuard] 
  },
  {
    path: 'cirugias/cirugiasProgramadas',
    component: CirugiasProgramadasComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'cirugias/cirugiasProgramadas',
    component: CirugiasProgramadasComponent,
    canActivate: [AuthGuard]
  },
  { 
    path: 'cirugias/cajas',  
    component: CajasCirugiasComponent, 
    canActivate: [AuthGuard] 
  },
  {
    path: 'cirugias/reservaCajas',
    component: ReservaCajasComponent,
    canActivate: [AuthGuard]
  }

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
