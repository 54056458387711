import { Injectable } from '@angular/core';
import { Adapter } from '../_adapters/adapter';

export class Concepto{
    idConcepto: number;
    concepto: string;
    modo: string;
    estado: string;
    registros:any[];
    loading: boolean;
    

    constructor(){
        this.loading = false;
        this.registros = [];
    }
}


@Injectable({
    providedIn: 'root'
})
export class ConceptoAdapter implements Adapter<Concepto> {

 adapt(item: any): Concepto {
    
    
    let c =  new Concepto();
    c.idConcepto = item.idConcepto
    c.concepto = item.concepto
    c.modo = item.modo
    c.estado = c.estado
    
    
    
    return c
    
  }

}