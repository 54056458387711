import { Component, OnInit } from '@angular/core';
import { EvolucionService } from '../_services/evolucion.service';
import {
  MAT_MOMENT_DATE_FORMATS,
  MomentDateAdapter,
  MAT_MOMENT_DATE_ADAPTER_OPTIONS,
} from '@angular/material-moment-adapter';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';
import * as moment from 'moment';
import { Moment } from 'moment';

@Component({
  selector: 'app-reportes',
  templateUrl: './reportes.component.html',
  styleUrls: ['./reportes.component.css'],
  providers: [
    // The locale would typically be provided on the root module of your application. We do it at
    // the component level here, due to limitations of our example generation script.
    {provide: MAT_DATE_LOCALE, useValue: 'ja-JP'},

    // `MomentDateAdapter` and `MAT_MOMENT_DATE_FORMATS` can be automatically provided by importing
    // `MatMomentDateModule` in your applications root module. We provide it at the component level
    // here, due to limitations of our example generation script.
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },
    {provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS},
  ],
})
export class ReportesComponent implements OnInit {

  selected: boolean = false;
  reportes: Array<any> = [];
  selectedUrl: any;
  loadingReportes: boolean = false;
  maxDate: Date;
  fechaSeleccionada: any;

  constructor(private es: EvolucionService, private _adapter: DateAdapter<any>) {
    this.maxDate = new Date();
    this.fechaSeleccionada = moment(new Date);
   }

  ngOnInit() {
    this._adapter.setLocale('es_AR');
    this.buscarReportes(this.fechaSeleccionada);
  }

  onDateChange(event){
    this.buscarReportes(event.value);
  }

  buscarReportes(date){
    this.loadingReportes = true;
    this.es.getReportesEnfermeroEnFecha(this.dateFormat(date)).subscribe(
      x => {
        this.reportes = x;
        this.loadingReportes = false;
      },
      error => {
        console.log(error)
        this.loadingReportes = false;
      }
    )
  }

  dateFormat(date: Moment){
    
    return date.format("DD/MM/YYYY");
   
  }


  imprimirReporteSeleccionado(idReporte){
    this.selectedUrl = this.es.imprimirReporteSeleccionado(idReporte)
    this.selected = true

  }


  salirPdf(){
    this.selected = false
  }




}
