import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

export interface Respuesta {
  ok: boolean;
  mensaje: string;
}
@Component({
  selector: 'app-dialog-mensaje',
  templateUrl: './dialog-mensaje.component.html',
  styleUrls: ['./dialog-mensaje.component.css'],
})
export class DialogMensajeComponent implements OnInit {
  private ok: boolean;
  constructor(
    public dialogRef: MatDialogRef<DialogMensajeComponent>,
    @Inject(MAT_DIALOG_DATA) public data: Respuesta
  ) {}

  ngOnInit() {}
}
