import { Component, OnInit, ViewChild  } from '@angular/core';
import { InternacionService } from '../_services/internacion.service';
import { Subject } from 'rxjs';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import * as moment from 'moment';
import { DialogAsignacionCamaComponent } from '../dialog-asignacion-cama/dialog-asignacion-cama.component';
import { PacienteService } from '../_services/paciente.service';
import { DialogConfirmacionComponent } from '../dialog-confirmacion/dialog-confirmacion.component';
import { DialogMensajeComponent } from '../dialog-mensaje/dialog-mensaje.component';
import { AuthenticationService } from '../_services/authentication.service';
@Component({
  selector: 'app-pacientes-para-internar',
  templateUrl: './pacientes-para-internar.component.html',
  styleUrls: ['./pacientes-para-internar.component.css']
})
export class PacientesParaInternarComponent implements OnInit {

  dataSourceItems: any;
  displayedColumnasItems: string[] = ['fechaInternacion', 'fechaCirugia', 'horaCirugia', 'cirugia', 'cirujano', 'anestesia',
                                      'sala', 'paciente', 'sexo', 'edad', 'mutual', 'ubicacion', 'ingHab', 'estado','action'];
  loading = true;
  filtroBusquedaForm: UntypedFormGroup;
  sinPacientes: any;
  pacientePayload;
  currentUser;

  protected onDestroyProperty = new Subject<void>();


  @ViewChild(MatPaginator) paginator: MatPaginator;

  constructor(
    private internacionService: InternacionService,
    private pacienteService: PacienteService,
    private authenticationService: AuthenticationService,
    private route: Router,
    public location: Location,
    private formBuilder: UntypedFormBuilder,
    private dialog: MatDialog,
    ) {
  }

  async ngOnInit() {
    this.currentUser = this.authenticationService.currentUserValue;
    this.buildFiltroBusquedaForm();
    this.buscarPacientesEnEspera();

  }

  private buildFiltroBusquedaForm() {
    this.filtroBusquedaForm = this.formBuilder.group({
         fechaDesde: [new Date()] ,
         fechaHasta: [new Date()],
         cirugiaProgramada: ['S'],
    });

  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSourceItems.filter = filterValue.trim().toLowerCase();

  }

  async buscarPacientesEnEspera() {
    this.loading = true;
    const filtroBusqueda = {
      fechaDesde: moment(this.filtroBusquedaForm.get('fechaDesde').value).format('DD/MM/YYYY'),
      fechaHasta: moment(this.filtroBusquedaForm.get('fechaHasta').value).format('DD/MM/YYYY'),
      cirugiaProgramada: this.filtroBusquedaForm.get('cirugiaProgramada').value
    };
    await this.internacionService.getPacientesEnEspera(filtroBusqueda).toPromise().then(
      (respuesta) => {

        if (respuesta.ok) {
          if (respuesta.elementos.length !== 0) {
            this.sinPacientes = false;
            this.dataSourceItems = new MatTableDataSource(respuesta.elementos);
          } else {
            this.sinPacientes = true;
          }
          this.loading = false;

        }
      },
      (error) => {
        console.log(error);
      }
    );
  }

  asignarCama(paciente) {
    this.pacienteService.setCurrentPatient(paciente);
    this.dialog
    .open(DialogAsignacionCamaComponent, {
      width: '600px',
      data: paciente
    })
    .afterClosed()
    .subscribe((confirmado: boolean) => {
      if (confirmado) {
        this.buscarPacientesEnEspera();
      }
     });
  }

  desasignarCama(paciente) {
    this.pacientePayload = {
      fechaInternacion: paciente.fechaInternacion,
      orden: paciente.orden,
      cama: null,
      pieza: null,
      usuario: this.currentUser.usuario
    };

    // console.log('onSubmit -> this.pacientePayload', this.pacientePayload);

    this.internacionService.setCamaPacienteEnEspera(this.pacientePayload).subscribe(
    (data) => {
      if (data.ok) {
        this.buscarPacientesEnEspera();
      }
      this.abrirDialogMensaje(data);
    },
    (error) => {
      console.log(error);
    }
  );
  }

  abrirDialogConfirmacion(paciente) {
    this.dialog.open( DialogConfirmacionComponent, {
      data: '¿Está seguro que desea desasignar la cama? ',
    })
    .afterClosed()
      .subscribe(
        (confirmado: boolean) => {
          if (confirmado) {
            this.desasignarCama(paciente);
          } else {
            this.dialog.closeAll();
          }
        },
        (error) => {
          console.log(error);
        }
      );
  }

  abrirDialogMensaje(resp) {
    this.dialog.open(DialogMensajeComponent, {
      width: '500px',
      height: '100px',
      data: resp,
    }).afterClosed()
    .subscribe(
      (confirmado: boolean) => {
        this.dialog.closeAll();
      },
      (error) => {
        console.log(error);
      }
    );
  }
}
