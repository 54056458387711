import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, BehaviorSubject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class EmbedUrlsService {
  public embedUrls: Observable<any[]>;
  private currentUrls: BehaviorSubject<any[]>;

  constructor(private http: HttpClient) {
    this.currentUrls = new BehaviorSubject<any[]>([]);
    this.embedUrls = this.currentUrls.asObservable();
  }

  setUrls(urls: any[]) {
    this.currentUrls.next(urls);
  }
}
