import { Component, OnInit } from '@angular/core';
import { PacienteService } from '../_services/paciente.service';
import * as moment from 'moment';
import { Router } from '@angular/router';
@Component({
  selector: 'app-visualizacion-paciente-triage',
  templateUrl: './visualizacion-paciente-triage.component.html',
  styleUrls: ['./visualizacion-paciente-triage.component.css']
})
export class VisualizacionPacienteTriageComponent implements OnInit {
  datosForm: any;
  currentPatient:any;
  constructor(
    private pacienteService: PacienteService,
    private router:Router
  ) {
    this.datosForm = this.pacienteService.getCurrentSignosFormLocal(); // me traigo los datos del formulario completo
    // console.log(this.datosForm)
    this.currentPatient = this.pacienteService.getCurrentPatient();
    //  console.log(this.currentPatient)
     }

  ngOnInit() {

  }
  edadPaciente(e){
  return this.pacienteService.edadPaciente(e) 
  }
  volver() {
    this.pacienteService.removeCurrentPatient();
    this.router.navigate(['/triageEnfermeria']);
  }
}
