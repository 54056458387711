<html>

<head>
  <meta charset="utf-8">
</head>

<body>

  <div class="container-fluid" style="margin-top: 1%">
    <mat-card [tabindex]="0" class="card-principal">
      <h3>Reservas de cajas de cirugías</h3>

      <div class="calendarios_y_filtros">

        <div class="calendario">
          <b>Fecha desde</b>
          <div class="datecontrol">
            <button class="flecha-dia" mat-icon-button (click)="previousDay_fechaDesde()">
              <mat-icon>keyboard_arrow_left</mat-icon>
            </button>
            <mat-form-field style="width: 150px; margin: 2px;">
              <input matInput [matDatepicker]="datepicker1" [formControl]="dateControl_fechaDesde" readonly>
              <mat-datepicker-toggle matSuffix [for]="datepicker1">
                <mat-icon matDatepickerToggleIcon>date_range</mat-icon>
              </mat-datepicker-toggle>
              <mat-datepicker #datepicker1 startView="month"></mat-datepicker>
            </mat-form-field>
            <button class="flecha-dia" mat-icon-button (click)="nextDay_fechaDesde()" style="right: 7px;">
              <mat-icon>keyboard_arrow_right</mat-icon>
            </button>
          </div>
        </div>

        <div class="calendario">
          <b>Fecha hasta</b>
          <div>
            <button class="flecha-dia" mat-icon-button (click)="previousDay_fechaHasta()">
              <mat-icon>keyboard_arrow_left</mat-icon>
            </button>
            <mat-form-field style="width: 150px; margin: 2px;">
              <input matInput [matDatepicker]="datepicker2" [formControl]="dateControl_fechaHasta" readonly>
              <mat-datepicker-toggle matSuffix [for]="datepicker2">
                <mat-icon matDatepickerToggleIcon>date_range</mat-icon>
              </mat-datepicker-toggle>
              <mat-datepicker #datepicker2 startView="month"></mat-datepicker>
            </mat-form-field>
            <button class="flecha-dia" mat-icon-button (click)="nextDay_fechaHasta()" style="right: 7px;">
              <mat-icon>keyboard_arrow_right</mat-icon>
            </button>
          </div>
        </div>

        <div class="filtro">
          <mat-form-field style="top: 10px">
            <mat-label>Filtrar por caja</mat-label>
            <mat-select [formControl]="cajaSeleccionadaControl">
              <mat-option>
                <ngx-mat-select-search [placeholderLabel]="'Buscar Cajas'"
                  [noEntriesFoundLabel]="'No se encontraron coincidencias'" [formControl]="filtroCajasControl">
                </ngx-mat-select-search>

              </mat-option>
              <mat-option [value]="">Ver <b>todas</b> las cajas</mat-option>
              <mat-option *ngFor="let caja of cajasFiltradas" [value]="caja.idCaja">{{ caja.nombre }}</mat-option>
            </mat-select>

            <mat-hint *ngIf="cajaSeleccionadaControl.value == undefined || cajaSeleccionadaControl.value === ''">Se verán
              "<b>Todas las cajas</b>"</mat-hint>
          </mat-form-field>
          <button mat-button *ngIf="cajaSeleccionadaControl.value" (click)="limpiarFiltroCaja()">
            <mat-icon>clear</mat-icon>
          </button>
        </div>

        <div class="filtro">
          <mat-form-field style="top: 10px">
            <mat-label>Filtrar por cirujano</mat-label>

            <mat-select [formControl]="cirujanoSeleccionadoControl">
              <mat-option>
                <ngx-mat-select-search [placeholderLabel]="'Buscar'"
                  [noEntriesFoundLabel]="'No se encontraron coincidencias'" [formControl]="filtroApellidoControl">
                </ngx-mat-select-search>
              </mat-option>




              <mat-option [value]="">Ver <b>todos</b> los profesionales</mat-option>
              <mat-option *ngFor="let cirujano of cirujanosFiltrados" [value]="cirujano.nroMatricula">{{ cirujano.apellido
                }}</mat-option>
            </mat-select>

            <mat-hint
              *ngIf="cirujanoSeleccionadoControl.value === '' || cirujanoSeleccionadoControl.value == undefined">Se verán
              "<b>Todos los cirujanos</b>"</mat-hint>
          </mat-form-field>
          <button mat-button *ngIf="cirujanoSeleccionadoControl.value" (click)="limpiarFiltroCirujano()">
            <mat-icon>clear</mat-icon>
          </button>
        </div>







      </div>

      <div class="d-flex justify-content-center" style="margin-top: 10%" *ngIf="cargando">
        <mat-spinner [diameter]="60"></mat-spinner>
      </div>


      <div class="center">

        <div class="d-flex justify-content-center" *ngIf="!cargando && reservas==null" style="margin-top: 20px;">
          <mat-card class="sin-elementos-card">
            <h5 style="color:#3F51B5">No se encontraron cajas de cirugías reservadas bajo los filtros para el rango de fechas seleccionado.</h5>
          </mat-card>
        </div>

        <div class="contenedor-cards-simil-tabla" *ngIf="!cargando && reservas!=null" style="margin-top: 20px;">
          <div class="card-simil-tabla" *ngFor="let reserva of reservas">
            <div class="contenedor-detalle-card">
              <span>El<strong> {{ reserva.fechaCirugia | date: 'dd-MM-yyyy'}}</strong>, a las <strong>{{ reserva.horaCirugia }}, </strong></span>
              <!--<span>Horario: <strong>{{cirugia.horaInicio}} - {{cirugia.horaFin}}</strong></span>-->
              <!--<span>Número de sala: <strong>{{cirugia.numeroSala }}</strong></span>-->
              <span>Cirujano: <strong>{{reserva.cirujano}}, </strong></span>
              <span>Instrumentador: <strong>{{reserva.instrumentador ? reserva.instrumentador : 'No asignado'}}</strong></span>
            </div>
              <span>Observaciones: <strong>{{ reserva.observaciones ? reserva.observaciones : 'No tiene observaciones'}}</strong></span>
              <span>Cajas: <strong *ngFor="let caja of reserva.cajasReservadas"><li>{{caja.nombreCaja}}</li></strong></span>          
              <mat-checkbox class="preparada" [checked]="reserva.estado === 'PRP'" (change)="actualizarEstado(reserva)">Reservada</mat-checkbox>
            </div>
        </div>
      </div>
    </mat-card>
  </div>

</body>

</html>