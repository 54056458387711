<!-- <div class="row" id="firstRowBack" style="border: 0px solid red;">
  <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
    <span style="float: right; margin-right: 3%;">
      <button mat-icon-button color="primary" (click)="volver()">
        <mat-icon aria-label="Example icon-button with a heart icon">keyboard_backspace</mat-icon> Volver
      </button>
    </span>
  </div>
</div>
<div class="row">
  <div class="col">
    <mat-card-header *ngIf="this.currentPatient">
    <mat-card-title
    >{{  currentPatient.apellido }}  {{ currentPatient.nombre  }}
</mat-card-title>

<mat-card-subtitle>
<span>
 
  <span > Documento </span>
  : {{ currentPatient.nroDocumento}}
</span>

</mat-card-subtitle>
<mat-card-subtitle>
 <span> Email: {{ currentPatient.email}}</span> |   
 <span> Telefono : {{ currentPatient.telefono}}</span>
  </mat-card-subtitle>

<mat-card-subtitle>
<span>Edad: {{ currentPatient.edad }} | </span>
<span>{{ currentPatient.sexo | toTitleCase }}</span>
|
<span>Nacimiento: {{ currentPatient.fechaNacimiento }}</span>
</mat-card-subtitle>
</mat-card-header>
  </div>
</div>

 -->






<!-- 
<div class="container-fluid" style= "height: 500;width: 100;"> -->
  <!-- <mat-card class="py-1 my-1" >
   <div mat-card-content>
    <p mat-card-title>Motivo de consulta</p>
    <hr> -->
    <form [formGroup]="signosFormHijo">
      <div class="row">
        <div class="col">
          <mat-form-field>
            <mat-label>Sistemas:</mat-label>
             <mat-select formControlName="catTriage" (selectionChange) = "getSubCategorias($event.value) && ingresoDato($event)" >
              <mat-option> 
                <ngx-mat-select-search placeholderLabel="Buscar" 
                  noEntriesFoundLabel="No se encontraron coincidencias" 
                  [formControl]="filtercatCtrl">
                </ngx-mat-select-search>  
               </mat-option>
                <mat-option  *ngFor="let cat of catFiltradas" [value]="cat">
                  {{cat.nombre}}
                </mat-option>
                <mat-error *ngIf="signosFormHijo.controls['catTriage'].invalid && submitted
            ">{{getErrorMessage('catTriage')}}</mat-error>
            </mat-select>
           
          </mat-form-field>

          <span style="margin: 2%;"></span>
          <mat-form-field>
           <mat-label>Sintomas:</mat-label>
 
           <mat-select formControlName="subcatTriage" (selectionChange)="prioridadSintoma($event.value)">
            <mat-option> 
              <ngx-mat-select-search placeholderLabel="Buscar" 
                noEntriesFoundLabel="No se encontraron coincidencias" 
                [formControl]="filterCtrl">
              </ngx-mat-select-search>  
             </mat-option>
              <mat-option  *ngFor="let subcat of subcatFiltradas" [value]="subcat">
                {{subcat.nombreSubcat}}
              </mat-option>
              <mat-error *ngIf="signosFormHijo.controls['subcatTriage'].invalid && submitted
              ">{{getErrorMessage('subcatTriage')}}</mat-error>
          </mat-select>
         
         </mat-form-field>
          <span style="margin: 2%;"></span>
          <mat-form-field class="example-full-width" >
            <mat-label>Observacion:</mat-label>
            <textarea formControlName="observacion" class="md-textarea md-textarea-auto form-control" style="height:150px;" matInput></textarea>
          </mat-form-field>
        </div>
    </div>
    </form>
    <br>

         <!-- Aca chequeo si el sintoma es o no prioridad 1 y segun la prioridad avanzo o termino la interaccion -->
      <!-- <div class="d-flex justify-content-center">
       
          <button   class="btn btn-success"  style="border-radius: 20px;"  [disabled]="!this.habilitarAvance" (click)="cargarSintomas()" type="submit" >Avanzar
          </button>
        </div> -->
     