<div class="container-fluid" style="margin-top: 1%">
    <mat-card>
    

          <div class="alert alert-warning alert-dismissible fade show" role="alert" *ngIf="noRegistrado">
            Su matricula no se encuentra registrada entre las matriculas de profesionales.
          </div>

          <h3>
            Cirugías programadas
          </h3>

          <div style="text-align: center; margin-top: 5%;" [hidden]="loadingInstrumentistas" *ngIf="errorInstrumentistas">
            <mat-card class="sin-elementos-card">
              <h5 style="color:#3F51B5">Ocurrió un error al recuperar la información</h5>
            </mat-card>
          </div>
        
  
         <div [hidden]="loadingInstrumentistas" *ngIf="!errorInstrumentistas" class="row" style="width: 70%;">
           <div class="col"> 
            <form [formGroup]="filtroBusquedaForm" *ngIf="!loadingInstrumentistas">
            <div class="contenedor" >
                <div id="filtro-instrumentador">
                    <mat-form-field class="filtros" id="instrumentistas">
                        <mat-label>Instrumentador</mat-label>
                        <mat-select  formControlName="matriculaInstrumentista">
                            <mat-option *ngFor="let instrumentista of this.instrumentistas" [value]="instrumentista.matricula" [disabled]="noRegistrado || !tienePermisos">
                                {{ instrumentista.nombre | toTitleCase }}
                            </mat-option>
                        </mat-select>
                  </mat-form-field>
                </div>
                <div id="filtro-fecha-desde">
                      <mat-form-field class="filtros" id="instrumentistas">
                        <mat-label>Fecha desde *</mat-label>
                        <input matInput formControlName="fechaDesde" [min]="fechaActual" [matDatepicker]="pickerfechaDesde">
                        <mat-datepicker-toggle matSuffix [for]="pickerfechaDesde" matTooltip="Seleccionar fecha" ></mat-datepicker-toggle>
                        <mat-datepicker #pickerfechaDesde ></mat-datepicker>
                      </mat-form-field>
                </div>
                <div id="filtro-fecha-hasta">
                      <mat-form-field class="filtros" id="instrumentistas">
                        <mat-label>Fecha hasta *</mat-label>
                        <input matInput formControlName="fechaHasta" [min]="fechaActual" [matDatepicker]="pickerfechaHasta">
                        <mat-datepicker-toggle matSuffix [for]="pickerfechaHasta" matTooltip="Seleccionar fecha"></mat-datepicker-toggle>
                        <mat-datepicker #pickerfechaHasta ></mat-datepicker>
                      </mat-form-field>
                  
                </div>
                <div id="buscar">
                  <button mat-raised-button 
                          color="primary" 
                          (click)="applyFilters(filtroBusquedaForm.value)" 
                          class="buscar-cirugias"
                          style="border-radius: 20px;">
                      <mat-icon>search</mat-icon>
                      Buscar cirugías
                  </button>
                </div>
                <div class="alerta alert alert-info" role="alert">
                  <svg xmlns="http://www.w3.org/2000/svg" style="display: none;">
                    <symbol id="check-circle-fill" fill="currentColor" viewBox="0 0 16 16">
                      <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z"/>
                    </symbol>
                    <symbol id="info-fill" fill="currentColor" viewBox="0 0 16 16">
                      <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zm.93-9.412-1 4.705c-.07.34.029.533.304.533.194 0 .487-.07.686-.246l-.088.416c-.287.346-.92.598-1.465.598-.703 0-1.002-.422-.808-1.319l.738-3.468c.064-.293.006-.399-.287-.47l-.451-.081.082-.381 2.29-.287zM8 5.5a1 1 0 1 1 0-2 1 1 0 0 1 0 2z"/>
                    </symbol>
                    <symbol id="exclamation-triangle-fill" fill="currentColor" viewBox="0 0 16 16">
                      <path d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566zM8 5c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995A.905.905 0 0 1 8 5zm.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2z"/>
                    </symbol>
                  </svg>
                  
                  <p class="mensaje"> <svg class="bi flex-shrink-0 me-2" width="18" height="18" role="img" aria-label="Info:"><use xlink:href="#info-fill"/></svg> Para modificar las cajas de una reserva preparada, se debe desmarcar dicho estado desde la pantalla de Reservas.</p>
                </div>

             </div>

            </form>

           </div>

        </div>

        <div
            class="d-flex justify-content-center"
            style="margin-top: 2%"
            *ngIf="loadingInstrumentistas || loadingCirugias">
            <mat-spinner [diameter]="60"></mat-spinner>
            
          </div>

        <div style="text-align: center; margin-top: 3%;" [hidden]="loadingCirugias" *ngIf="errorCirugias && !errorInstrumentistas">
          <mat-card class="sin-elementos-card">
            <h5 style="color:#3F51B5">Ocurrió un error al recuperar las cirugías</h5>
          </mat-card>
        </div>

        <div style="text-align: center; margin-top: 3%; margin-bottom: 1%;" [hidden]="loadingCirugias" *ngIf="noHayCirugias">
          <mat-card class="sin-elementos-card">
            <h5 style="color:#3F51B5">No se encontraron cirugías</h5>
          </mat-card>
        </div>
  

        <div class="contenedor-cards-simil-tabla" *ngIf="!loadingCirugias && !noHayCirugias">

          <div class="card-simil-tabla" *ngFor="let cirugia of datosAMostrar">
            <div class="contenedor-detalle-card" id="detalle-card">
              <span class="span-fecha"><strong>{{cirugia.fecha}}</strong>, de <strong>{{cirugia.horaInicio | slice:0:-3 }} a {{cirugia.horaFin  | slice:0:-3 }}</strong>, sala <strong>{{cirugia.numeroSala}}</strong></span>
              <span class="span-descripcion">Descripción: <strong>{{cirugia.cirugiaProgramada}}</strong></span>
              <span class="span-instrumentador">Instrumentador: <strong>{{cirugia.instrumentista ? cirugia.instrumentista : 'No asignado'}}</strong></span>
              <span class="span-paciente">Paciente: <strong>{{cirugia.paciente}}</strong></span>
              <span class="span-boton">
                <!-- No tiene caja asignada -->
                <button [disabled]="noRegistrado" [hidden]="cirugia.estado === 'PRP'" mat-raised-button class="asignar-button boton" style="border: 5px white !important;"
                  (click)="verCajasCirugia(cirugia)" *ngIf="!cirugia.tieneCajasAsignadas">
                  <mat-icon>add</mat-icon>
                  <span>Asignar caja</span>
                </button> 
                <!-- Tiene caja asignada -->
                <button [disabled]="noRegistrado" [hidden]="cirugia.estado === 'PRP'" mat-raised-button class="modificar-button boton"
                  (click)="verCajasCirugia(cirugia)" *ngIf="cirugia.tieneCajasAsignadas">
                  <mat-icon>settings</mat-icon>
                  <span>Editar cajas</span>  
                </button>
                <div *ngIf="cirugia.estado === 'PRP'" class="PRP alert alert-info" role="alert">
                  <p style="text-align: center;">Reserva preparada.</p>
                </div>
              </span>
            </div>
          </div>
        </div>
        
        <mat-paginator [pageSizeOptions]="[5]" showFirstLastButtons (page)="pageChanged($event)" [hidden]="noHayCirugias || loadingCirugias"></mat-paginator>    
    
    </mat-card>
</div>
