<!-- <div class="row" id="firstRowBack" style="border: 0px solid red;">
  <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
    <span style="float: right; margin-right: 3%;">
      <button mat-icon-button color="primary" (click)="volver()">
        <mat-icon aria-label="Example icon-button with a heart icon">keyboard_backspace</mat-icon> Volver
      </button>
    </span>
  </div>
</div>
<div class="row">
  <div class="col">
    <mat-card-header *ngIf="this.currentPatient">
    <mat-card-title
    >{{  currentPatient.apellido }}  {{ currentPatient.nombre  }}
</mat-card-title>

<mat-card-subtitle>
<span>
 
  <span > Documento </span>
  : {{ currentPatient.nroDocumento}}
</span>

</mat-card-subtitle>
<mat-card-subtitle>
 <span> Email: {{ currentPatient.email}}</span> |   
 <span> Telefono : {{ currentPatient.telefono}}</span>
  </mat-card-subtitle>

<mat-card-subtitle>
<span>Edad: {{ currentPatient.edad }}  </span> |
<span>{{ currentPatient.sexo }}</span>
|
<span>Nacimiento: {{ currentPatient.fechaNacimiento }}</span>
</mat-card-subtitle>
</mat-card-header>
  </div>
</div> -->

<div class="container-fluid">
<div class="col-sm-12 py-1 my-1">
<div class="container py-0 my-0">
<mat-card class="py-1 my-1" style="text-align: center">
  <h1 mat-card-title>Signos Vitales</h1>
 <div mat-card-content>

  <form [formGroup]="impresionFormHijo">

    <div class="row">
        <div class="col-sm-6">
            <mat-form-field>
                <input matInput type="number" formControlName="presionSis" (change)="ingresoDato($event)"
                  placeholder="Presión Sistólica ">
                    <mat-error *ngIf="impresionFormHijo.controls['presionSis'].invalid 
                    ">{{getErrorMessage('presionSis')}}</mat-error>
                    <mat-hint align="end"> 70~200 </mat-hint>
            </mat-form-field>
        </div>
        <div class="col">
          <mat-form-field>
              <input matInput type="number" formControlName="presionDias" (change)="ingresoDato($event)"
                  placeholder="Presión Diastólica ">
                  <mat-error *ngIf="impresionFormHijo.controls['presionDias'].invalid 
                  ">{{getErrorMessage('presionDias')}}</mat-error>
                  <mat-hint align="end"> 40~110 </mat-hint>
          </mat-form-field>
          
        </div>
    </div>

    <div class="row">
      <div class="col-sm-6">
          <mat-form-field>
              <input matInput type="number" formControlName="pulsoCardiaco" (change)="ingresoDato($event)"
                placeholder="Pulso Cardiaco ">
                  <mat-error *ngIf="impresionFormHijo.controls['pulsoCardiaco'].invalid
                  ">{{getErrorMessage('pulsoCardiaco')}}</mat-error>
                  <mat-hint align="end"> 40~150 </mat-hint>
          </mat-form-field>
      </div>
      <div class="col">
        <mat-form-field >
            <input matInput type="number" formControlName="frecRespiratoria" (change)="ingresoDato($event)"
                placeholder="Frecuencia Respiratoria "
               >
                <mat-error *ngIf="impresionFormHijo.controls['frecRespiratoria'].invalid
                ">{{getErrorMessage('frecRespiratoria')}}</mat-error>
                <mat-hint align="end"> 12~30 </mat-hint>
        </mat-form-field>
      </div>
    </div>

    <div class="row">
      <div class="col-sm-6">
          <mat-form-field >
              <input matInput type="number" formControlName="tempCorporal" (input)="ingresoTemperatura($event)" (change)="ingresoDato($event)"
                placeholder="Temperatura Corporal " onkeydown="return event.key !== 'e' && event.key !== '-' ">
                  <mat-error *ngIf="impresionFormHijo.controls['tempCorporal'].invalid 
                  ">{{getErrorMessage('tempCorporal')}}</mat-error>
                  <mat-hint align="end" *ngIf="rangoTemperatura"> 30~45 </mat-hint>
                  <mat-hint align="end" style="background-color: red; color: white;" *ngIf="!rangoTemperatura"> 30~45 </mat-hint>
          </mat-form-field>
        
      </div>
      
      <div class="col">
        <mat-form-field>
          <input matInput type="number" formControlName="saturacion" (change)="ingresoDato($event)"
            placeholder="Saturacion ">
              <mat-error *ngIf="impresionFormHijo.controls['saturacion'].invalid 
              ">{{getErrorMessage('saturacion')}}</mat-error>
              <mat-hint align="end"> > 90 </mat-hint>
       </mat-form-field>
      </div>
      <div class="col-sm-6">
        <mat-form-field>
        <mat-select formControlName="glasgow" placeholder="Escala glasgow" >
          <mat-option  *ngFor="let g of escalaGlasgow" [value]="g.value">
            {{g.value}}
          </mat-option>
      </mat-select>
    </mat-form-field>
      
    </div>
      
      <mat-form-field hidden >
        <!-- <mat-label>Categoria:</mat-label> -->
        <input matInput formControlName="catTriage"  >
      </mat-form-field>
       
          <mat-form-field hidden>
             <!-- <mat-label>Sub-Categoria*</mat-label> -->
             <input matInput  formControlName="subcatTriage">
  
             <!-- <mat-error *ngIf="impresionFormHijo.controls['subcategoria'].invalid 
             && submitted">{{getErrorMessage('subcategoria')}}</mat-error> -->
             
          </mat-form-field>
        
        
    </div> 
    <!-- <div class="d-flex justify-content-md-center">
      <div *ngIf ="sintomaFueradeRango() ; else elseBlock">
        <button class="btn btn-danger"  style="border-radius: 20px;"   (click)="guardarSignosVitales()" type="submit" >Avanzar
        </button> 
      </div>
      <ng-template #elseBlock>
      <button type="button"  class="btn btn-success"  style="border-radius: 20px;" [disabled]="!this.habilitarAvance" (click)="sintomasPaciente(this.currentPatient)" >Avanzar
      </button>
      </ng-template>
      <mat-spinner [diameter]="30" *ngIf="loading"></mat-spinner>
      
      <alert style="font-size: 12px"></alert>
      </ng-template>
    </div> -->
      </form>
 </div>
</mat-card>
</div>
</div>
</div>





