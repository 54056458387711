
<div class="row" id="firstRowBack" style="border: 0px solid red;">
  <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
    <span style="float: right; margin-right: 3%;">
      <button mat-icon-button color="primary" (click)="volver()">
        <mat-icon aria-label="Example icon-button with a heart icon">keyboard_backspace</mat-icon> Volver
      </button>
    </span>
  </div>
</div>


<!--Datos del paciente y los sintomas-->
<mat-card>
  <div *ngIf = "currentPatient.color.idColor == 1; else elseBlock">
  <p class="alert alert-danger p-1 m-1 align-middle text-center">
    PRIORIDAD 1 - INGRESAR SIN DEMORA
  </p>
  </div>
  <ng-template #elseBlock>
    <div *ngIf = "currentPatient.color.idColor == 2; else elseBlock2">
      <p class="alert-alertaAmarilla p-1 m-1 align-middle text-center">
        PRIORIDAD 2 - ATENDER DENTRO DE LA PROXIMA MEDIA HORA
      </p>
    </div>
  </ng-template>
  <ng-template #elseBlock2>
    <div  *ngIf = "currentPatient.color.idColor == 3">
    <p class="alert alert-success p-1 m-1 align-middle text-center" >
      PRIORIDAD 3 - ATENDER EN CONSULTORIOS EXTERNOS
    </p>
  </div>
  </ng-template>

  <div class="row">
  <div class="col-md-12 align-middle text-center">

     <span><strong>Nombre y apellido:</strong> {{ currentPatient.paciente.nombre }} {{ currentPatient.paciente.apellido }} </span>
     <br>
     <span><strong>Numero de documento: </strong>{{currentPatient.paciente.nroDocumento }} </span>
     <br>
     <span><strong>Fecha de nacimiento: </strong>{{  currentPatient.paciente.fechaNacimiento }} </span>
     <br>
     <span><strong>Fecha de Ingreso:</strong> {{  currentPatient.fechaIngreso }} </span>
     <br>
     <span><strong>Hora de Ingreso:</strong> {{  currentPatient.horaIngreso }} </span>
     <br>
     <span> <strong>Sintoma: </strong>{{  currentPatient.subCategoria.nombreSubcat }} </span>
     <br>
     <span><strong> Presion Sistolica: </strong>{{  currentPatient.presionSistolica }} </span>
     <br>
     <span> <strong>Presion Diastolica:</strong> {{  currentPatient.presionDiastolica }} </span>
     <br>
     <span> <strong>Pulso Cardiaco: </strong>{{  currentPatient.pulso }} </span>
     <br>
     <span> <strong>Frecuencia Respiratoria:</strong> {{  currentPatient.frecuenciaRespiratoria }} </span>
     <br>
     <span> <strong>Saturacion: </strong>{{  currentPatient.saturacion }} </span>
     <br>
     <span><strong>Temperatura: </strong>{{  currentPatient.temperatura }} </span>
     <br>
     <span> <strong>Escala de glasgow: </strong>{{  currentPatient.glasgow }} </span>
     <br>
     <span> <strong>Medico Derivado: </strong>{{  currentPatient.medDerivado.nombreCompleto }} </span>
     <br>
     <span> <strong>Observacion: </strong><p [innerHTML]="currentPatient.observacion"></p> </span>
  </div>
</div>



</mat-card>