import { Component, OnInit, Inject } from '@angular/core';
import {MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CajasEsterilizacionService } from '../_services/cajas-esterilizacion.service';
import { DialogMensajeComponent } from '../dialog-mensaje/dialog-mensaje.component';
import { MatDialogRef } from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators } from "@angular/forms";

@Component({
  selector: 'app-dialog-modificar-caja',
  templateUrl: './dialog-modificar-caja.component.html',
  styleUrls: ['./dialog-modificar-caja.component.css']
})
export class DialogModificarCajaComponent implements OnInit {
  validarForm: FormGroup;
  nombre: String;
  id: any;
  titulo: String
  accion: String
  isMobile: boolean = window.innerWidth < 1025;

  constructor(@Inject(MAT_DIALOG_DATA) public data,
              private dialogRef: MatDialogRef<DialogModificarCajaComponent>, 
              private cajasService: CajasEsterilizacionService,
              public dialogo: MatDialog,
              private formBuilder: FormBuilder,) {
    this.nombre = data.caja.nombre;
    this.id = data.caja.idCaja;
    this.titulo = data.titulo;
    this.accion = data.accion; 

    this.validarForm = this.formBuilder.group({
      nombre: [
        "",
        [Validators.required, Validators.pattern("^[a-zA-Z0-9.,-áéíóúÁÉÍÓÚñÑ#\\s]*$")],
      ]
    })    
  }

  ngOnInit(): void {
  }

  async submit(){
      if (this.validarForm.invalid) {
        this.dialogo.open(DialogMensajeComponent, {
          width: '500px',
          height: this.isMobile ? '150px' : '110px',
          data: {ok: false, mensaje:"Para el nombre de una caja solo se permiten letras, números, comas, puntos, guiones y #"},
        }).afterClosed().subscribe(() => {
          this.dialogRef.close(true);
        });
        return;
      }
      this.cajasService.modificarCaja(this.id,  this.validarForm.get('nombre').value, null).subscribe(res => {
        let ok: boolean = false;
        if (res === 'La caja se modificó correctamente' || res === 'La caja se agregó correctamente'){
          let data = {ok: true, mensaje: res}
          this.dialogo.open(DialogMensajeComponent, {
            width: '500px',
            height: '100px',
            data: data,
          }).afterClosed().subscribe(() => {
            this.dialogRef.close(true);
          });
        }
        else {
          let data = {ok: false, mensaje: res || 'Error al '+this.accion.toLowerCase()+' la caja. Ingrese un nombre válido'}
          this.dialogo.open(DialogMensajeComponent, {
            width: '500px',
            height: '100px',
            data: data,
          }).afterClosed().subscribe(() => {
            this.dialogRef.close(false);
          });
        }
      })
    }

    

    cancelar() {
      this.dialogRef.close(false);
    }


}
