import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthenticationService } from '../_services/authentication.service';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { first } from 'rxjs/operators';
import { AlertService } from '../_services/alert.service';
import { MatDialog } from '@angular/material/dialog';
import { DialogRecuperarContrasenaComponent } from '../dialog-recuperar-contrasena/dialog-recuperar-contrasena.component';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  submitted= false;
  loading= false;
  loginForm: UntypedFormGroup;
  rolEnfermero: any;
  esInstrumentador: any;
  showPass = false;
  mayusActivado;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private alertService: AlertService,
    private router: Router,
    private authenticationService: AuthenticationService,
    private dialog: MatDialog,
  ) { 
    if (this.authenticationService.currentUserValue) { 
      //console.log(this.authenticationService.currentUserValue);
      this.router.navigate(['/internados']);
  }
   
    }

  ngOnInit() {
    this.loginForm = this.formBuilder.group({
      username: ['', Validators.required],
      password: ['', Validators.required]
    });

    window.addEventListener('keydown', this.mayusActivo);
  }

  recuperarClave() {
    this.dialog.open(DialogRecuperarContrasenaComponent, {
      width: '500px',
      hasBackdrop: true
    })
  }

  get f() { return this.loginForm.controls; }

  mayusActivo(event:KeyboardEvent){

    if(event.getModifierState('CapsLock'))
    {
      document.getElementById('mayus').style.display = 'block';
          
    }
    else{
      document.getElementById('mayus').style.display = 'none';
    }
   }
  onSubmit() {
    // console.log('submit');
    this.submitted = true;

    this.alertService.clear();

    // stop here if form is invalid
    if (this.loginForm.invalid) {
        return;
    }

    this.loading = true;
    this.authenticationService.login(this.f.username.value, this.f.password.value)
    .pipe(first())
    .subscribe(
      async data => {
        if(data.ok){
          await this.filtrarRoles(data);
          if(this.rolEnfermero.length > 0) {
            this.router.navigate(['/internados']);
          } else if (this.esInstrumentador.length > 0) {
            this.router.navigate(['/cirugias/cirugiasProgramadas']);
          } else {
            this.router.navigate(['/home']);
          }   
        }
        else{
          
          this.alertService.error("Usuario y contraseña incorrectos")
          this.loading = false;
        }
      },
      error => {
        this.loading = false;
        this.alertService.error("Se produjo un error, por favor intente mas tarde");    
      }
    );
  }
  password() {
    
      this.showPass = !this.showPass;
    
  }
  filtrarRoles(data){
    this.rolEnfermero = data.user.roles.filter(elemento => {
      if (elemento.codigo === 'ENF') {
        return elemento;
      }
    });
    this.esInstrumentador = data.user.roles.filter(elemento => {
      if (elemento.codigo === 'TEC') {
        return elemento.perfiles.filter(ele => {
          if (ele.codigo === 'INST') {
            return ele;
          }
        });
      }
    })
  }

  convertirEnMinusculas(){
    this.f.username.setValue(this.f.username.value.toLowerCase());
  }

}
