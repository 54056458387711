import { Component, OnInit } from '@angular/core';
import { CajasEsterilizacionService } from '../_services/cajas-esterilizacion.service';
import { throws } from 'assert';
import {MatDialog} from '@angular/material/dialog';
import { DialogModificarCajaComponent } from '../dialog-modificar-caja/dialog-modificar-caja.component';
import { Router } from '@angular/router';
import { MatTableDataSource, MatTable } from '@angular/material/table';

@Component({
  selector: 'app-cajas-cirugias',
  templateUrl: './cajas-cirugias.component.html',
  styleUrls: ['./cajas-cirugias.component.css']
})
export class CajasCirugiasComponent implements OnInit {
  cajas: MatTableDataSource<any>;
  sinCajas: boolean;
  loading: boolean = false;
  displayedColumnasItems: string[] = ['nombre', 'acciones'];
  filtro: string = '';
  buscarValue: string;
  isMobile: boolean = window.innerWidth < 1025;

  constructor( private cajasService: CajasEsterilizacionService,  
              public dialog: MatDialog, 
              private router: Router) { }

  ngOnInit(): void {
    this.loading= true;
    this.getCajas();
    this.loading = false;
  }


  async getCajas(){
    this.cajasService.obtenerCajas().subscribe(res => {
      if (res != null && res.length > 0){
        this.sinCajas = false;
        this.cajas= new MatTableDataSource(res)
      } else {
        this.cajas = null;
        this.sinCajas= true;
      }
  })
  }

  async modificarNombre(caja) {
    const filtroActual = this.filtro;
    const dialogRef = this.dialog.open(DialogModificarCajaComponent,
      { height: this.isMobile ? '310px' : '210px',
        data: {
        caja: caja,
        titulo: 'Ingrese el nuevo nombre para la caja',
        accion: 'Modificar'}
      },)
    dialogRef.afterClosed().subscribe((result) => {
      if (result === true) { // si se modifica actualizo, si no dejo el filtro
        this.buscarValue= ''
        this.getCajas();
      } else {
        this.filtro = filtroActual;
        this.cajas.filter = this.filtro;
      }
    });
  }

  async crearCaja() {
    const filtroActual = this.filtro;
    const dialogRef = this.dialog.open(DialogModificarCajaComponent, {
      data: {
        caja: {
          idCaja: null,
          nombre: null,
        },
        titulo: 'Ingrese el nombre para la nueva caja',
        accion: 'Crear'
      }
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result === true) { // si se modifica actualizo, si no dejo el filtro
        this.buscarValue= ''
        this.getCajas();
      } else {
        this.filtro = filtroActual;
        this.cajas.filter = this.filtro;
      }
    });
  }
  

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.filtro = filterValue.trim().toLowerCase();
    this.cajas.filter = this.filtro;
  }

}
