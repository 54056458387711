<h1 mat-dialog-title style="padding-left: 0%;padding-right: 3.5%;">Trasladar paciente</h1>
<div mat-dialog-content>
  <form [formGroup]="trasladoForm" id="formTraslado">

      <span style="size:16px; margin-bottom: 2%;">
        Paciente: <b>{{this.trasladoForm.get('paciente').value}}</b>
        
      </span>
      <mat-divider class="divisor"></mat-divider> 
      <span>
        <h6>
          Cama actual
        </h6>
      </span>
      
      <div class="row">
        <div class="col justify-content-center">
        
          <p>
            Ubicación: <b>{{this.trasladoForm.get('camaAct').value}}</b>
          </p>
        </div>
        <div class="col justify-content-center">
          <p>Cambiar estado a: </p>

          <mat-radio-group name="opciones"  formControlName="estadoCamaAct">
            <mat-radio-button *ngFor="let op of listaEstados" 
                              name="opciones" 
                              [value]="op"  
                              style="margin: 10px"
                              [checked]="op.checked"  >
              {{op.nombre}}
            </mat-radio-button>
          </mat-radio-group>
        </div>
         
      </div> 
      <mat-divider class="divisor"></mat-divider> 
      <span>
        <h6>
          Cama nueva
        </h6>
      </span>
    
      <div class="row">
          
        <div class="col-7 justify-content-center">
          <mat-form-field style="width: 100%;" appearance="outline">
            <mat-label>Pabellón</mat-label>
            <mat-select  formControlName="pabellonElegido" 
                        (selectionChange)="getCamasLibres(this.trasladoForm.get('pabellonElegido').value)">
                <mat-option *ngFor="let pabellon of this.pabellones" [value]="pabellon">
                    {{ pabellon.denominacion | toTitleCase }}
                </mat-option>

            </mat-select>
          </mat-form-field>
        </div>
           
        <div class="col-4 justify-content-center">
              
            <mat-form-field style="width: 100%;" appearance="outline">
            <mat-label>Cama Libre*</mat-label> 
            <mat-select  formControlName="camaNueva">
                <mat-option *ngFor="let cama of this.camasLibres" [value]="cama">
                    {{cama.pieza}} - {{cama.cama}}
                </mat-option>
  
            </mat-select>
            <mat-error style="font-size:12px;" *ngIf="trasladoForm.controls['camaNueva'].invalid 
                          && submitted">{{getErrorMessage('camaNueva')}}</mat-error>
          </mat-form-field>
          
        </div>
      </div>
  </form>

</div>
<div mat-dialog-actions class="d-flex justify-content-center">
  <button mat-raised-button 
          color="warn" 
          (click)="cerrarDialogo()"> Cancelar
  </button>

  <button mat-raised-button 
          type="submit" 
          form="formTraslado"
          color="primary" 
          (click)="onSubmit()"> Confirmar
  </button>
</div>