import { Component, OnInit } from '@angular/core';
import { InternacionService } from '../_services/internacion.service';
import { AlertService } from '../_services/alert.service';
import { PacienteService } from '../_services/paciente.service';
import { Router } from '@angular/router';
import { EvolucionService } from '../_services/evolucion.service';
import { PedidosService } from '../_services/pedidos.service';
import { AuthenticationService } from '../_services/authentication.service';
import { MedicacionService } from '../_services/medicacion.service';
import * as moment from 'moment';
import { DialogTrasladoPacienteComponent } from '../dialog-traslado-paciente/dialog-traslado-paciente.component';
import { MatDialog } from '@angular/material/dialog';
import { DialogCambiarContrasenaComponent } from '../dialog-cambiar-contrasena/dialog-cambiar-contrasena.component';

@Component({
  selector: 'app-internados',
  templateUrl: './internados.component.html',
  styleUrls: ['./internados.component.css'],
})

export class InternadosComponent implements OnInit {
  pabellones;
  pabellonElegido;
  internados;
  loading = false;
  selectedUrl: string;
  selected: boolean;
  searchText: any;
  currentUser;
  indicacion: any;

  //cantidadSolicitudes: number = 0;
  //jsonPedidoDxI: any = {};
  //jsonCirugia: any = {};
  //listaPedidosDxI: any[] = [];
  //listaCirugiasProg: any[] = [];

  constructor(
    private pacienteService: PacienteService,
    private router: Router,
    private internacionService: InternacionService,
    private alertService: AlertService,
    private authenticationService: AuthenticationService,
    private evolucionService: EvolucionService,
    private pedidosService: PedidosService,
    private medicacionService: MedicacionService,
    private dialog: MatDialog,
  ) {
    this.pacienteService.setCurrentPatient(null);
  }

  ngOnInit() {
    this.authenticationService.currentUser.subscribe((x) => {
      this.currentUser = x;
    });
    
    this.internacionService.obtenerPabellones().subscribe((x) => {
      this.pabellones = x.paginador.elementos;
      if (sessionStorage.getItem('pabellon')) {
        this.pabellonElegido = JSON.parse(sessionStorage.getItem('pabellon'));
      } else {
        this.pabellonElegido = this.pabellones[0];
      }
      this.internadosPorPabellon();
    });

    if (new Date().getTime() > new Date(this.currentUser.fechaExpiracionContrasena).getTime()) {
      this.openDialogCambiarContrasena(this.currentUser);
    }
  }

  openDialogCambiarContrasena(objUsuarioLog) {
    this.dialog.open(DialogCambiarContrasenaComponent, {
      width: '500px',
      data: objUsuarioLog,
      hasBackdrop: true,
      disableClose: true
    }).afterClosed().subscribe(logout => {
      if (logout) {
        this.authenticationService.logout();
        this.router.navigate(['/login']);
      }
    });
  }

  async internadosPorPabellon() {
    this.alertService.clear();
    this.searchText = null;
    this.loading = true;
    await this.internacionService.obtenerInternadosDePabellon(this.pabellonElegido).toPromise().then((x) => {
      this.internados = x.paginador.elementos.sort(
        (a, b) => a.pieza - b.pieza
      );
      // this.cargarFechaIndicaciones();
      // console.log(this.internados)
      // this.internados.forEach(element => {
      //   this.pacienteService.getEtiquetaPaciente({nroDocumento: element.dniPaciente, tipoDocumento: element.tipoDniPaciente}).subscribe(
      //     data => {
      //       element.etiquetas = data.paginador.elementos;
      //       console.log(element.etiquetas);
      //     },
      //     error => console.log(error)
      //   );
      // });
      this.loading = false;
    }, (error) => {
      this.loading = false;
      // this.alertService.error("Hubo un error en la conexion")
    });

    //this.obtenerSolicitudesPendientesXInternado(this.internados);
  }

  cargarFechaIndicaciones() {
    this.internados.forEach(internado => {
      this.medicacionService
      .indicacionActual(internado.carpeta)
      .subscribe(
        (x) => {
          console.log(x)
          if (x.paginador.elementos.length > 0) {
            this.indicacion = x.paginador.elementos[0];
            internado.fechaUltIndicacion =  moment(this.indicacion.fechaPedida, 'DD/MM/YYYY HH:mm:ss A ');
            internado.fechaUltIndicacion = internado.fechaUltIndicacion.format('DD/MM/YYYY HH:mm');
          }
        });
    });

  }

/*  async obtenerSolicitudesPendientesXInternado(internados) {
    for await (const interX of internados) {
      interX.cantidadSolicitudes = 0;

      this.jsonPedidoDxI.nroDniPaciente = interX.dniPaciente;
      this.jsonPedidoDxI.tipoDniPaciente = interX.tipoDniPaciente;
      this.jsonPedidoDxI.tipoPedido = 1;
      this.jsonPedidoDxI.sectorInforme = 1;

      this.jsonCirugia.nroDniPaciente = interX.dniPaciente;
      this.jsonCirugia.tipoDniPaciente = interX.tipoDniPaciente;
      this.jsonCirugia.fechaDesde = new Date().toLocaleDateString();

      await this.cargarPedidosCirugias(interX);
    }
  }

  async cargarPedidosCirugias(interX) {

    await this.setEstadoPedido('SOL');
    await this.cargarPedidosDxI(interX);          // CARGO LOS PEDIDOS SOLICITADOS
    await this.setEstadoPedido('PRO');
    await this.cargarPedidosDxI(interX);          // CARGO LOS PEDIDOS PROGRAMADOS
    await this.cargarCirugias(interX);
  }

  async setEstadoPedido(estado) {
    this.jsonPedidoDxI.estado = estado;
  }

  async cargarPedidosDxI(interX) {
    // console.log(interX)
    // console.log(this.jsonPedidoDxI);
    await this.pedidosService.getPedidosGenerico(this.jsonPedidoDxI).toPromise().then(respGetPedidos => {
      // console.log(respGetPedidos);
      if (respGetPedidos) {
        if (respGetPedidos.ok) {
          // interX.cantidadSolicitudes = interX.cantidadSolicitudes + respGetPedidos.pedidos.length;
          respGetPedidos.pedidos.forEach((element) => {
            if (element.categoria == 'INTERNADO' && element.nroCarpeta == interX.carpeta) {
              this.listaPedidosDxI.push(element);
              interX.cantidadSolicitudes = interX.cantidadSolicitudes + 1;
            }
          });
          
        }
      }
    }, error => {
      console.log(error);
    });
  }

  async cargarCirugias(interX) {
    await this.pedidosService.getCirugiasGenerico(this.jsonCirugia).toPromise().then(respGetCirugias => {
      // console.log(respGetCirugias);
      if (respGetCirugias.ok) {
        interX.cantidadSolicitudes = interX.cantidadSolicitudes + respGetCirugias.reservas.length;
        // this.listaCirugiasProg = respGetCirugias.reservas;
      }
    }, error => {
      console.log(error);
    });
  }
*/
  agregarMisPacientes(internado) {
    this.internacionService.agregarMisPacientes(internado).subscribe(
      (x) => {
        this.internadosPorPabellon();
      },
      (error) => {
        console.log('hubo un error');
      }
    );
  }

  sacarMisPacientes(internado) {
    this.internacionService.sacarMisPacientes(internado).subscribe(
      (x) => {
        this.internadosPorPabellon();
      },
      (error) => {
        // console.log('cagamos fuego');
      }
    );
  }

  seleccionarPaciente(internado) {
    this.pacienteService.setCurrentPatient(internado);
    sessionStorage.setItem('pabellon', JSON.stringify(this.pabellonElegido));
    this.router.navigate(['/paciente']);
  }

  selecPacPendientes(internado) {
    internado.expanded = true;
    this.pacienteService.setCurrentPatient(internado);
    sessionStorage.setItem('pabellon', JSON.stringify(this.pabellonElegido));
    this.router.navigate(['/paciente']);
  }


  imprimirPabellon() {
    this.selectedUrl = this.evolucionService.imprimirPabellon(
      this.pabellonElegido.pabellon
    );
    this.selected = true;
  }

  imprimirTodosReportesPorTurno() {
    this.selectedUrl = this.evolucionService.imprimirTodosReportesPorTurno(
      this.currentUser.usuario
    );
    this.selected = true;
  }

  salirPdf() {
    this.selected = false;
  }


  etiquetaInternado(internado) {
    return '../../assets/images/logos/COVID-CONF.JPG';
  }

  indicacionReciente(fechaUltIndicacion) {
   // console.log(fechaUltIndicacion);
    const fechaPedida = moment(fechaUltIndicacion, 'DD/MM/YYYY HH:mm');
    const diferencia = moment.duration(moment().diff(fechaPedida));
    return (diferencia.asHours() < 1);
  }

  cambiarCama(internado) {
    this.pacienteService.setCurrentPatient(internado);
    sessionStorage.setItem('pabellon', JSON.stringify(this.pabellonElegido));
    this.dialog
    .open(DialogTrasladoPacienteComponent, {
      width: '600px',
      data: internado
    })
    .afterClosed()
    .subscribe((confirmado: boolean) => {
      if (confirmado) {
        this.internadosPorPabellon();
      }
     });
  }

  estudiosPorPabellon() {
    sessionStorage.setItem('pabellon', JSON.stringify(this.pabellonElegido));
    this.router.navigate(['/estudios-pendientes-por-pabellon']);
  }

}
