<div class="container-fluid center-block  ">
  <br>
  <div class=" d-flex align-items-center justify-content-center">
    <mat-card  style="text-align: center;" >
      
      <mat-card-title
      class="mat-card-title"
      style="color: #0d3453; font-family: Arial, Helvetica; text-align: center"
      ><img
        src="../../assets/images/logos/isologo_español.png"
        alt="hospital_logo"
        style="width: 55px; height: 45px; margin: auto !important"
      />
      <span class="align-middle ml-3">enfermería</span></mat-card-title
    >
      <br>
      <alert style="font-size: 12px"></alert>
      
      <form [formGroup]="loginForm" (keydown.enter)="$event.preventDefault()" >
        <mat-card-content>
          <p>
            <mat-form-field>
              <input matInput type="text"
              formControlName="username" 
              [ngClass]="{ 'is-invalid': submitted && f.username.errors }"
              (keyup) = "convertirEnMinusculas()"
              (keyup.enter)="onSubmit()"
              placeholder="Usuario">
              <div *ngIf="submitted && f.username.errors" class="invalid-feedback">
                <div *ngIf="f.username.errors.required">Usuario es requerido</div>
              </div>
            </mat-form-field>
          </p>
          <p>
            <mat-form-field>  
              <input  (keyup.enter)="onSubmit()" matInput [type]="showPass ? 'text' : 'password'" formControlName="password" [ngClass]="{ 'is-invalid': submitted && f.password.errors }" placeholder="Contraseña" required />
              <button 
              matSuffix
              matTooltip="{{showPass ? 'Ocultar contraseña' : 'Mostrar contraseña'}}"
              (click)="password()" color="primary"
              mat-icon-button 
              >
              <mat-icon> {{showPass ? 'visibility_off' : 'visibility'}}</mat-icon>
            </button>   
            <div *ngIf="submitted && f.password.errors" class="invalid-feedback">
              <div *ngIf="f.password.errors.required">Contraseña es requerida</div>
            </div>
            </mat-form-field> 
         
          </p>
            <div id="mayus" style="display:none" class="alert alert-danger m-1">
      
              Las mayúsculas están activadas.
           
            </div >
        </mat-card-content>  
        <mat-card-actions>
            <button [disabled]="loading"  type="button" 
            class="btn btn-lg  btn-outline-info"  
            type="submit" 
            (click)="onSubmit()">iniciar sesión</button>
            <mat-progress-bar mode="indeterminate" *ngIf="loading"></mat-progress-bar>
        </mat-card-actions>
      </form>
      <div class="container">
        <div class="row">
          <div class="col align-self-center" style="text-align: center;">
              <a href="javascript:void(0);" (click)="recuperarClave()">
                <img src="./../../assets/images/login/OlvideMisDatos.png" class="" style="cursor: pointer; width: 40px;"/>
                <p style="text-align: center; line-height: 100%;font-size: 12px;">Recuperar clave</p>
              </a>
          </div>
          <!-- <div class="col align-self-center" style="text-align: center;">
            <a href="#SoporteTecnicoModal" data-toggle="modal" data-target="#SoporteTecnicoModal">
              <img src="./../../assets/images/login/soporteTecnico.png" class="" style="cursor: pointer; width: 40px;"/>
              <p style="text-align: center; line-height: 100%;font-size: 12px;">Soporte técnico</p>
            </a>
          </div> -->
        </div>
      </div>
      <br>   
      <div  class="container-imagenes" *ngIf="!loading else cargando">
        <img style="margin-right:16px" style="margin-right: 10%" id="efector-logo" src="../../assets/images/logos/logo_efector.jpg">  
        <a href="https://cidcam.org"><img id="cidam-logo" style="margin-right:9%" src="../../assets/images/logos/logo_cidcam.jpg" >  </a>     
        <a href="http://www.cenas.org.ar/"> <img  id="cenas-logo" src="../../assets/images/logos/logo_cenas.jpg" >  </a>
      </div>
      <ng-template #cargando>
        <div class="container-imagenes">
          <img style="margin-right:16px" style="margin-right: 10%" id="efector-logo" src="../../assets/images/logos/logo_efector.jpg">  
          <img id="cidam-logo" style="margin-right:9%" src="../../assets/images/logos/logo_cidcam.jpg">    
          <img id="cenas-logo" src="../../assets/images/logos/logo_cenas.jpg">
        </div>
      </ng-template>
      <br>
    </mat-card>
  </div>

  
 
</div>