import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { CajasEsterilizacionService } from '../_services/cajas-esterilizacion.service';
import { PacienteService } from '../_services/paciente.service';
import { DialogConfirmacionComponent } from '../dialog-confirmacion/dialog-confirmacion.component';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';


import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { DialogMensajeComponent } from '../dialog-mensaje/dialog-mensaje.component';

interface Reserva {
  id: number;
  idCaja: number;
  nombreCaja: string;
  fechaHoraEstado: string;
  numeroCirugia: number;
  fechaCirugia: string;
  horaCirugia: string;
  instrumentador: string;
  cirujano: string; 
  estado: string; 
  preparada: boolean;
}


@Component({
  selector: 'app-reserva-cajas',
  templateUrl: './reserva-cajas.component.html',
  styleUrls: ['./reserva-cajas.component.css']
})
export class ReservaCajasComponent implements OnInit {
  dateControl_fechaDesde = new FormControl(new Date());
  dateControl_fechaHasta = new FormControl(new Date());
  reservas: Reserva[];
  columnas: string[] = ['fecha', 'hora', 'instrumentador', 'cirujano', 'caja', 'observaciones'];
  cargando: boolean = true;
  
  //cajas
  cajas;
  cajaSeleccionadaControl  = new FormControl(''); // Control para el select
  cajaSeleccionada = '';
  filtroCajasControl = new FormControl();
  cajasFiltradas;

  //cirujanos
  cirujanos;
  cirujanosFiltrados;
  cirujanoSeleccionadoControl = new FormControl(''); 
  cirujanoSeleccionado = '';
  nombreCirujanoSeleccionado;
  filtroApellido: string = '';  
  filtroApellidoControl = new FormControl();
  protected onDestroyProperty = new Subject<void>();


  @ViewChild('inputCirujano') inputCirujano: ElementRef;

  constructor(
    private cajasEsterilizacionService: CajasEsterilizacionService,
    private pacienteService: PacienteService,
    public dialog: MatDialog,
  ) { }

  async ngOnInit() {
    await this.loadReservas(this.cajaSeleccionada, this.cirujanoSeleccionado); // Cargar reservas al inicio
    await this.obtenerCajas();
    await this.obtenerProfesionales();
    await this.suscribirseAEventos();    
  }

  suscribirseAEventos() {
    this.dateControl_fechaDesde.valueChanges.subscribe(() => {
      this.loadReservas(this.cajaSeleccionada, this.cirujanoSeleccionado); // Cargar reservas cuando cambie la fecha
    });

    this.dateControl_fechaHasta.valueChanges.subscribe(() => {
      this.loadReservas(this.cajaSeleccionada, this.cirujanoSeleccionado); // Cargar reservas cuando cambie la fecha
    });

    // Suscribirte a los cambios del select de las cajas
    this.cajaSeleccionadaControl.valueChanges.subscribe(
      idCajaSeleccionada => {
        this.loadReservas(idCajaSeleccionada, this.cirujanoSeleccionado);
        this.cajaSeleccionada = idCajaSeleccionada;
    });

    this.cirujanoSeleccionadoControl.valueChanges.subscribe(
      idCirujanoSeleccionado => {
        // Buscar el cirujano correspondiente en la lista
        // console.log(idCirujanoSeleccionado);
        this.loadReservas(this.cajaSeleccionada, idCirujanoSeleccionado );
        this.cirujanoSeleccionado = idCirujanoSeleccionado;
        if(this.cirujanoSeleccionadoControl.value){
          this.nombreCirujanoSeleccionado = this.cirujanos.find(c => c.nroMatricula == idCirujanoSeleccionado).apellido;        
        }else{
          this.nombreCirujanoSeleccionado = '';
        }
      }
    );
    
    this.filtroApellidoControl.valueChanges
        .pipe(takeUntil(this.onDestroyProperty))
        .subscribe(() => {
          this.filtroCirujanos();          
        });    

    this.filtroCajasControl.valueChanges
    .pipe(takeUntil(this.onDestroyProperty))
    .subscribe(() => {
      this.filtroCajas(); // Función para filtrar las cajas
    });
  }

  async obtenerProfesionales() {
    await this.pacienteService.getProfesional(null, null, null, null, 'ON').toPromise().then(
      data => {        
        this.cirujanos = data.paginador.elementos;        
        this.cirujanos.sort((a, b) => a.apellido.localeCompare(b.apellido));
        this.cirujanosFiltrados = this.cirujanos;
      },
      error => {
        console.log(error);        
      }
    )  }

  async obtenerCajas() {
    await this.cajasEsterilizacionService.obtenerCajas().toPromise().then(
      data => {        
        this.cajas = data;
        this.cajas.sort((a, b) => a.idCaja.localeCompare(b.idCaja));  
        this.cajasFiltradas = this.cajas;    
      },
      error => {
        console.log(error);        
      }
    );
  }

  filtroCirujanos(){
    if (!this.cirujanos) {
      return;
    }
    let search = this.filtroApellidoControl.value;
    
    if (!search) {
      this.cirujanosFiltrados = this.cirujanos;
      
      return;
    } else {
      search = search.toLowerCase();
    }
    this.cirujanosFiltrados =
      this.cirujanos.filter(
        (medicos) => medicos.apellido.toLowerCase().indexOf(search) > -1 || medicos.nroMatricula.toLowerCase().indexOf(search) > -1
      );
  }

  filtroCajas() {
    let search = this.filtroCajasControl.value;
    
    if (!search) {
      this.cajasFiltradas = this.cajas;
      return;
    } else {
      search = search.toLowerCase();
    }
    this.cajasFiltradas =
      this.cajas.filter(
        (caja) => caja.nombre.toLowerCase().indexOf(search) > -1
      );
  } 

  previousDay_fechaDesde() {
    this.cargando = true;
    const currentDate = this.dateControl_fechaDesde.value;
    currentDate.setDate(currentDate.getDate() - 1);
    this.dateControl_fechaDesde.setValue(currentDate);
  }

  nextDay_fechaDesde() {
    this.cargando = true;
    const currentDate = this.dateControl_fechaDesde.value;
    currentDate.setDate(currentDate.getDate() + 1);
    this.dateControl_fechaDesde.setValue(currentDate);
  }

  previousDay_fechaHasta() {
    this.cargando = true;
    const currentDate = this.dateControl_fechaHasta.value;
    currentDate.setDate(currentDate.getDate() - 1);
    this.dateControl_fechaHasta.setValue(currentDate);
  }

  nextDay_fechaHasta() {
    this.cargando = true;
    const currentDate = this.dateControl_fechaHasta.value;
    currentDate.setDate(currentDate.getDate() + 1);
    this.dateControl_fechaHasta.setValue(currentDate);
  }

  async loadReservas(idCaja, matriculaCirujano) {
    const selectedDate_fechaDesde = this.dateControl_fechaDesde.value;
    const selectedDate_fechaHasta = this.dateControl_fechaHasta.value;
    // Realiza una solicitud al backend para cargar las reservas para la fecha seleccionada
    await this.fetchReservasFromBackend(selectedDate_fechaDesde, selectedDate_fechaHasta, idCaja, matriculaCirujano);
  }

  async fetchReservasFromBackend(fechaDesde: Date, fechaHasta: Date, idCaja, matriculaCirujano){
    // Actualiza this.reservas con los datos cargados desde el backend
    await this.cajasEsterilizacionService.obtenerReservasPorFecha(fechaDesde, fechaHasta, idCaja, matriculaCirujano)
      .toPromise().then(x => {
        if(x.ok){
          this.reservas = x.elementos;  
        }else{
          this.reservas = null;
        }
        this.cargando = false;        
      });
  }

  limpiarFiltroCaja() {
    this.cajaSeleccionadaControl.setValue('');
  }

  limpiarFiltroCirujano(){
    this.cirujanoSeleccionadoControl.setValue('');
  }

  actualizarEstado(reserva){
    reserva.estado === 'ACT' ? reserva.estado= 'PRP' : reserva.estado= 'ACT';
    this.dialog
      .open(DialogConfirmacionComponent, {
        data: `¿Desea modificar el estado de la reserva?`,
      })
      .afterClosed()
      .subscribe((confirmado: boolean) => {
        if (confirmado) {
          this.cajasEsterilizacionService.modificarEstadoReservaCajas({
            numeroCirugia: reserva.numeroCirugia,
            observaciones: null,
            estado: reserva.estado,
          }).toPromise().then(x => {
            this.dialog.open(DialogMensajeComponent, {
              width: '500px',
              data: x,
              }
            )
          })
        } else {
          reserva.estado === 'ACT' ? reserva.estado= 'PRP' : reserva.estado= 'ACT';
        }
      });

  }
}
