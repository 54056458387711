<div class="container-fluid" style="margin-top: 1%; margin-bottom: 1%;">
  <mat-card [tabindex]="0" class="card-principal">
    <div class="container-fluid">
        
        <h3>
          Pacientes para internar
        </h3>
     
        <div
          class="d-flex justify-content-center"
          style="margin-top: 10%"
          *ngIf="loading">
          <mat-spinner [diameter]="60"></mat-spinner>
          
        </div>
        

       <div [hidden]="loading" class="row" style="width: 70%;">
         <div class="col"> 
          <form [formGroup]="filtroBusquedaForm">
          <div class="row" >
              <div class="col-xs-8 col-sm-8 col-md-4 col-lg-3 col-xl-3">
                    <mat-form-field style="width: 100%">
                      <mat-label>Fecha desde *</mat-label>
                      <input matInput formControlName="fechaDesde"  [matDatepicker]="pickerfechaDesde">
                      <mat-datepicker-toggle matSuffix [for]="pickerfechaDesde" matTooltip="Seleccionar fecha" ></mat-datepicker-toggle>
                      <mat-datepicker #pickerfechaDesde ></mat-datepicker>
                    </mat-form-field>
              </div>
              <div class="col-xs-8 col-sm-8 col-md-4 col-lg-3 col-xl-3">
                    <mat-form-field style="width: 100%;">
                      <mat-label>Fecha hasta *</mat-label>
                      <input matInput formControlName="fechaHasta" [matDatepicker]="pickerfechaHasta">
                      <mat-datepicker-toggle matSuffix [for]="pickerfechaHasta" matTooltip="Seleccionar fecha"></mat-datepicker-toggle>
                      <mat-datepicker #pickerfechaHasta ></mat-datepicker>
                    </mat-form-field>
                
              </div>
              <div class="col-xs-8 col-sm-8 col-md-4 col-lg-3 col-xl-2">
                <button mat-raised-button 
                        id="buscar-pacientes"
                        color="primary" 
                        (click)="buscarPacientesEnEspera()" 
                        style="border-radius: 20px;">
                    <mat-icon>search</mat-icon>
                    Buscar pacientes
                </button>
            
              </div>
           </div> 
          </form>
         </div>  
      </div> 

      <mat-card-content>
        
      <div  class="row" style="margin-top: 1%;">
        <div [hidden]="loading" class="col" style="overflow: auto;">
          
          <div style="text-align: center; margin-top: 5%;  " *ngIf="sinPacientes">
            <mat-card class="sin-elementos-card">
              <h5 style="color:#3F51B5">No se encontraron pacientes</h5>
            </mat-card>
          </div>

          <mat-form-field *ngIf="!sinPacientes">
            <mat-label>Buscar</mat-label>
            <input matInput (keyup)="applyFilter($event)" autocomplete="off" />
          </mat-form-field>
          <span style="margin-left:1%;"></span>

          <div class="contenedor-cards-simil-tabla" *ngIf="!sinPacientes">

            <div class="card-simil-tabla" *ngFor="let paciente of dataSourceItems.filteredData">
              <div class="contenedor-detalle-card" id="detalle-card">
                <div class="contenedor-atributos">
                  <span class="span-fecha-internacion">Fecha internación:<strong> {{paciente.fechaInternacion}} </strong></span>
                  <span class="span-fecha-cirugia">Fecha cirugía:<strong> {{paciente.fechaCirugia}} </strong> a las <strong> {{ paciente.horaCirugia }} </strong></span>
                  <span class="span-cirugia">Cirugía: <strong>{{paciente.cirugia}} </strong></span>
                  <span class="span-cirujano">Cirujano: <strong>{{paciente.cirujano ? paciente.cirujano : 'No asignado'}} </strong></span>
                  <span class="span-anestesia">Anestesia: <strong>{{paciente.anestesia}} </strong></span>
                  <span class="span-sala">Sala: <strong>{{paciente.sala}} </strong></span>
                  <span class="span-paciente">Paciente: <strong>{{paciente.paciente}} </strong></span>
                  <span class="span-sexo">Sexo: <strong>{{paciente.sexo}} </strong></span>
                  <span class="span-edad">Edad: <strong>{{paciente.edad}} </strong></span>
                  <span class="span-mutual">Mutual: <strong>{{paciente.nombreFinanciador ? paciente.nombreFinanciador : '-'}} </strong></span>
                  <span class="span-ubicacion">Ubicación: <strong>{{paciente.pieza}} - {{paciente.cama}} </strong></span>
                  <span class="span-ingreso-habitacion">Ingreso habitación: <strong>{{( paciente.ingHab | titlecase ) || '-'}} </strong></span>
                  <span class="span-estado">Estado: <strong>{{paciente.nomEstado}}</strong></span>
                </div>
                
                <div class="contenedor-acciones">
                  <span class="span-botones">
                    <!-- No tiene caja asignada -->
                    <button
                        class="boton"
                        id="boton-asignar"
                        (click)="asignarCama(paciente)"
                        color="primary"
                        mat-raised-button
                        *ngIf="paciente.estado == 'E' && (paciente.pieza !== '' && paciente.cama !== '' && paciente.estado == 'E')"
                      >
                      Asignar cama 
                      <mat-icon>hotel</mat-icon>  
                    </button>
                    <button
                        class="boton"
                        id="boton-asignar-doble"
                        (click)="asignarCama(paciente)"
                        color="primary"
                        mat-raised-button
                        *ngIf="paciente.estado == 'E' && !(paciente.pieza !== '' && paciente.cama !== '' && paciente.estado == 'E')"
                      >
                      Asignar cama 
                      <mat-icon>hotel</mat-icon>  
                    </button>
                      <button
                        (click)="abrirDialogConfirmacion(paciente)"
                        id="boton-desasignar"
                        class="boton"
                        color="warn"
                        mat-raised-button
                        *ngIf="paciente.pieza !== '' && paciente.cama !== '' && paciente.estado == 'E'"
                      >
                      Desasignar cama  
                      </button>
                  </span>
                </div>
                
              </div>
            </div>
  
          </div>
          
        </div>
      </div>
    </mat-card-content>

    </div>
  </mat-card>
</div>
