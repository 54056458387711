import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { User } from '../_models/user.model';
import { Router } from '@angular/router';
import { AppComponent } from '../app.component';

const httpOptions = {
  headers: new HttpHeaders({})
};

@Injectable({ providedIn: 'root' })

export class InternacionService {

    baseUrl: string;
   
   



    constructor(
        private http: HttpClient,
        private router: Router) {
          
            this.baseUrl = AppComponent.baseUrl;
    }

    obtenerPabellones(){
        return this.http.get<any>(this.baseUrl + '/internacion/pabellones')
    }

    obtenerInternadosDePabellon(pabellon){
        return this.http.post<any>(this.baseUrl + '/internacion/seguro/get_internados_enfermero', JSON.stringify(pabellon))
    }

    agregarMisPacientes(internado){
        return this.http.post<any>(this.baseUrl + '/enfermeria/seguro/agregar-mis-pacientes', JSON.stringify(internado))
    }

    sacarMisPacientes(internado){
        return this.http.post<any>(this.baseUrl + '/enfermeria/seguro/sacar-mis-pacientes', JSON.stringify(internado))
    }

    setTrasladoPaciente(traslado){
       
        return this.http.post<any>(this.baseUrl + '/internacion/seguro/setTrasladoPaciente', traslado)
    }

    getPacientesEnEspera(filtroBusqueda){

        return this.http.post<any>(this.baseUrl + '/internacion/seguro/getPacientesEnEspera', filtroBusqueda)
    }

    setCamaPacienteEnEspera(paciente){
       
        return this.http.post<any>(this.baseUrl + '/internacion/seguro/setCamaPacienteEnEspera', paciente)
    }

    getSolicitudesPendientesPorPabellon(pabellon){
        return this.http.post<any>(this.baseUrl + '/internacion/seguro/getSolicitudesPendientesPorPabellon', JSON.stringify(pabellon))
    }

    getCirugiasPorCarpetaDeHoy(carpeta) {
        return this.http.get<any>(this.baseUrl + '/cirugias/seguro/getIngresoHabitacionCirugia/' + carpeta);
    }

    setIngresoHabitacionCirugia(cirugias) {
        return this.http.post<any>(this.baseUrl + '/cirugias/seguro/setIngresoHabitacionCirugia', JSON.stringify(cirugias));
    }
    
}
