export class SignosVitales {
  public constructor(init?: Partial<SignosVitales>) {
    Object.assign(this, init);
  }

  frecuenciaCardiaca: number;
  frecuenciaRespiratoria: number;
  hemoglucotest: number;
  saturacion: number;
  temperatura: number;
  tensionArterial: number;
  rass;
  camicu;
}
