import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, BehaviorSubject } from 'rxjs';
import { AppComponent } from '../app.component';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  private baseUrl: string;

  constructor(
    private http: HttpClient
  ) {
    this.baseUrl = AppComponent.baseUrl;
  }

  recuperarClave(nombreUsuario) {
    let form = {
      usuario: nombreUsuario,
      idAplicacion: 1,
    };

    return this.http.post<any>(this.baseUrl + '/login/enviarMensajeRecuperarContrasenia', form);
  }

  passwordChange(nombreUsuario, formValue) {
    let form = {
      usuario: nombreUsuario,
      oldPassword: formValue.actual,
      newPassword: formValue.confirmPassword,
      nombreApp: 'Consultorios'
    };

    return this.http.post<any>(this.baseUrl + '/usuariosLDAP/cambiarContrasena', form);
  }
}
