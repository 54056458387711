import { Component, Inject, OnInit } from '@angular/core';
import { AuthenticationService } from '../_services/authentication.service';
import { PacienteService } from '../_services/paciente.service';
import { AlertService } from '../_services/alert.service';
import { TriageService } from '../_services/triage-paciente.service';
import { MatPaginator } from '@angular/material/paginator';
import { ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { UntypedFormBuilder,UntypedFormControl,UntypedFormGroup } from '@angular/forms';
import { Validators } from '@angular/forms';
// import { CrearTriageComponent } from '../crear-triage/crear-triage.component';
import * as moment from 'moment';
import { MAT_DATE_LOCALE, DateAdapter, MAT_DATE_FORMATS } from '@angular/material/core';
import { MatStepper } from '@angular/material/stepper';
import { MatTableDataSource } from '@angular/material/table';
import {
  MAT_MOMENT_DATE_FORMATS,
  MomentDateAdapter,
  MAT_MOMENT_DATE_ADAPTER_OPTIONS,
} from '@angular/material-moment-adapter';

import { STEPPER_GLOBAL_OPTIONS } from '@angular/cdk/stepper';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { DialogConfirmacionComponent } from '../dialog-confirmacion/dialog-confirmacion.component';
import { DialogMensajeComponent } from '../dialog-mensaje/dialog-mensaje.component';





@Component({
  selector: 'app-triage-enfermeria',
  templateUrl: './triage-enfermeria.component.html',
  styleUrls: ['./triage-enfermeria.component.css']
})

export class TriageEnfermeriaComponent implements OnInit {
    nroDoc;
    nombre;
    apellido;
    hayTriageHoy = false;
    submitted = false;
    searched = false;
    loading = false;
    triages:any[];
    todosLosTriage: any[];
    existenTriages: boolean = false;
    dataSourceItems;
    currentUser;
    filtrotriageForm:UntypedFormGroup = new UntypedFormGroup({ tipodni: new UntypedFormControl(),
      dni: new UntypedFormControl(),
      fechaDesde:new UntypedFormControl() ,
      fechaHasta: new UntypedFormControl(),
      estado:new UntypedFormControl(),
      prioridad: new UntypedFormControl(),
      medico:new UntypedFormControl()}
      );
      
    dataTablaItems: any[] = [];
    dataSourceItemsFiltro: any;
    displayedColumnasItems: string[] = ['DNI', 'Apellido','Color','Respiratorio','Medico','Servicio','subCat','FechaIngreso','HoraIngreso','Estado','Acciones'];

    //filtro por medico
    medicos: any[];
    filtermedCtrl: UntypedFormControl = new UntypedFormControl();
    medFiltrados: any[]= [];

    //listado de profesionales
    profesionales: any[]= [];
    protected onDestroyProperty = new Subject<void>();
    //spinner cargando
    cargando:boolean = true;

    displayColumns = ['DNI','Nombre','Apellido','Edad','Sexo','Email','Telefono','Triage'];
    // filtros seleccionados
    fecha = new Date();
    //estado del triage
    estadoTriage : any[] = [];
    
    listaPrioridades: any[];
    listaTriageSinTemp: any[]=[];
    @ViewChild(MatPaginator) paginator: MatPaginator;
    constructor( 
      private authService: AuthenticationService,
      public dialog: MatDialog,
      private pacienteService: PacienteService,
      private route:Router,
      private formBuilder: UntypedFormBuilder,
      private triageService:TriageService
      
      ) { 
        this.pacienteService.getCurrentPatientLocal();
        this.currentUser = this.authService.currentUserValue;
        
     }

 async ngOnInit() {
     await this.listarProfesionales();
     await this.listarEstados();
     await this.listenmedFilter();
     await this.buildfiltrotriageForm();
     await this.getPrioridadesTriage();
     await this.cargarTriages(this.filtrotriageForm);
  }

  crearTriage(paciente){
    this.pacienteService.setCurrentPatientLocal(paciente);
    this.route.navigate(['/crear-triage']);
  }

  async getPrioridadesTriage() {
    await this.triageService.cargarPrioridades().toPromise().then(resp => {
      if(resp.ok){
      this.listaPrioridades = resp.elementos;
      
      this.listaPrioridades.unshift({
        idPrioridad:4,
        color:'Todos',
        
       });
        // console.log(this.listaPrioridades
      }
    }, error => {
      console.log(error)
    })
  }

  /* filtros para el listado de los triages */
  async cargarTriages(filtrotriageForm) {
    this.loading = true;
    this.searched = true;
    //armar objeto filtro
    const filtroTriage = {
      
      'paciente':  {
              'nroDocumento': filtrotriageForm.get('dni').value,
              'tipoDocumento': filtrotriageForm.get('tipodni').value
              },
       'estado': {
          'estado': filtrotriageForm.get('estado').value.estado,
          'desc': filtrotriageForm.get('estado').value.desc
          },
       'fechaDesde': moment(filtrotriageForm.get('fechaDesde').value).format('DD/MM/YYYY'),
       'fechaHasta': moment(filtrotriageForm.get('fechaHasta').value).format('DD/MM/YYYY'),
       'subCategoria': {
          'prioridad': {
          'idPrioridad':filtrotriageForm.get('prioridad').value
          },
         },
       'medDerivado': {
         'matricula':filtrotriageForm.get('medico').value.nroMatricula,
         'nombreCompleto':filtrotriageForm.get('medico').value.apellido
        }
        
    };
    
    await this.triageService.getTriagesPorFiltro(filtroTriage).toPromise().then(
        (data) => {
       
          if (data.ok) {
            this.triages = data.data;
            
            // console.log(this.listaTriageSinTemp)
            this.existenTriages=( this.triages.length > 0 );
            this.cargando=false;
            this.dataSourceItemsFiltro= new MatTableDataSource(this.triages);
          } else {
            this.existenTriages = false;
            this.triages = [];
          }
          this.loading = false;
        },
        (error)=>{
          console.log(error);
        }
       ); 
  }
async buildfiltrotriageForm(){
   this.filtrotriageForm = this.formBuilder.group({
        tipodni: [null],
        dni: [null],
        fechaDesde:[new Date()] ,
        fechaHasta: [new Date()],
        estado:[this.estadoTriage.find(estado=> estado.estado === 'ING')],
        prioridad: [null],
        medico:{
          matricula:null,
          apellido:'Todos'
        }
   })
  //  console.log(this.filtrotriageForm.get('estado').value);
 }

  openDialogPacientes(): void {
    const dialogRef = this.dialog.open(BuscarPacienteDialog, {
      width: '500px',
      data: {
        verPaciente: true,
        
      },
    });
    
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        const paciente = {
          nroDocumento: result.nroDocumento,
          tipoDocumento: result.tipoDocumento,
          str_paciente: result.apellido,
          edad: result.edad,
          nacimiento: result.fechaNacimiento,
          email: result.email
        };
        this.seleccionarPaciente(paciente);
        

      }
    });
    
  }
  seleccionarPaciente(paciente) 
  {    
    this.pacienteService.setCurrentPatientLocal(paciente);
    this.route.navigate(['/crear-triage']);
  }
  
  volver() 
  {
    // this.pacienteService.removeCurrentPatient();
    this.route.navigate(['/home']);
  }
  cambiarAtencion(paciente)
  {
    this.loading = true;
    this.triageService.cambiarEstado(paciente).subscribe(
      (respuesta)=>{
        if(respuesta.ok){
          // console.log('Asi quedo el paciente al cambiar el estado',paciente);
          this.cargarTriages(this.filtrotriageForm);
        }
        
      },
      (error)=>{
        console.log(error);
      }

    )
   
    }

    estadoAnterior(paciente)
    {
      // console.log(this.estadoTriage)
      if(paciente.estado.estado == 'ESP'){
        
        paciente.estado = this.estadoTriage.find((estado)=> estado.estado === 'ING');
        
        }
      
  
      this.cambiarAtencion(paciente);
      
    }

    estadoSiguiente(paciente)
    {

      if(paciente.estado.estado == 'ING'){
        paciente.estado = this.estadoTriage.find((estado)=> estado.estado === 'ESP');

      }
      this.cambiarAtencion(paciente);
      
    }


    visualizarDatos(paciente)
    {
      // console.log(paciente);
      this.pacienteService.setCurrentPatient(paciente);
      this.route.navigate(['/visualizacion-paciente-triage',paciente])

    }


    filtroMedicos(){
      if (!this.profesionales) {
        return;
      }
      let search = this.filtermedCtrl.value;
      
      if (!search) {
        this.medFiltrados = this.profesionales;
        
        return;
      } else {
        search = search.toLowerCase();
      }
      this.medFiltrados =
        this.profesionales.filter(
          (medicos) => medicos.apellido.toLowerCase().indexOf(search) > -1 || medicos.nroMatricula.toLowerCase().indexOf(search) > -1
        );
 
    }

    async listenmedFilter() 
    {
      this.filtermedCtrl.valueChanges
        .pipe(takeUntil(this.onDestroyProperty))
        .subscribe(() => {
          this.filtroMedicos();
          
        });
    }
  async listarProfesionales()
    {
      let matricula= null;
      let servicio  = null;
      let especialidad= null;
      let condicion = null;
      let estado = 'ON';
      
    await  this.pacienteService.getProfesional(matricula,servicio ,especialidad , condicion , estado).toPromise().then(
          (data) => {
            this.profesionales = data.paginador.elementos;
            
            this.medFiltrados = data.paginador.elementos;
            this.profesionales.splice(0,0,{nroMatricula: 'null',apellido: 'Todos'});
        
          },
          (error) => {
            console.log(error);
          }
        )
      
      }

    async listarEstados()
    {
        await this.triageService.getEstadosTriage().toPromise().then(
          (data)=>{
            // console.log(data);
            if(data.ok){
              this.estadoTriage = data.elementos;
              this.estadoTriage.unshift({
                estado:null, //para no pasarle null 
                desc:'Todos'
              })
            }
            
          },
          (error)=>{
            console.log(error);
          }

          
        )
      }
      eliminarTriage(paciente){
       
        this.dialog.open(DialogConfirmacionComponent, {
          data: '¿Seguro desea eliminar el Triage del paciente: '+ paciente.paciente.apellido + ', DNI:' + paciente.paciente.nroDocumento + '?',
        }).afterClosed().subscribe((confirmado: boolean) => {
          if (confirmado) {
            paciente.estado.estado = 'ANU';
            paciente.estado.desc = 'Anulado'
            this.triageService.cambiarEstado(paciente).subscribe((respuesta) => {
              if (respuesta.ok) {
                this.openRespuesta(respuesta);
              }
            })
          }
        })


      }
      openRespuesta(respuesta) {
        this.dialog.open(DialogMensajeComponent, {
          width: '500px',
          data: {ok:respuesta.ok, mensaje:respuesta.mensaje}
        }).afterClosed().subscribe(()=> {
            location.reload()     
        })
      }

}
  //componente para la busqueda de pacientes
  @Component({
    selector: 'dialog-buscar-paciente',
    templateUrl: 'dialog-buscar-paciente.html',
    styleUrls: ['./triage-enfermeria.component.css'],
  })
  export class BuscarPacienteDialog {
    nroDoc;
    nombre;
    apellido;
    searched = false;
    pacientes = [];
    loading = false;
   
  constructor(
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<BuscarPacienteDialog>,
    private pacienteService: PacienteService,
    @Inject(MAT_DIALOG_DATA) public data
  ) {}

  buscarPaciente() {
    this.loading = true;
    let doc = -1;
    let nombre = -1;
    
    if (this.nroDoc) {
      doc = this.nroDoc;
    }
    if (this.nombre) {
      nombre = this.nombre;
      
    }
    this.pacienteService.buscarPaciente(nombre,-1, doc).subscribe(
      (data) => {
        this.searched = true;
        this.pacientes = data.paginador.elementos;
        // console.log(this.pacientes);
        this.pacientes = this.pacientes.filter((paciente)=> paciente.tipoDocumento != 9 ); // filtro los pacientes temporales
        this.loading = false;
      },
      (error) => {
        this.searched = true;
        console.log(error);
        this.loading = false;
      }
    );
    
  }
  edadPaciente(e){
    let age=0;
    if(e.fechaNacimiento != ''){
      
      //edad = (Math.abs(Date.now()- e.fechaNacimiento));
      //console.log(edad)
      //edad = Math.floor((edad / (1000 * 3600 * 24))/365);

      //console.log(edad)
      let auxiliar: string = e.fechaNacimiento;
      let aux = auxiliar.substring(3,5) + '/' + auxiliar.substring(0,2) + '/' + auxiliar.substring(6,10);
      const today = new Date();
      const birthDate = new Date(aux);
      age = today.getFullYear() - birthDate.getFullYear();
      const m = today.getMonth() - birthDate.getMonth();

      if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
        age--;
      }
    }
    else{
      return '';
    }
    return age + ' años -';
  }
  openCrearPacienteDialog() {
    
  this.dialog.open(CrearPacienteDialog, {
      width: '500px',
      data: this.data,
    });
   
    this.closePacienteDialog();
  }
  closePacienteDialog(){
    this.dialogRef.close();

  }
 
  }

  //componente para la creacion de pacientes
  @Component({
    selector: 'dialog-crear-paciente',
    templateUrl: 'dialog-crear-paciente.html',
    styleUrls: ['./triage-enfermeria.component.css'],
    providers: [
      { provide: MAT_DATE_LOCALE, useValue: 'es_AR' },
      {
        provide: STEPPER_GLOBAL_OPTIONS,
        useValue: { displayDefaultIndicatorType: false },
      },
      {
        provide: DateAdapter,
        useClass: MomentDateAdapter,
        deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
      },
      { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS },
    ],
  })
  export class CrearPacienteDialog  {
    currentUser;
    registerForm: UntypedFormGroup;
    maxDate: Date = new Date();
    submitted: boolean = false;
    loading: boolean = false;
    paciente: any;
    genders = [
      { value: 'M', descripcion: 'Masculino' },
      { value: 'F', descripcion: 'Femenino' },
    ];
  
    constructor(
      private formBuilder: UntypedFormBuilder,
      private route: Router,
      private pacienteService: PacienteService,
      private authService: AuthenticationService,
      private alertService: AlertService,
      public dialogRef: MatDialogRef<CrearPacienteDialog>,
      public dialogBusqueda: MatDialogRef<BuscarPacienteDialog>,
      @Inject(MAT_DIALOG_DATA) public data
    ) {
      this.currentUser = this.authService.currentUserValue;
      // console.log(this.currentUser.usuario)
    }
      
    ngOnInit() {
     this.buildForm();
    //  this.pacienteService.removeCurrentPatient();
    }


    private buildForm() {
      this.registerForm = this.formBuilder.group({
       
          nombre: [
            '',
            [Validators.required, Validators.pattern('^[A-Za-zñÑáéíóúÁÉÍÓÚ ]+$')],
          ],
          apellido: [
            '',
            [Validators.required,Validators.pattern('^[A-Za-zñÑáéíóúÁÉÍÓÚ ]+$')],
          ],
          email: [
           '',
          [
            
            Validators.email,
            Validators.pattern(
              '^[A-Za-zñÑáéíóúÁÉÍÓÚ0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$'
            ),
           ],
          ],
  
          telefono: [
            '',
            [
              
              Validators.maxLength(16),
              Validators.minLength(7),
              Validators.pattern('[0-9]*'),
            ],
          ],
          fechaNacimiento: ['', [Validators.required]],
          sexo: ['', Validators.required],
          localidad: ['La Plata'],
          domicilio: [
            'No suministrado',
            [ Validators.pattern('^[A-Za-zñÑáéíóúÁÉÍÓÚ ]+$')],
          ],
          codPostal: ['1900', [ Validators.pattern('[0-9]*')]],


          });
        }
        get f() {
          return this.registerForm.controls;
        }
      
        guardarPaciente() {
        
          let pacienteNuevo;
          this.submitted = true;
          
          this.alertService.clear();
          if (this.registerForm.invalid) {
            
            return;
          }
          this.loading = true;
          pacienteNuevo = {
            nombre: this.registerForm.get('nombre').value,
            apellido: this.registerForm.get('apellido').value,
            // dni: this.registerForm.get('dni').value,
            email: this.registerForm.get('email').value,
            sexo: this.registerForm.get('sexo').value,
            fechaNacimiento: moment(this.registerForm.get('fechaNacimiento').value.date).format('DD/MM/YYYY'),
            domicilio:this.registerForm.get('domicilio').value,
            localidad: this.registerForm.get('localidad').value,
            codPostal: this.registerForm.get('codPostal').value,
            telefono: this.registerForm.get('telefono').value,
            usuario: null,
            password:null,
            userModifico: this.currentUser.usuario
            
          }

          //verifico si el campo email esta vacio y le pongo por defecto No tiene
          if(this.registerForm.get('email').value == ''){
            this.registerForm.get('email').setValue('NO TIENE');
            

          }
           //verifico si el campo telefono esta vacio y le pongo por defecto No suministrado
          if(this.registerForm.get('telefono').value == ''){
            this.registerForm.get('telefono').setValue('No suministrado')
            

          }
          // console.log(pacienteNuevo)
          this.pacienteService
            .crearPaciente(
              this.registerForm.value.nombre,
              this.registerForm.value.apellido,
              this.registerForm.value.sexo,
              moment(this.registerForm.value.fechaNacimiento).format('DD/MM/YYYY'),
              this.registerForm.value.telefono,
              this.registerForm.value.email,
              this.registerForm.value.domicilio,
              this.registerForm.value.localidad,
              this.registerForm.value.codPostal,
              pacienteNuevo.usuario,
              pacienteNuevo.password,
              
            )
            .subscribe(
              (data) => {
                
                if (data.ok) {
                  this.pacienteService.buscarPaciente(-1,-1,data.numeroDocumento).subscribe(
                    (data2)=>{
                      // console.log(data2)
                      this.paciente= data2.paginador.elementos[0];
                      // console.log('Paciente:',this.paciente)
                      // this.pacienteService.setCurrentPatientLocal(this.paciente)
                      this.dialogBusqueda.close();
                      this.dialogRef.close(this.paciente)
                      }
                  )
                  
                  this.dialogRef.afterClosed().subscribe((result) => {
                    
                    if (result) {
                      const paciente = {
                        nroDocumento: result.nroDocumento,
                        tipoDocumento: result.tipoDocumento,
                        str_paciente: result.apellido,
                        edad: result.edad,
                        nacimiento: result.fechaNacimiento,
                       
                      };
                      
                      this.pacienteService.setCurrentPatientLocal(paciente)
                      this.route.navigate(['/crear-triage']);
                      
                     } else {
                      this.alertService.error(data.mensaje);
                    }
                    })
                    
                 } else {
                  this.alertService.error(data.mensaje);
                }
                 
                this.loading = false;
                // this.registerForm.reset();
               },
              (error) => {
                this.alertService.error('Se genero un error');
                console.log(error);
                this.loading = false;
                this.registerForm.reset();
              }
             
                  );
                
              }
          getErrorMessage(Campo) {
          return this.registerForm.get(Campo)
          .hasError('required') ? 'Este campo es obligatorio' : '';
            }
      
     

        }


