import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import {MatToolbarModule} from '@angular/material/toolbar';
import { User } from '../_models/user.model';
import { BehaviorSubject } from 'rxjs';
import { AuthenticationService } from '../_services/authentication.service';
import { PacienteService } from '../_services/paciente.service';
import { NavBarServiceService } from '../_services/nav-bar-service.service'
import { MatDialog } from '@angular/material/dialog';
import { DialogCambiarContrasenaComponent } from '../dialog-cambiar-contrasena/dialog-cambiar-contrasena.component';

@Component({
  selector: 'app-manager',
  templateUrl: './manager.component.html',
  styleUrls: ['./manager.component.css']
})

export class ManagerComponent implements OnInit {

  navLinks: any[];
  activeLinkIndex = -1; 
  currentUser: User;
  currentPatient;
  success;
  userData: any;
  userDataSubject: BehaviorSubject<any>;
  horusUrl: any;

  constructor(
    private pacienteService: PacienteService,
    private router: Router,
    private authenticationService: AuthenticationService,
    private navBarService: NavBarServiceService,
    public dialog: MatDialog
  ) {
    
    this.authenticationService.currentUser.subscribe(x => {
      this.currentUser = x;
    })
    
    this.pacienteService.currentPatient.subscribe(x => {
      this.currentPatient = x
    });
  }
   
  toggleNavBar() {
    this.navBarService.toggle();
  }

  closeNavBar() {
    this.navBarService.close();
  }
  
  toggleSuccess() {
    this.success == !this.success;
  }
  
  ngOnInit(): void {

  }

  logout() {
    this.currentPatient = null
    this.authenticationService.logout();
    this.router.navigate(['/login']);
  }

  volver(){
    this.pacienteService.removeCurrentPatient()
    this.router.navigate(['/internados'])
  }

  irAlHistorial() {
    window.open(window.location.protocol + "//" + this.horusUrl + "/he_fe/#/login?site=internacion/ver/" + this.currentPatient.carpeta, "_blank");
  }

  cambiarContrasena() {
    this.dialog.open(DialogCambiarContrasenaComponent, {
      width: '500px',
      data: this.currentUser,
      hasBackdrop: true,
    });
  }

}


