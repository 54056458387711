import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { User } from '../_models/user.model';
import { Router } from '@angular/router';
import { AppComponent } from '../app.component';

const httpOptions = {
  headers: new HttpHeaders({})
};

@Injectable({ providedIn: 'root' })

export class MedicacionService {

    baseUrl: string;

    constructor(
        private http: HttpClient,
        private router: Router) {
            this.baseUrl = AppComponent.baseUrl;
    }

    obtenerMedicamentos() {
        return this.http.post<any>(this.baseUrl + '/internacion/farmaceutico/seguro/getMedicamentoxNombre', 'NULL');
    }

    indicacionActual(carpeta) {
        const body = {carpeta: carpeta, tipo: 8};
        return this.http.post<any>(this.baseUrl + '/internacion/medicamentos/seguro/indicacion_vigente', body );
    }

    historialIndicaciones(carpeta) {
        let body = {carpeta: carpeta, tipo: 8};
        return this.http.post<any>(this.baseUrl + '/internacion/medicamentos/seguro/historial-indicaciones', body );
    }

    itemDePedido(numeroPedido, numeroItem) {
        const body = {numeroPedido:numeroPedido, numeroItem: numeroItem}
        return this.http.post<any>(this.baseUrl + '/internacion/medicamentos/seguro/item_pedido_detalle', body);
    }

    registroMedicamento(registro) {
        return this.http.post<any>(this.baseUrl + '/enfermeria/seguro/registro-medicamento', registro);
    }

    borrarRegistro(registro) {
        return this.http.post<any>(this.baseUrl + '/enfermeria/seguro/borrar-registro', registro);

    }

    getRegistrosMedicamentos(idCarpeta, fechaDesde, fechaHasta) {
        return this.http.get<any>(
          this.baseUrl +
          '/enfermeria/getRegistrosMedicamentos/' +
          idCarpeta +
          '/' +
          fechaDesde +
          '/' +
          fechaHasta
        );
      }

}
