<mat-accordion >
  <mat-expansion-panel *ngFor="let group of this.grouped" [disabled]="chequeoSubmenu(group[0])">
    <mat-expansion-panel-header>
        <mat-panel-title>        
      <!--  <a  routerLink="{{group[0].url}}" (click)="inputSideNav.toggle()" class="link-menu">
         {{group[0].nombreMenu}}
        </a> -->
        <a>{{group[0].nombreMenu}}</a>

  </mat-panel-title>

  <mat-panel-description> 
    
  </mat-panel-description>
 
</mat-expansion-panel-header>
<ul>
  <li  *ngFor="let funcion of group">
    
    <a
      routerLink="{{ funcion.url }}"
      [state]="{ data: funcion.includeUrl }" 
      (click)="inputSideNav.toggle()"
      class="link-menu"
      >{{funcion.nombreSubMenu}}</a
    >
  </li>
</ul>

</mat-expansion-panel>

</mat-accordion>
  
  