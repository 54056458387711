<h1 mat-dialog-title class="tituloDialog" class="latoregular">Actualizar contraseña</h1>
<div mat-dialog-content>
    <div class="d-flex justify-content-center" style="margin-top: 10%" *ngIf="loading">
            <mat-spinner [diameter]="60"></mat-spinner>
    </div>
    
    <form [formGroup]="actualizarContraForm" (keydown.enter)="$event.preventDefault()" id="formActualizarContra" *ngIf="!loading">
            <mat-divider class="divisor"></mat-divider>

            <!-- Contraseña Actual -->
            <mat-form-field appearance="outline">
                    <mat-label>Contraseña actual</mat-label>
                    <input  matInput [type]="showPass[0] ? 'text' : 'password'" formControlName="actual"/>
                    <button matTooltip="{{showPass[0] ? 'Ocultar contraseña' : 'Mostrar contraseña'}}"
                            (click)="showHidePassword(0)"
                            color="primary"
                            matSuffix
                            mat-icon-button>
                            <mat-icon>{{showPass[0] ? 'visibility_off' : 'visibility'}}</mat-icon>
                    </button>
            </mat-form-field>
    
            <!-- Contraseña Nueva -->
            <mat-form-field appearance="outline">
                    <mat-label>Contraseña nueva</mat-label>
                    <input  matInput [type]="showPass[1] ? 'text' : 'password'" formControlName="password" onpaste="return false;"/>
                    <button matTooltip="{{showPass[1] ? 'Ocultar contraseña' : 'Mostrar contraseña'}}"
                            (click)="showHidePassword(1)"
                            color="primary"
                            matSuffix
                            mat-icon-button>
                            <mat-icon>{{showPass[1] ? 'visibility_off' : 'visibility'}}</mat-icon>
                    </button>
            </mat-form-field>
            <!-- <mat-error style="font-size:13px; margin-bottom: 1px;" *ngIf="(actualizarContraForm.errors?.passMustNotMatch && submitted)">
                            La contraseña actual y la nueva no pueden ser iguales </mat-error> -->

            <!-- Confirmar Contraseña Nueva -->
            <mat-form-field appearance="outline" required>
                    <mat-label>Confirmar contraseña nueva </mat-label>
                    <input matInput [type]="showPass[2] ? 'text' : 'password'" formControlName="confirmPassword" onpaste="return false;"/>
                    <button matTooltip="{{showPass[2] ? 'Ocultar contraseña' : 'Mostrar contraseña'}}"
                            matSuffix
                            (click)="showHidePassword(2)"
                            color="primary"
                            mat-icon-button>
                            <mat-icon>{{showPass[2] ? 'visibility_off' : 'visibility'}}</mat-icon>
                    </button>
            </mat-form-field>
            <!-- <mat-error style="font-size:13px; margin-bottom: 1px;" *ngIf="(actualizarContraForm.errors?.passMustMatch && submitted)">
                    Las contraseñas no coinciden </mat-error> -->

            <mat-progress-bar mode="determinate" [value]="valueProgressBar" [class]="colorProgressBar"></mat-progress-bar>

            <mat-list>
                    <div mat-subheader id="requisitos" class="latosemibold"><b>Requisitos</b></div>
                    <mat-list-item class="latosemibold">
                            <mat-icon mat-list-icon 
                            [style.color]="(actualizarContraForm.controls['password'].errors?.required 
                                    || actualizarContraForm.controls['password'].errors?.passMinLength
                                    || actualizarContraForm.controls['password'].errors?.maxlength) ? 'red' : 'green'">
                            {{ (actualizarContraForm.controls['password'].errors?.required 
                                    || actualizarContraForm.controls['password'].errors?.passMinLength
                                    || actualizarContraForm.controls['password'].errors?.maxlength) ? 'close' : 'done' }}</mat-icon>
                            <div mat-line>M&aacute;s de 10 caracteres</div>
                    </mat-list-item>
                    <mat-list-item class="latosemibold">
                            <mat-icon mat-list-icon [style.color]="actualizarContraForm.controls['password'].errors?.passUppercase ? 'red' : 'green'">
                            {{ actualizarContraForm.controls['password'].errors?.passUppercase ? 'close' : 'done' }}</mat-icon>
                            <div mat-line>Al menos una may&uacute;scula</div>
                    </mat-list-item>
                    <mat-list-item class="latosemibold">
                            <mat-icon mat-list-icon [style.color]="actualizarContraForm.controls['password'].errors?.passLowercase ? 'red' : 'green'">
                            {{ actualizarContraForm.controls['password'].errors?.passLowercase ? 'close' : 'done' }}</mat-icon>
                            <div mat-line>Al menos una min&uacute;scula</div>
                    </mat-list-item>        
                    <mat-list-item class="latosemibold">
                            <mat-icon mat-list-icon [style.color]="actualizarContraForm.controls['password'].errors?.passNum ? 'red' : 'green'">
                            {{ actualizarContraForm.controls['password'].errors?.passNum ? 'close' : 'done' }}</mat-icon>
                            <div mat-line>Al menos un n&uacute;mero</div>
                    </mat-list-item>
            </mat-list>
            <p class="alert alert-danger p-1 m-1 align-middle" style="height: auto;"
                *ngIf="actualizarContraForm.errors?.passMustNotMatch && submitted"> La contraseña actual y la nueva no pueden ser iguales </p>
            <p class="alert alert-danger p-1 m-1 align-middle" style="height: auto;"
                *ngIf="actualizarContraForm.errors?.passMustMatch && submitted"> Las contraseñas nuevas no coinciden </p>
            <p class="alert alert-danger p-1 m-1 align-middle" style="height: auto;"
                *ngIf="actualizarContraForm.controls['password'].errors?.passEndWhitespace && submitted"> La contraseña nueva no puede terminar con espacios en blanco </p>
            <p class="alert alert-danger p-1 m-1 align-middle" style="height: auto;"
                *ngIf="existeError"> Ocurri&oacute; un error al intentar cambiar la contraseña. Intente nuevamente. </p>
    </form>
</div>
<div mat-dialog-actions class="d-flex justify-content-center" *ngIf="!loading">
    <button mat-raised-button color="warn" mat-dialog-close (click)="cerrar(true)"> Cerrar </button>
    <button mat-raised-button type="submit" form="formAsignacion" class="boton" (click)="onSubmit()" color="primary"
            [disabled]="loadingSubmit" [class.spinnerButton]="loadingSubmit"> Confirmar
    </button>
</div>