import { Component, OnInit, ViewChild  } from '@angular/core';
import { InternacionService } from '../_services/internacion.service';
import { Subject } from 'rxjs';
import { UntypedFormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { takeUntil } from 'rxjs/operators';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { CamasService } from '../_services/camas.service';
import { AuthenticationService } from '../_services/authentication.service';


@Component({
  selector: 'app-camas',
  templateUrl: './camas.component.html',
  styleUrls: ['./camas.component.css']
})

export class CamasComponent implements OnInit {
  pabellones:any;
  dataSourceItems: any;
  displayedColumnasItems: string[] = ['habitacion', 'cama', 'estadoActual','horaCirugia', 'action'];
  camas: any[];
  filterCtrl: UntypedFormControl = new UntypedFormControl();
  camasFiltradas: any[];
  sinCamas: any;
  loading = true;
  pabellonSeleccionado: any;
  pabellonesFiltrados: any[];
  mobileDevice;
 

  protected onDestroyProperty = new Subject<void>()


  @ViewChild(MatPaginator) paginator: MatPaginator;

  constructor( 
    private camasService: CamasService,
    private internacionService: InternacionService,
    private authenticationService: AuthenticationService,
    private route:Router,
    public location: Location
    ) 
  { }

  async ngOnInit() {
    await this.cargarPabellones();
    this.cargarCamas(this.pabellonSeleccionado);
    this.listenFilter();
    this.getMobile();

  }

  listenFilter() {
    this.filterCtrl.valueChanges
      .pipe(takeUntil(this.onDestroyProperty))
      .subscribe(() => {
        this.filtroPabellones();
      });
  }

  getMobile(){
    this.mobileDevice = false;
    if( /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) ) {
      this.mobileDevice = true;
     }
  }

  async cargarCamas(pabellonSeleccionado){
    this.loading = true;
    await this.camasService.getCamasPorPabellon(pabellonSeleccionado.pabellon).toPromise().then(
      (respuesta) => {
           // console.table(respuesta.data);
           if (respuesta.ok) {
            if (respuesta.data.length !== 0) {
              this.sinCamas = false;
              this.ordenarCamas(respuesta.data);
              this.dataSourceItems = new MatTableDataSource(respuesta.data);

              /* this.dataSourceItems.paginator = this.paginator;
              if(this.paginator != null){
                this.traducirPaginador(this.paginator);
              } */
              
            } else {
              this.sinCamas = true;
            }
            this.loading = false;
          };
        },
        (error) => {
          console.log(error);
        }
      );
  }

  ordenarCamas(listaCamas){
    listaCamas = listaCamas.sort(
      (a, b) => a.pieza - b.pieza
    );
    listaCamas = listaCamas.sort(
      (a, b) => a.cama - b.cama
    );
  }

  async cargarPabellones(){
    await this.internacionService.obtenerPabellones().toPromise().then(
      (data) => {
        // console.log(data);
        if(data.ok){
          this.pabellones = data.paginador.elementos;
          this.pabellonesFiltrados = data.paginador.elementos;
          this.pabellonSeleccionado = this.pabellones[0];
         // this.cargarCamas(this.pabellonSeleccionado); 
        }
      },
      (error) => {
        console.log(error);
      }
    );
  }

 /*  traducirPaginador(paginator){
    paginator._intl.itemsPerPageLabel = 'Items por página';
    paginator._intl.nextPageLabel = 'Siguiente';
    paginator._intl.previousPageLabel = 'Anterior';
  } */

  protected filtroPabellones() {
    if (!this.pabellones) {
      return;
    }
    let search = this.filterCtrl.value;
    if (!search) {
      this.pabellonesFiltrados = this.pabellones;
      return;
    } else {
      search = search.toLowerCase();
    }
    // filtro los pabellones
    this.pabellonesFiltrados =
      this.pabellones.filter(
        (pabellon) => pabellon.denominacion.toLowerCase().indexOf(search) > -1
      );
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSourceItems.filter = filterValue.trim().toLowerCase();
    // console.table(this.dataSourceItems.filteredData);
  }

  cambiarEstadoCama(cama, idEstado){
    let usuario = this.authenticationService.currentUserValue.usuario;    

    this.camasService.setEstadoCama(cama.cama, cama.pieza, idEstado, usuario).subscribe(
      (respuesta) =>
      {
        if(respuesta.ok){
         //  console.log(respuesta);
          /*para que mientras le pegue al back actualice la vista dijo mauri*/ 
          this.cargarCamas(this.pabellonSeleccionado);
        }
      },
      (error) => {
        console.log(error);
      }

    );
  }


 actualizarPagina(){
  this.cargarCamas(this.pabellonSeleccionado);		
 }
}
