<button   mat-button  routerLink="/paciente" ><mat-icon  >keyboard_backspace</mat-icon></button>
<div  class="d-flex justify-content-center" *ngIf="loadingListado">
    <mat-spinner [diameter]="60"></mat-spinner> 
</div>
<div class="container" *ngIf="!loadingListado">
    <mat-form-field>
        <mat-label>Elija una fecha</mat-label>
        <input matInput [(ngModel)]="fechaSeleccionada" [max]="maxDate" [matDatepicker]="picker" (dateChange)="onDateChange($event)" disabled>
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker disabled="false"></mat-datepicker>
      </mat-form-field>
    <div class="d-flex justify-content-center" *ngIf="this.signosVitalesForms.length == 0">
        <p>No posee signos vitales registrados en esta fecha</p>
    </div>
  <mat-accordion>
  <mat-expansion-panel *ngFor="let signos of this.signosVitalesForms; index as i" >
      <mat-expansion-panel-header>
          <mat-panel-title>
            {{ prettyPrintFecha(signos.value.fecha)}} - Enfermero: {{ signos.value.usuario }}
          </mat-panel-title>
      </mat-expansion-panel-header>
      <alert [hidden]="!submittedSignosVitales"  style="font-size: 12px"></alert>
      <form  [formGroup]="signosVitalesForms[i]" >
          <div class="row">
              <div class="col-sm" >
                  <mat-form-field >
                      <input   matInput placeholder="FC" 
                              formControlName="frecuenciaCardiaca"
                              >
                  </mat-form-field>
              </div>
          
              <div class="col-sm" >
                  <mat-form-field >
                      <input   matInput placeholder="TA" formControlName="tensionArterial"
                 >
                  </mat-form-field>
              </div>

              <div class="col-sm" >
                  <mat-form-field >
                      <input matInput placeholder="T°" formControlName="temperatura"
                      >
                  </mat-form-field>
              </div>
          </div>
          <div class="row">
              <div class="col-sm" >
                  <mat-form-field >
                      <input matInput placeholder="FR" formControlName="frecuenciaRespiratoria">                  </mat-form-field>
              </div>
          
              <div class="col-sm" >
                  <mat-form-field >
                      <input matInput placeholder="HGT" formControlName="hemoglucotest"
                      >
                  </mat-form-field>
              </div>
              <div class="col-sm" >
                  <mat-form-field >
                      <input matInput placeholder="SPO2" formControlName="saturacion"
                      >
                  </mat-form-field>
              </div>
          </div>
          <div class="row">

            <div class="col-sm" >
                <mat-form-field>
                    <mat-label>Rass</mat-label>
                    <mat-select formControlName="rass"  >
                      <mat-option
                        *ngFor="let element of this.escalaRass"
                        [value]="element.value"
                      >
                       {{element.value}} | {{ element.descripcion  }}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
            </div>

            <div class="col-sm" >
                <mat-form-field>
                    <mat-label>Camicu</mat-label>
                    <mat-select formControlName="camicu">
                      <mat-option
                        *ngFor="let element of this.escalaCamicu"
                        [value]="element.value"
                      >
                       {{element.value}} | {{ element.descripcion  }}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
            </div>
        
            

            <div class="col-sm" >
               
            </div>
          </div>
          <div class="container text-right">
            <button *ngIf="habilitarEditar(signos)" style="width: 70px; font-size: 12px; float: right" (click)="deleteSv(signos)"  [disabled]="loadingEvolucionEnfermeria"  type="button" class="btn btn-xs  btn-outline-danger"   >Borrar</button>
          <button *ngIf="habilitarEditar(signos)" style="margin-right: 1%;width: 70px; font-size: 12px;" (click)="submitSv(signos)"  [disabled]="this.loadingSignosVitales"  type="button" class="btn btn-xs  btn-outline-info"  type="submit" >Guardar</button>
          </div>
      </form>
      
    </mat-expansion-panel>
  </mat-accordion>

</div>