import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Router } from '@angular/router';
import { AppComponent } from '../app.component';
import { SignosVitalesEvo } from '../_models/signosVitalesEvo.model';
import {
  EnfermeriaEvo,
  EnfermeriaEvoAdapter,
} from '../_models/enfermeriaEvo.model';
import { ControlDeInfeccionesEvo } from '../_models/controDeInfeccionesEvo.model';

const httpOptions = {
  headers: new HttpHeaders({}),
};

@Injectable({ providedIn: 'root' })
export class EvolucionService {
  baseUrl: string;

  private currentPatientSubject: BehaviorSubject<any>;

  public currentPatient: Observable<any>;

  constructor(
    private http: HttpClient,
    private ea: EnfermeriaEvoAdapter,
    private router: Router
  ) {
    this.baseUrl = AppComponent.baseUrl;
  }
   
  obtenerNotasPublicasPorTipo(ficha) {
    return this.http.post<any>(
      this.baseUrl + '/turnos/fichamedica/seguro/getNotasPublicasPorTipoEnFecha',
      ficha
    );
  }

  obtenerHistorialEvolucionEnfermeria(carpeta): Observable<EnfermeriaEvo[]> {
    return this.http
      .post<any>(
        this.baseUrl + '/turnos/fichamedica/seguro/getfichaevolucionenfermeriaEnFecha',
        carpeta
      )
      .pipe(
        // Adapt each item in the raw data array

        map((data) =>
          data.paginador.elementos
            .sort(
              (a, b) =>
                new Date(b.nota.fecha).getTime() as any -
                new Date(a.nota.fecha).getTime() as any
            )
            .map((item) => this.ea.adapt(item))
        )
      );
  }

  enviarSignosVitales(sv: SignosVitalesEvo) {
    return this.http.post<any>(
      this.baseUrl + '/turnos/fichamedica/seguro/guardarNota',
      sv
    );
  }

  enviarEvolucionEnfermeria(ef: EnfermeriaEvo) {
    return this.http.post<any>(
      this.baseUrl + '/turnos/fichamedica/seguro/guardarNota',
      ef
    );
  }

  enviarControlDeInfecciones(ctrl: ControlDeInfeccionesEvo){
    return this.http.post<any>(
      this.baseUrl + '/turnos/fichamedica/seguro/guardarNota',
      ctrl
    );
  }

  borrarEvolucion(ef: EnfermeriaEvo) {
    return this.http.post<any>(
      this.baseUrl + '/turnos/fichamedica/seguro/borrarelemento',
      ef
    );
  }

  borrarSignosVitales(signosVitales) {
    return this.http.post<any>(
      this.baseUrl + '/turnos/fichamedica/seguro/borrarelemento',
      signosVitales
    );
  }

  imprimirPaciente(idCarpeta) {
    const today = new Date();

    const fecha =
      today.getMonth() + 1 + '-' + today.getDate() + '-' + today.getFullYear();
    return (
      this.baseUrl +
      '/enfermeria/imprimir_hoja/pdf/' +
      idCarpeta +
      '/' +
      fecha +
      '/' +
      this.getRndInteger(1000000000000, 99999999999999)
    );
  }

  imprimirReporte(idCarpeta, fecha) {
    return (
      this.baseUrl +
      '/enfermeria/imprimir_reporte/pdf/' +
      idCarpeta +
      '/' +
      fecha +
      '/' +
      this.getRndInteger(1000000000000, 99999999999999)
    );
  }

  imprimirReportePorTurno(idCarpeta, usuario) {
    const today = new Date();
    const fecha =
      today.getMonth() + 1 + '-' + today.getDate() + '-' + today.getFullYear();
    return (
      this.baseUrl +
      '/enfermeria/imprimir_reporte_por_turno/pdf/' +
      idCarpeta +
      '/' +
      usuario +
      '/' +
      fecha +
      '/' +
      this.getRndInteger(1000000000000, 99999999999999)
    );
  }

  imprimirTodosReportesPorTurno(usuario) {
    return (
      this.baseUrl +
      '/enfermeria/imprimir_todos_reportes_por_turno/pdf/' +
      usuario +
      '/' +
      this.getRndInteger(1000000000000, 99999999999999)
    );
  }

  imprimirPabellon(pabellon) {
    const today = new Date();

    const fecha =
      today.getMonth() + 1 + '-' + today.getDate() + '-' + today.getFullYear();

    return (
      this.baseUrl +
      '/enfermeria/imprimir_pabellon/pdf/' +
      pabellon +
      '/' +
      fecha +
      '/' +
      this.getRndInteger(1000000000000, 99999999999999)
    );
  }

  getReportesEnfermeroEnFecha(fecha) {
    return this.http.post<any>(
      this.baseUrl + '/enfermeria/seguro/reportes-enfermero-fecha',
      fecha
    );
  }

  imprimirReporteSeleccionado(idReporte) {
    return (
      this.baseUrl +
      '/hcdigital/imprimir/pdf/' +
      30 +
      '/' +
      idReporte +
      '/' +
      this.getRndInteger(1000000000000, 99999999999999)
    );
  }

  getRndInteger(min, max) {
    return Math.floor(Math.random() * (max - min)) + min;
  }
}
