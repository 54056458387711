export class DatosEvolucionEnfermeria {
  public constructor(init?: Partial<DatosEvolucionEnfermeria>) {
    Object.assign(this, init);
  }

  toleranciaOral: boolean;
  tto_vo: boolean;
  avp: boolean;
  deambulacion: boolean;
  heridaQx: boolean;
  lugarHeridaQx: string;
  avc: boolean;
  peritaltismo: boolean;
  evolucion: string;
  dolor: string;
  estado: string;
  otros: string;
  upp: boolean;
  zona: string;
  grado: string;
  riesgo: string;
  sv: string;
  lavado: boolean;
  sng: string;
  k108: string;
  so: string;
  sg: string;
  oxigeno: string;
  fechaTraqueotomia;
  fechaARM;
  modoVentilatorio;
  vt;
  peep;
  pi;
  fio2;
  version;
  id;
  higieneManos: boolean;
  cabeceraElevada: boolean;
  controlPresionCuff: boolean;
  observacionPresionCuff: string;
  higieneBucal: boolean;
  aspiracionSubglotica: boolean;
  listaUPP: [];
  suspensionSedacion:boolean;
  cateterDialisis: boolean;
  dataAvc: [];
  dataCateterDialisis: [];
}
