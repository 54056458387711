<button   mat-button  routerLink="/paciente" ><mat-icon  >keyboard_backspace</mat-icon></button>
<div  class="d-flex justify-content-center" *ngIf="false">
    <mat-spinner [diameter]="60"></mat-spinner> 
</div>

<div class="container" *ngIf="true">
    <div class="d-flex justify-content-center" *ngIf="false">
        <p>No posee indicaciones medicas</p>
    </div>
    <mat-accordion>
    <mat-expansion-panel *ngFor="let indicacion of this.indicaciones; index as i" >
      <mat-expansion-panel-header>
          <mat-panel-title>
            Info de la indicacion
          </mat-panel-title>
      </mat-expansion-panel-header>
      <alert style="font-size: 12px"></alert>
        
  
    </mat-expansion-panel>
  </mat-accordion>

<div class="container" *ngIf="false">
