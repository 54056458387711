<h1 mat-dialog-title style="padding-left: 0%;padding-right: 3.5%;">Asignar cama</h1>
<div mat-dialog-content>
  <form [formGroup]="asignacionForm" id="formAsignacion">

      <div>
        Paciente: <b>{{this.data.paciente}}</b>
      </div>
      <div>
        Cirugía: <b>{{this.data.cirugia}}</b>
      </div>
      <div>
        Fecha: <b>{{this.data.fechaCirugia}}</b>
      </div>
      <div style="margin-bottom: 2%;">
        Hora: <b>{{this.data.horaCirugia}}</b>  
      </div>

      <mat-divider class="divisor"></mat-divider> 
      <span>
        <h6>
          Seleccionar cama
        </h6>
      </span>
    
      <div class="row">
          
        <div class="col-6 justify-content-center">
          <mat-form-field style="width: 100%;" appearance="outline">
            <mat-label>Pabellón</mat-label>
            <mat-select  formControlName="pabellonElegido" 
                        (selectionChange)="getCamas(this.asignacionForm.get('pabellonElegido').value)">
                <mat-option *ngFor="let pabellon of this.pabellones" [value]="pabellon">
                    {{ pabellon.denominacion | toTitleCase }}
                </mat-option>

            </mat-select>
          </mat-form-field>
        </div>
           
        <div class="col-5 justify-content-center">
              
            <mat-form-field style="width: 100%;" appearance="outline">
            <mat-label>Cama *</mat-label> 
            <mat-select  formControlName="camaNueva">
                <mat-option *ngFor="let cama of this.camas" [value]="cama">
                    {{cama.pieza}} - {{cama.cama}} - {{cama.estadoActualDescripcion}}
                </mat-option>
  
            </mat-select>
            <mat-error style="font-size:12px;" *ngIf="asignacionForm.controls['camaNueva'].invalid 
                          && submitted">{{getErrorMessage('camaNueva')}}</mat-error>
          </mat-form-field>
          
        </div>
      </div>
  </form>

</div>
<div mat-dialog-actions class="d-flex justify-content-center">
  <button mat-raised-button 
          color="warn" 
          (click)="cerrarDialogo()"> Cancelar
  </button>

  <button mat-raised-button 
          type="submit" 
          form="formAsignacion"
          color="primary" 
          (click)="onSubmit()"> Confirmar
  </button>
</div>
