import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl } from '@angular/forms';
import { UntypedFormGroup } from '@angular/forms';
import { Validators } from '@angular/forms';
import { PacienteService } from '../_services/paciente.service';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { CrearTriageComponent } from '../crear-triage/crear-triage.component';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
@Component({
  selector: 'app-sintomas-paciente',
  templateUrl: './sintomas-paciente.component.html',
  styleUrls: ['./sintomas-paciente.component.css']
})
export class SintomasPacienteComponent implements OnInit {
  @Input() signosFormHijo:UntypedFormGroup;
  sintomasForm: UntypedFormGroup;
  signosFormAux:any;
  currentPatient: any;
  prioridad;
  patientData:any;
  submitted: boolean = false;
  loading: boolean = false;
  listaCategorias:any[];
  listaSubCategorias:any[];
  habilitarAvance = false;
  time = new Date();
  esUrgencia:boolean = false;

   //filtro select subcategorias
   subcategorias: any[];
   filterCtrl: UntypedFormControl = new UntypedFormControl();
   subcatFiltradas : any[];
   protected onDestroyProperty = new Subject<void>();
  //filtro select categorias
  categorias: any[];
  filtercatCtrl: UntypedFormControl = new UntypedFormControl();
  catFiltradas : any[];


  constructor(private formBuilder: UntypedFormBuilder,
    private pacienteService: PacienteService,
    private router: Router,
    private triageComp: CrearTriageComponent
    ) { 
     
      this.currentPatient = this.pacienteService.getCurrentPatientLocal(); 
      
      this.signosFormAux = this.pacienteService.getCurrentSignosFormLocal();
      // console.log(this.signosFormAux)
    }

  ngOnInit() {
    
    this.datospacienteActual();//obtengo el paciente 
    this.getTipoCategoria(); //cargo todas las categorias
    this.listenFilter();
    this.listencatFilter();
  }

   cargarSintomas(){
    this.submitted = true;
    
    if (!this.sintomasForm.valid) {
            // console.log('no es valido')
            return;
          }
          
         
          // console.log(this.sintomasForm.value)
          this.pacienteService
            .finTriage(
             this.sintomasForm.value).subscribe(
              (data) => {
                
                  if (data) {
                    // console.log('todo ok')
                    this.submitted=false;
                    this.pacienteService.setCurrentSignosFormLocal(this.sintomasForm);
                    this.router.navigate(['/visualizacion-paciente-triage'])
                  }
                   
                  },
  
              (error) => {
                
                console.log(error);
                this.loading = false;
               
              }
            );
            
          
            

   }  

   edadPaciente(e){
    return this.pacienteService.edadPaciente(e) 
    }

  volver() {
    // this.pacienteService.removeCurrentPatient();
    this.router.navigate(['/signos-vitales']);
  }

  datospacienteActual(){
    this.pacienteService
    .datosPaciente(
      this.currentPatient.numeroDoc,
      this.currentPatient.tipoDoc
    )
    .subscribe(
      (data) => {
        this.patientData = data;
        //  console.log(this.patientData);
       }, 
       (error) => {
        console.log(error);
      }
    )}

     //categorias para el form
     async getTipoCategoria() {
      await this.pacienteService.cargarCategorias().toPromise().then(resp => {
        // console.log(resp.elementos);
        if(resp.ok){
        this.listaCategorias = resp.elementos;
        this.catFiltradas=resp.elementos;
        }
      }, error => {
        console.log(error)
      })
    }
    async getSubCategorias(categoria){
      // console.log('Id Categoria:',categoria.nombre);
      await this.pacienteService.cargarSubCategorias(categoria.id).toPromise().then((resp)=>{
        if(resp.ok){
        this.listaSubCategorias= resp.elementos;
        this.subcatFiltradas = resp.elementos;
        // console.log(this.listaSubCategorias)
        }
      }, error => {
        console.log(error)
      })
    }

      prioridadSintoma(cat){
        
       //  console.log (cat)
        if(cat.prioridad.idPrioridad == 1){
          this.prioridad = cat.prioridad.idPrioridad;
          this.esUrgencia = true;
         //  console.log("Prioridad 1 , urgencia");
        }
        else {
          this.prioridad = cat.prioridad.idPrioridad;
          this.esUrgencia = false;
         //  console.log("Prioridad " + categoria );
        }
       return this.prioridad ;
      }
      ingresoDato(change){
     
        if(change)
         this.habilitarAvance = true;
        }


         listenFilter() {
          this.filterCtrl.valueChanges
            .pipe(takeUntil(this.onDestroyProperty))
            .subscribe(() => {
              this.filtrosubCategorias();
              
            });
      
          
        }
        protected filtrosubCategorias() {
          if (!this.listaSubCategorias) {
            return;
          }
          let search = this.filterCtrl.value;
          
          if (!search) {
            this.subcatFiltradas = this.listaSubCategorias;
            return;
          } else {
            search = search.toLowerCase();
          }
          this.subcatFiltradas =
            this.listaSubCategorias.filter(
              (subcategorias) => subcategorias.nombreSubcat.toLowerCase().indexOf(search) > -1
            );
            
        }

        listencatFilter() {
          this.filtercatCtrl.valueChanges
            .pipe(takeUntil(this.onDestroyProperty))
            .subscribe(() => {
              this.filtroCategorias();
              
            });
      
          
        }
        protected filtroCategorias() {
          if (!this.listaCategorias) {
            return;
          }
          let search = this.filtercatCtrl.value;
          
          if (!search) {
            this.catFiltradas = this.listaCategorias;
            return;
          } else {
            search = search.toLowerCase();
          }
          this.catFiltradas =
            this.listaCategorias.filter(
              (categorias) => categorias.nombre.toLowerCase().indexOf(search) > -1
            );
            
        }
   
    }


