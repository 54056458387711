
<div class="container" style="margin-top:1%" *ngIf="!selected">
  
  <div class="row justify-content-md-center" style="margin-top:3%; text-align: center;">
    <div class="col-1 offset-1">  
      <mat-icon style="float: right; cursor: pointer;-webkit-text-fill-color:red; margin-left: -5%;" (click)="imprimirPabellon()">picture_as_pdf</mat-icon> 
    </div>
    <div class="col-md-4 col-lg-3">
      <mat-form-field class="searcher" >
        <input matInput [(ngModel)]="searchText" placeholder="Buscar paciente">
        <mat-icon matSuffix>search</mat-icon>
      </mat-form-field>
    </div>
    <div  class="col-xs-3 col-sm-6 col-md-4 col-lg-3" >  
      
      <mat-form-field class="searcher" >
            <mat-label *ngIf="!pabellonElegido">Seleccionar pabellon</mat-label>
            <mat-label *ngIf="pabellonElegido">{{pabellonElegido.denominacion}}</mat-label>
            <mat-select [(ngModel)]="pabellonElegido" (selectionChange)="internadosPorPabellon(pabellonElegido)">
                <mat-option *ngFor="let pabellon of pabellones" 
                            [value]="pabellon">{{pabellon.denominacion}}
                </mat-option>
            </mat-select>
      </mat-form-field>
    </div>
    <div class="col-xs-3 col-sm-6 col-md-2">
      <button mat-stroked-button color="primary" (click)="imprimirTodosReportesPorTurno()">Generar reportes</button>
    </div>
  </div>  

  <div class="row d-flex justify-content-center" style="text-align: center;">
    <div class="col-xs-3 col-sm-6 col-md-4">
      <button mat-stroked-button color="primary" (click)=estudiosPorPabellon() matTooltip="Estudios Pendientes" >
        Ver todos los estudios pendientes del pabellón
      </button>
    </div>
  </div>

  <div class="d-flex justify-content-center" style="margin-top:10%"  *ngIf="loading">
    <mat-spinner [diameter]="60"></mat-spinner>   
  </div>
  <alert style="font-size: 12px"></alert>
  <div class="container ">
  <div class="row d-flex justify-content-center">
  <div class="col-xs-12 col-sm-12 col-md-9 col-lg-7 col-xl-7 " *ngIf="!loading">
    <div style="text-align: center; vertical-align: middle; margin-top: 15%;" *ngIf="internados && internados.length==0">
      <h6>No hay internados en este pabellon</h6>
    </div>
    <mat-list *ngIf="internados && internados.length>0">
        <mat-list-item *ngFor="let internado of internados | filter : searchText">
          <span matListAvatar>
            <button matTooltip="Trasladar"
                    (click)="cambiarCama(internado)"
                    color="primary"
                    mat-icon-button>
              <mat-icon>swap_horiz</mat-icon>
            </button>
          </span>
        
          <span matListAvatar>
            <div><mat-icon >person</mat-icon></div>
            <div style="font-size: 11px;">{{internado.pieza}} - {{internado.cama}}</div>
          </span>
        
          <h5 style="padding-top: 20px;" matLine>{{ internado.paciente | toTitleCase }}</h5>
          <span *ngIf="internado.etiquetas && internado.etiquetas.length > 0">
            <img   [src]="etiquetaInternado(internado)">
          </span>

          <p matLine >
            <span style="padding:0; "  mat-subheader >{{internado.diagnostico | toTitleCase}}</span>
          </p>
          <div style = "min-width: 30% !important; max-height: 100% !important;">
          <mat-action-list style = "max-height: 50% !important;margin-top: -15%!important;" >
            <button *ngIf="internado.seguido" (click)=sacarMisPacientes(internado) style="margin-right:6%">
              <mat-icon class="yellow-icon">star</mat-icon>
            </button>
            <button id="boton-estrella" *ngIf="!internado.seguido" (click)=agregarMisPacientes(internado) style="margin-right:6%">
              <mat-icon >star_border</mat-icon>
            </button>
            <button style="width:35px; height:35px;" mat-mini-fab color="primary" (click)=seleccionarPaciente(internado)>
              <mat-icon [inline]=true>edit</mat-icon>
            </button>
            <!--<button (click)=selecPacPendientes(internado) [disabled]="internado.cantidadSolicitudes == 0" matTooltip="Solicitudes Pendientes">
              <img src="../../assets/images/logos/icono_rp.png" /> ({{internado.cantidadSolicitudes}})
            </button>-->
          </mat-action-list>
          <!-- <p *ngIf = "internado.fechaUltIndicacion" style = "margin: 0 0 -10% 15%;">
            <span *ngIf = "!indicacionReciente(internado.fechaUltIndicacion) else elseRojo" style="padding:0px;"  mat-subheader>
              {{internado.fechaUltIndicacion }}
            </span>
            <ng-template #elseRojo>
              <span style="padding:0px; color: red"  mat-subheader>
                {{internado.fechaUltIndicacion }}
              </span>
            </ng-template>
          </p> -->
        </div>
        </mat-list-item>
      </mat-list>
  </div>
</div>
 </div>
</div>

<div class="container-fluid" *ngIf="selected">
    <button   mat-button  (click)="salirPdf()" ><mat-icon  >keyboard_backspace</mat-icon></button>

    <div class="row">
        
    </div>
    <br>
    <div class="container">             
        <embed type="application/pdf" [src]="selectedUrl | safeUrl" width="103.5%;"  height="500px;"  >
    </div>
</div>