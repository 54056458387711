
<div *ngIf=currentUser >
  <mat-toolbar >
    <span>
      
      <button mat-icon-button (click)="toggleNavBar()">
        <mat-icon>menu</mat-icon>
        
      </button>
      <img class="logo-enfermeria"  src="../../assets/images/logos/isologo_español.png">
      
  </span>
        <span>enfermería</span>
        <span class="example-spacer"></span>
        <span style="font-size: 15px; font-weight: 400; ">{{this.currentUser.nombreCompleto}}</span>
        <mat-icon *ngIf="!currentPatient && false">search</mat-icon>
        <button class="menu" mat-button [matMenuTriggerFor]="belowMenu" (click)="closeNavBar()">
      <mat-icon>more_vert</mat-icon></button>
        <mat-menu #belowMenu="matMenu" yPosition="below">
          <button mat-menu-item (click)="cambiarContrasena()">Actualizar Contraseña</button>
          <button mat-menu-item (click)=logout()>Cerrar sesión</button>
        </mat-menu>
    </mat-toolbar>
    <!--
  <nav *ngIf="!currentPatient" mat-tab-nav-bar mat-align-tabs="center">
    <div mat-tab-link
    *ngFor="let link of navLinks"
    [routerLink]="link.link"
    routerLinkActive #rla="routerLinkActive"
    [active]="rla.isActive">{{link.label}}
    
    </div>
  </nav>
  -->
</div>