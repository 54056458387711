import { Evolucion } from "./Evolucion.model";
import { DatosEvolucionEnfermeria } from "./datosEvolucionEnfermeria.model";
import { Injectable } from "@angular/core";
import { Adapter } from "../_adapters/adapter";
import { CharactersService } from "../_services/characters.service";

export class EnfermeriaEvo extends Evolucion {
  constructor(
    init?: Partial<DatosEvolucionEnfermeria>,
    id?: number,
    version?: number,
    usuario?: string
  ) {
    super();

    this.id = id;
    this.version = version;
    this.tipo = { id: 8 };
    this.fechaTurnoSt = this.obtenerFecha();
    this.privada = false;
    this.nombreMedico = usuario;
    this.evolucionEnfermeria = new DatosEvolucionEnfermeria(init);
  }

  fecha;
  evolucionEnfermeria: DatosEvolucionEnfermeria;

  private obtenerFecha() {
    const fecha = new Date(); ////// corregir minutos y hora
    return fecha.getDay() + "/" + (fecha.getMonth()+ 1) + "/" + fecha.getFullYear() + " " + ("00" + fecha.getHours()).slice(-2) + ":" + ("00" + fecha.getMinutes()).slice(-2);
  }

}

@Injectable({
  providedIn: "root",
})
export class EnfermeriaEvoAdapter implements Adapter<EnfermeriaEvo> {
  adapt(item: any): EnfermeriaEvo {
    const ee = new EnfermeriaEvo();
    ee.id = item.nota.id;
    ee.version = item.nota.version;
    ee.carpeta = item.nota.carpeta;
    ee.fecha = item.nota.fecha;
    ee.nombreMedico = item.nota.usuario;

    ee.evolucionEnfermeria.id = item.id;
    ee.evolucionEnfermeria.version = item.version;
    ee.evolucionEnfermeria.avp = item.avp;
    ee.evolucionEnfermeria.deambulacion = item.deambulacion;
    ee.evolucionEnfermeria.dolor = item.dolor;
    ee.evolucionEnfermeria.estado = item.estado;
    ee.evolucionEnfermeria.evolucion = item.evolucion;
    ee.evolucionEnfermeria.grado = item.grado;
    ee.evolucionEnfermeria.heridaQx = item.heridaQx;
    ee.evolucionEnfermeria.k108 = item.k108;
    ee.evolucionEnfermeria.lavado = item.lavado;
    ee.evolucionEnfermeria.otros = item.otros;
    ee.evolucionEnfermeria.peritaltismo = item.peritaltismo;
    ee.evolucionEnfermeria.riesgo = item.riesgo;
    ee.evolucionEnfermeria.sng = item.sng;
    ee.evolucionEnfermeria.sv = item.sv;
    ee.evolucionEnfermeria.so = item.so;
    ee.evolucionEnfermeria.sg = item.sg;
    ee.evolucionEnfermeria.toleranciaOral = item.toleranciaOral;
    ee.evolucionEnfermeria.tto_vo = item.tto_vo;
    ee.evolucionEnfermeria.zona = item.zona;
    ee.evolucionEnfermeria.lugarHeridaQx = item.lugarHeridaQx;
    ee.evolucionEnfermeria.oxigeno = item.oxigeno;
    ee.evolucionEnfermeria.fechaTraqueotomia = item.fechaTraqueotomia;
    ee.evolucionEnfermeria.fechaARM = item.fechaARM;
    ee.evolucionEnfermeria.modoVentilatorio = item.modoVentilatorio;
    ee.evolucionEnfermeria.vt = item.vt;
    ee.evolucionEnfermeria.peep = item.peep;
    ee.evolucionEnfermeria.pi = item.pi;
    ee.evolucionEnfermeria.fio2 = item.fio2;
    ee.evolucionEnfermeria.higieneBucal = item.higieneBucal;
    ee.evolucionEnfermeria.cabeceraElevada = item.cabeceraElevada;
    ee.evolucionEnfermeria.controlPresionCuff = item.controlPresionCuff;
    ee.evolucionEnfermeria.observacionPresionCuff = item.observacionPresionCuff;
    ee.evolucionEnfermeria.higieneManos = item.higieneManos;
    ee.evolucionEnfermeria.aspiracionSubglotica = item.aspiracionSubglotica;
    ee.evolucionEnfermeria.suspensionSedacion = item.suspensionSedacion;
    ee.evolucionEnfermeria.upp = item.upp;
    ee.evolucionEnfermeria.listaUPP = item.listaUPP;
    ee.evolucionEnfermeria.avc = item.avc;
    ee.evolucionEnfermeria.cateterDialisis = item.cateterDialisis;
    ee.evolucionEnfermeria.dataAvc = item.dataAvc;
    ee.evolucionEnfermeria.dataCateterDialisis = item.dataCateterDialisis;
    return ee;
  }

  /* serialize(ee: EnfermeriaEvo): any {
    return {id: user.id,
            usuario: user.username,
            nombre: user.fullName,
            email: user.email,
            nroDocumento: user.documento,
            str_fechaNacimiento: user.str_fechaNacimiento}
  }*/
}
