<button mat-icon-button style="float: right;top:-24px;right:-24px;" [mat-dialog-close]="false">
    <mat-icon class="close-icon" color="warn">close</mat-icon>
</button>
<h1 mat-dialog-title>Crear paciente</h1>
<div mat-dialog-content>
    <form [formGroup]="registerForm">
        <div class="row">
            <div class="col-sm-6">
                <mat-form-field>
                    <input matInput type="text" formControlName="nombre"
                      placeholder="Nombre" required>
                        <mat-error *ngIf="registerForm.controls['nombre'].invalid 
                        && submitted">{{getErrorMessage('nombre')}}</mat-error>
                </mat-form-field>
            </div>
            
            <div class="col">
                <mat-form-field>
                    <input matInput type="text" formControlName="apellido"
                        placeholder="Apellido " required>
                        <mat-error *ngIf="registerForm.controls['apellido'].invalid 
                        && submitted">{{getErrorMessage('apellido')}}</mat-error>
                </mat-form-field>
                
            </div>

        </div>
            <div class="row">
                <!-- <div class="col-sm-6">
                   <mat-form-field>
                       <input matInput type="text"  formControlName="dni"
                           placeholder="DNI *">
                           <mat-error *ngIf="registerForm.controls['dni'].invalid 
                           && submitted">{{getErrorMessage('dni')}}</mat-error>
                   </mat-form-field>
                   </div> -->
                
                <div class="col">
                 <mat-form-field>
                    <mat-label>Sexo</mat-label>
                    <mat-select formControlName="sexo" required>
                        <mat-option *ngFor="let sexo of genders" [value]="sexo.value">
                            {{sexo.descripcion}}
                        </mat-option>

                    </mat-select>
                    <mat-error *ngIf="registerForm.controls['sexo'].invalid 
                    && submitted">{{getErrorMessage('sexo')}}</mat-error>
                    
                 </mat-form-field>
                 </div>
             </div>
            <div class="row">
                <div class="col">
                   <mat-form-field>
                       <input matInput type="text"  formControlName="domicilio"
                            placeholder="Domicilio">
                            <mat-error *ngIf="registerForm.controls['domicilio'].invalid 
                            && submitted">{{getErrorMessage('domicilio')}}</mat-error>
                   </mat-form-field>
                </div>
                 
                <div class="col-sm-6">
                <mat-form-field>
                    <mat-label>Fecha nacimiento </mat-label>
                    <input matInput formControlName="fechaNacimiento" [max]="maxDate" [matDatepicker]="picker" required>
                    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                    <mat-datepicker #picker></mat-datepicker>
                    <mat-error *ngIf="registerForm.controls['fechaNacimiento'].invalid 
                        && submitted">{{getErrorMessage('fechaNacimiento')}}</mat-error>
                 </mat-form-field>
                 </div>
            </div>
        
        <div class="row">
            <div class="col">
                <mat-form-field>
                    <input matInput type="email" formControlName="email"
                         placeholder="Email">
                     <mat-error *ngIf="registerForm.controls['email'].invalid  && submitted
                        ">{{getErrorMessage('email')}}</mat-error>
                </mat-form-field>
            </div>
            <div class="col">
                <mat-form-field>
                    <input matInput type="text" formControlName="telefono"
                        placeholder="Teléfono">
                        <mat-error *ngIf="registerForm.controls['telefono'].invalid  && submitted
                        ">{{getErrorMessage('telefono')}}</mat-error>
                 </mat-form-field>
             </div>
         </div>
        <div class="row">
            <div class="col">
                <mat-form-field>
                    <input matInput type="text" formControlName="localidad"  
                         placeholder="Localidad *">
                        <mat-error *ngIf="registerForm.controls['localidad'].invalid  && submitted
                       ">{{getErrorMessage('localidad')}}</mat-error>
                </mat-form-field>
             </div>

              <div class="col">
                <mat-form-field>
                    <input matInput type="text" formControlName="codPostal"  
                        [ngClass]="{ 'is-invalid': submitted && f.localidad.errors }" placeholder="Código postal" >
                        <div *ngIf="submitted && f.localidad.errors" class="invalid-feedback">
                         </div>
                    </mat-form-field>
                </div>
        </div>
        
        <div class="d-flex justify-content-md-center">
            <button type="submit"  class="btn btn-success" [disabled]="!this.registerForm.valid" style="border-radius: 20px;"   (click)="guardarPaciente()" >Guardar
            </button>
            <mat-spinner [diameter]="30" *ngIf="loading"></mat-spinner>
        </div>
        <alert style="font-size: 12px"></alert>
    </form>
</div>