import { Component, OnInit, Input } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { AuthenticationService } from '../_services/authentication.service';
import { User } from '../_models/user.model';
import { EmbedUrlsService } from '../_services/embedUrls.service';
import { isNgTemplate } from '@angular/compiler';
//import { isEmbeddedView } from '@angular/core/src/view/util'; // comentado al pasar a mat8

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.css']
})
export class MenuComponent implements OnInit {

  @Input() inputSideNav: MatSidenav;
  panelOpenState = false;
  currentUser: User;
  funciones: any[];
  usuarioLog: any;
  respuesta: boolean;
  roles;
  perfiles; 
  grouped: any[]

  constructor(
    private urlsService: EmbedUrlsService,
    private authenticationService: AuthenticationService
  ) { }

  ngOnInit() {
    this.obtenerPermisosMenu();
    
  }

  obtenerPermisosMenu() {
    this.authenticationService.currentUser.subscribe(
      (resp) => {
        this.funciones = [];
        if (resp) {
          this.usuarioLog = resp;
          this.roles = this.usuarioLog.roles;
          this.roles.forEach((rol) => {
            this.perfiles = rol.perfiles;
            this.perfiles.forEach((perfil) => {
              this.funciones.push.apply(this.funciones, perfil.funciones);
            });
          });
          this.funciones = this.funciones.filter(
            (funcion, index, self) =>
              index ===
              self.findIndex(
                (f) =>
                  f.url === funcion.url &&
                  f.nombreMenu == funcion.nombreMenu &&
                  f.nombreSubMenu == funcion.nombreSubMenu
              )
          );
          this.urlsService.setUrls(this.funciones);

          this.grouped = this.groupBy(this.funciones, function (item) {
           
           
            return [item.nombreMenu];
          });
          
        }
      },
      (error) => {
        console.log(error);
      }
    );
  }

  groupBy(list, fn) {
    var groups = {};
    for (var i = 0; i < list.length; i++) {
      var group = JSON.stringify(fn(list[i]));
      if (group in groups) {
        groups[group].push(list[i]);
      } else {
        groups[group] = [list[i]];
      }
    }
    return this.arrayFromObject(groups);
  }
  
 chequeoSubmenu(item){
  var check = false;
  //console.log(item);
  if(item.nombreMenu == item.nombreSubMenu){
    check = true;
  }
  return check;

 }
 

  arrayFromObject(obj) {
    var arr = [];
    for (var i in obj) {
      arr.push(obj[i]);
    }
    return arr;
  }
}
export class ExpansionOverviewExample {
  panelOpenState = false;
}
