<div class="dialog">
  <h2 mat-dialog-title>{{ titulo}} {{ nombre }}</h2>
  <form [formGroup]="validarForm">
    <mat-form-field style="align-self: center; width: 50%;">
      <mat-label>Nombre</mat-label>
      <input matInput placeholder="Nuevo nombre" formControlName="nombre" maxlength="255" required>
    </mat-form-field>


    <mat-dialog-actions align="end">
        <button mat-raised-button color="warn"[mat-dialog-close]="true" (click)="cancelar()" style="margin-top: 3%;">Cancelar</button>
        <button mat-raised-button color="primary" [mat-dialog-close]="true" (click)="submit()" style="margin-top: 3%;" cdkFocusInitial>{{ accion }}</button>
    </mat-dialog-actions>
  </form>
</div>