<div class="container-fluid" style="margin-top:1%">
  
  <mat-card>
    <div class="container-fluid">

      <div class="row">
        <div class="col-md">
          <h3 *ngIf="hayPabellon">Estudios pendientes en el pabellón: {{ pabellonSeleccionado.denominacion }}</h3>
        </div>
        <div>
          <button mat-raised-button color="warn" class="float-right" (click)="back()">Volver</button>
        </div>
      </div>

      <mat-card-content>
        <div class="container" style="margin-top:1%;">
          
          <div class="row d-flex justify-content-center" style="margin-top: 10%;" *ngIf="loading">
            <mat-spinner [diameter]="60"></mat-spinner>
          </div>
          <alert style="font-size: 12px;"></alert>

          <div class="container">

            <div class="row d-flex justify-content-center">

              <div *ngIf="!loading">
                <div style="text-align: center; vertical-align: middle; margin-top: 15%;" *ngIf="!hayPedidos">
                  <h6>No se encontraron estudios pendientes de internados de este pabellón</h6>
                </div>

                <div class="contenedor-tabla">
                  <table
                        mat-table
                        [dataSource]="dataSourceItems"
                        *ngIf="hayPedidos">

                    <!-- Columna paciente -->
                    <div style="text-align: center; margin-right: 1px">
                      <ng-container matColumnDef="paciente">
                        <th mat-header-cell *matHeaderCellDef>Paciente</th>
                        <td mat-cell *matCellDef="let pedido">{{ pedido.paciente }}<td>
                      </ng-container>
                    </div>

                    <!-- Columna ubicación -->
                    <div style="text-align: center; margin-right: 1px">
                      <ng-container matColumnDef="ubicacion">
                        <th mat-header-cell *matHeaderCellDef>Ubicación (pieza - cama)</th>
                        <td mat-cell *matCellDef="let pedido">{{ pedido.pieza }} - {{ pedido.cama }}</td>
                      </ng-container>
                    </div>

                    <!-- Columna estudios -->
                    <div style="text-align: center; margin-right: 1px">
                      <ng-container matColumnDef="estudios">
                        <th mat-header-cell *matHeaderCellDef>Estudios</th>
                        <td mat-cell *matCellDef="let pedido">
                          <p *ngFor="let estudio of pedido.estudiosPaciente" id="parrafoEstCir">{{estudio.nombreEstudio}}
                            <a href="{{ estudio.linkPreparacion }}" target="_blank" [hidden]="!estudio.linkPreparacion">
                                <mat-icon style="font-size: 22px; cursor: pointer; color: #0a3d6e" matTooltip="Ver Preparacion">picture_as_pdf</mat-icon>
                            </a>
                          </p>
                        </td>
                      </ng-container>
                    </div>

                    <!-- Columna Servicio -->
                    <div style="text-align: center; margin-right: 1px">
                      <ng-container matColumnDef="servicio">
                        <th mat-header-cell *matHeaderCellDef>Servicio</th>
                        <td mat-cell *matCellDef="let pedido">{{ pedido.nombreServicio }}</td>
                      </ng-container>
                    </div>

                    <!-- Columna Estado -->
                    <div style="text-align: center; margin-right: 1px">
                      <ng-container matColumnDef="estado">
                        <th mat-header-cell *matHeaderCellDef>Estado</th>
                        <td mat-cell *matCellDef="let pedido">
                          <p id="parrafoEstCir" *ngIf="pedido.estado == 'SOL'">Solicitado</p>
                          <p id="parrafoEstCir" *ngIf="pedido.estado == 'PRO'">Programado</p>
                        </td>
                      </ng-container>
                    </div>

                    <!-- Columna Fecha-Hora -->
                    <div style="text-align: center; margin-right: 1px">
                      <ng-container matColumnDef="fechaHora">
                        <th mat-header-cell *matHeaderCellDef>Fecha y Hora</th>
                        <td mat-cell *matCellDef="let pedido">{{ pedido.fechaHoraProg }}</td>
                      </ng-container>
                    </div>

                    <!-- Columna Profesional Efector -->
                    <div style="text-align: center; margin-right: 1px">
                      <ng-container matColumnDef="actuante">
                        <th mat-header-cell *matHeaderCellDef>Profesional Efector</th>
                        <td mat-cell *matCellDef="let pedido">{{ pedido.actuante | toTitleCase }}</td>
                      </ng-container>
                    </div>

                    <tr mat-header-row *matHeaderRowDef="displayedColumnasItems; sticky: true"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumnasItems"></tr>

                  </table>
                </div>

              </div>
            </div>
          </div>
        </div>
      </mat-card-content>
    </div>
  </mat-card>
</div>