<button mat-button routerLink="/paciente">
  <mat-icon>keyboard_backspace</mat-icon>
</button>
<div class="d-flex justify-content-center" *ngIf="loadingListado">
    <mat-spinner [diameter]="60"></mat-spinner>
</div>

<div class="container" *ngIf="!loadingListado">
    <mat-form-field>
        <mat-label>Elija una fecha</mat-label>
        <input matInput [(ngModel)]="fechaSeleccionada" [max]="maxDate" [matDatepicker]="picker" (dateChange)="onDateChange($event)" disabled>
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker disabled="false"></mat-datepicker>
    </mat-form-field>
    <div class="d-flex justify-content-center" *ngIf="this.evolucionesForms.length == 0">
        <p>No posee evoluciones registradas en esta fecha</p>
    </div>
    <mat-accordion>
        <mat-expansion-panel *ngFor="let form of this.evolucionesForms; index as i">
            <mat-expansion-panel-header>
                <mat-panel-title>
                    {{ prettyPrintFecha(form.value.fecha)}} - Enfermero: {{ form.value.usuario }}
                </mat-panel-title>
            </mat-expansion-panel-header>
            <form [formGroup]="form">
                <div class="row">
                    <div class="col-sm checkbox-evolucion">
                        <mat-checkbox formControlName="toleranciaOral" labelPosition="after" style="font-size: 12px">Tolerancia oral</mat-checkbox>
                    </div>
                    <div class="col-sm checkbox-evolucion">
                        <div>
                            <mat-checkbox formControlName="tto_vo" labelPosition="after" style="font-size: 12px">TTO vo</mat-checkbox>
                        </div>
                    </div>

                    <div class="col-sm checkbox-evolucion">
                        <mat-checkbox formControlName="avp" labelPosition="after" style="font-size: 12px">AVP</mat-checkbox>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm checkbox-evolucion">
                        <mat-checkbox formControlName="deambulacion" labelPosition="after" style="font-size: 12px">Deambulación</mat-checkbox>
                    </div>
                    <div class="col-sm checkbox-evolucion">
                        <mat-checkbox formControlName="peritaltismo" labelPosition="after" style="font-size: 12px">Peritaltismo</mat-checkbox>
                    </div>

                    <div class="col-sm">
                        <mat-checkbox formControlName="avc" style="font-size: 12px; margin-right: 5%" (change)="changeAvc(i)">AVC</mat-checkbox>
                        <mat-form-field>
                          <mat-label>Ingrese la ubicación de las vías para AVC</mat-label>
                          <mat-select formControlName="formAvc" [disabled]="!form.value.avc" multiple> <!--falta formControlName=***-->
                            <mat-option *ngFor="let dato of this.listaViasUbicacion" [value]="dato">
                              {{ dato }}
                            </mat-option>
                          </mat-select>
                        </mat-form-field>
                      </div>

                      <div class="col-sm">
                        <mat-checkbox formControlName="cateterDialisis" style="font-size: 12px; margin-right: 5%" (change)="changeAvc(i)">Catéter para diálisis</mat-checkbox>
                        <mat-form-field>
                          <mat-label>Ingrese la ubicación de las vías de catéter para diálisis</mat-label>
                          <mat-select formControlName="formCateterDialisis" [disabled]="!form.value.cateterDialisis" multiple> <!--falta formControlName=***-->
                            <mat-option *ngFor="let dato of this.listaViasUbicacion" [value]="dato">
                              {{ dato }}
                            </mat-option>
                          </mat-select>
                        </mat-form-field>
                      </div>

                    
                </div>
                <div class="row">
                    <div class="col-sm checkbox-evolucion">
                        <mat-checkbox formControlName="heridaQx" labelPosition="after" style="font-size: 12px; margin-right: 5%">Herida QX</mat-checkbox>
                        <mat-form-field>
                            <input matInput formControlName="lugarHeridaQx" placeholder="Ubicacion" />
                        </mat-form-field>
                    </div>
                    <div class="col-sm"></div>
                    <div class="col"></div>
                </div>
                <mat-divider></mat-divider>
                <br />
                <div class="container-fluid">
                    <div class="row">
                        <div class="col">
                            <mat-form-field>
                                <mat-label>Oxigeno</mat-label>
                                <mat-select formControlName="oxigeno">
                                    <mat-option *ngFor="let o of this.opcionesOxigeno" [value]="o">
                                        {{ o | toTitleCase }}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                        <div class="col">
                            <mat-form-field>
                                <mat-label>Fecha de traqueotomia</mat-label>
                                <input matInput formControlName="fechaTraqueotomia" [max]="maxDate" [matDatepicker]="picker" />
                                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                                <mat-datepicker #picker disabled="false"></mat-datepicker>
                            </mat-form-field>
                        </div>
                        <div class="col">
                            <mat-form-field>
                                <mat-label>Fecha ARM</mat-label>
                                <input autocomplete="off" matInput formControlName="fechaARM" [max]="maxDate" [matDatepicker]="pickerARM" />
                                <mat-datepicker-toggle matSuffix [for]="pickerARM"></mat-datepicker-toggle>
                                <mat-datepicker #pickerARM></mat-datepicker>
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-auto">
                            <mat-form-field>
                                <input matInput formControlName="vt" placeholder="VT" />
                            </mat-form-field>
                        </div>
                        <div class="col-auto">
                            <mat-form-field>
                                <input matInput formControlName="peep" placeholder="PEEP" />
                            </mat-form-field>
                        </div>
                        <div class="col-auto">
                            <mat-form-field>
                                <input matInput formControlName="pi" placeholder="PI" />
                            </mat-form-field>
                        </div>
                        <div class="col-auto">
                            <mat-form-field>
                                <input matInput formControlName="fio2" placeholder="FIO2" />
                            </mat-form-field>
                        </div>
                    </div>
                </div>
                <mat-divider></mat-divider>
                <br />
                <mat-form-field style="width: 100%">
                    <textarea class="texto-evolucion" matInput type="text" placeholder="Ingrese una evolución" formControlName="evolucion"></textarea>
                    <button mat-button *ngIf="form.controls['evolucion']" matSuffix mat-icon-button aria-label="Clear" (click)="form.controls['evolucion'].reset()">
            <mat-icon>close</mat-icon>
          </button>
                </mat-form-field>
                <mat-divider></mat-divider>
                <br />
                <mat-accordion>
                    <mat-expansion-panel>
                        <mat-expansion-panel-header>
                            <mat-panel-title> Estado </mat-panel-title>
                        </mat-expansion-panel-header>
                        <label>Dolor: </label>
                        <div class="row">
                            <mat-radio-group style="width: 100%" formControlName="dolor">
                                <mat-radio-button labelPosition="after" style="font-size: 12px; margin-left: 2%" value="leve">Leve</mat-radio-button>
                                <mat-radio-button labelPosition="after" style="font-size: 12px; margin-left: 2%" value="moderado">Moderado</mat-radio-button>
                                <mat-radio-button labelPosition="after" style="font-size: 12px; margin-left: 2%" value="severo">Severo</mat-radio-button>
                                <button mat-button matSuffix mat-icon-button aria-label="Clear" (click)="form.controls['dolor'].reset()">
                  <mat-icon style="line-height: 16px">close</mat-icon>
                </button>
                            </mat-radio-group>
                        </div>
                        <mat-divider></mat-divider>
                        <br />
                        <label>Estado:</label>
                        <br />
                        <div class="row">
                            <mat-radio-group style="width: 100%" formControlName="estado">
                                <mat-radio-button labelPosition="after" style="font-size: 12px; margin-left: 2%" value="vigil">Vigil</mat-radio-button>
                                <mat-radio-button labelPosition="after" style="font-size: 12px; margin-left: 2%" value="somnoliento">Somnoliento</mat-radio-button>
                                <button mat-button matSuffix mat-icon-button aria-label="Clear" (click)="form.controls['estado'].reset()">
                  <mat-icon style="line-height: 16px">close</mat-icon>
                </button>
                            </mat-radio-group>
                        </div>
                        <div class="container-fluid">
                            <div class="row">
                                <mat-form-field>
                                    <input matInput formControlName="otros" placeholder="Otros" />
                                </mat-form-field>
                            </div>
                        </div>
                    </mat-expansion-panel>
                </mat-accordion>
                <mat-divider></mat-divider>
                <br />
                <mat-accordion>
                    <mat-expansion-panel>
                        <mat-expansion-panel-header>
                            <mat-panel-title> UPP </mat-panel-title>
                        </mat-expansion-panel-header>

                        <div class="row">
                            <div class="col">
                                <mat-slide-toggle formControlName="upp" color="primary" (change)="changed(i)">
                                    {{form.controls.upp.value ? 'Si' : 'No'}}
                                </mat-slide-toggle>
                            </div>
                        </div>
                        <table
                            mat-table
                            [dataSource]="form.value.listaUPPmostrar"    
                            *ngIf="form.value.listaUPPmostrar.length>0"
                            style="width: 100%;">
                            <div style="text-align: center; ">
                                <ng-container matColumnDef="zona">
                                <th mat-header-cell *matHeaderCellDef>Zona</th>
                                <td mat-cell *matCellDef="let upp">{{ upp.zona }}<td>
                                </ng-container>
                            </div>
                            <div style="text-align: center; ">
                                <ng-container matColumnDef="grado">
                                <th mat-header-cell *matHeaderCellDef>Grado</th>
                                <td mat-cell *matCellDef="let upp">{{ upp.grado }}<td>
                                </ng-container>
                            </div>
                            <div style="text-align: center; ">
                                <ng-container matColumnDef="riesgo">
                                <th mat-header-cell *matHeaderCellDef>Riesgo</th>
                                <td mat-cell *matCellDef="let upp">{{ upp.riesgo }}<td>
                                </ng-container>
                            </div>
                            <div style="text-align: center; ">
                                <ng-container matColumnDef="accion">
                                <th mat-header-cell *matHeaderCellDef>Acción</th>
                                <td mat-cell *matCellDef="let upp"><button mat-button *ngIf="habilitarEditar(form.value)" (click)="eliminarUpp(upp, i)"><mat-icon style="color: red;">close</mat-icon></button><td>
                                </ng-container>
                            </div>

                            <tr mat-header-row *matHeaderRowDef="columnasUpp; sticky: true"></tr>
                            <tr mat-row *matRowDef="let row; columns: columnasUpp"></tr>
                        </table>
                        <div class="row">
                            <div class="col-12 col-sm-6 col-md-6 col-xl-3">
                                <mat-form-field>
                                    
                                 <input matInput formControlName="zona" placeholder="Zona:" />
                                    
                                 <!-- <mat-select matInput formControlName="zona"  placeholder="Zona"  >                    
                                    <mat-option *ngFor = 'let z of listaZonas' [value]="z.value" >
                                          {{z.value}}
                                    </mat-option>
                                </mat-select> -->
                                </mat-form-field>
                              <br>
                              
                              <!-- <mat-form-field [hidden]="!this.otroValor && this.zonaAux != 'Otros'">
                              <input matInput  placeholder="Ingrese otra zona" [(ngModel)]="otraZona" [ngModelOptions]="{standalone: true}" (change)="agregarZona(otraZona)"  >
                               <button mat-button mat-icon-button matSuffix (click)="agregarZona(otraZona)" type="submit" id="addZona" matTooltip="Agregar zona"> <mat-icon>add</mat-icon></button>  
                              </mat-form-field> -->
                            </div>
                          
                            <div class="col-12  col-sm-6 col-md-6 col-xl-3">
                                <mat-form-field>
                                    <mat-label>Grado</mat-label>
                                    <mat-select matInput formControlName="grado" >
                                    <mat-option *ngFor="let grado of this.gradovalores" [value]="grado">
                                        {{ grado  }}
                                    </mat-option>
                                    </mat-select>
                                  </mat-form-field>
                            </div>
                        
                            <div class="col-12  col-sm-6 col-md-6 col-xl-3">
                                <mat-form-field>
                                    <input matInput formControlName="riesgo" placeholder="Riesgo" />
                                </mat-form-field>
                            </div>
                            <div class="col-8 col-sm-2 col-md-2 col-xl-3">
                                <button *ngIf="habilitarEditar(form.value) && form.value.upp" [disabled]="! form.value.zona || ! form.value.grado" style="width: 100px; font-size: 12px; float: right" (click)="guardarUpp(i)" type="button" class="btn btn-xs btn-outline-info">
                                    Agregar UPP
                                </button>
                            </div>
                        </div>
                    </mat-expansion-panel>
                </mat-accordion>
                <mat-divider></mat-divider>
                <br />
                <mat-accordion>
                    <mat-expansion-panel>
                        <mat-expansion-panel-header>
                            <mat-panel-title> Sonda </mat-panel-title>
                        </mat-expansion-panel-header>

                        <div class="row">
                            <div class="col-8">
                                <mat-form-field>
                                    <input matInput formControlName="sv" placeholder="SV" />
                                </mat-form-field>
                            </div>
                            <div class="col-sm">
                                <mat-checkbox formControlName="lavado" style="margin-top: 17%" labelPosition="before" style="font-size: 12px">Lavado</mat-checkbox>
                            </div>
                        </div>
                        <div class="container-fluid">
                            <div class="row">
                                <mat-form-field>
                                    <input matInput formControlName="sng" placeholder="SNG" />
                                </mat-form-field>
                            </div>

                            <div class="row">
                                <mat-form-field>
                                    <input matInput formControlName="k108" placeholder="K108" />
                                </mat-form-field>
                            </div>
                            <div class="row">
                                <mat-form-field>
                                    <input matInput formControlName="so" placeholder="SO" />
                                </mat-form-field>
                            </div>
                            <div class="row">
                                <mat-form-field>
                                    <input matInput formControlName="sg" placeholder="SG" />
                                </mat-form-field>
                            </div>
                        </div>
                    </mat-expansion-panel>
                </mat-accordion>
                <br />
                <mat-accordion>
                    <mat-expansion-panel>
                        <mat-expansion-panel-header>
                            <mat-panel-title> Control de infecciones </mat-panel-title>
                        </mat-expansion-panel-header>
                        <div class="row">
                            <div class="col-auto" style="width: 100%">
                                <label>Higiene de Manos:</label>
                                <mat-radio-group formControlName="higieneManos" style="width: 100%" >
                                  <mat-radio-button labelPosition="after"  style="font-size: 12px;margin-left: 2%" [value]="true">Si</mat-radio-button>
                                  <mat-radio-button labelPosition="after"  style="font-size: 12px;margin-left: 2%" [value]="false">No</mat-radio-button>
                                  <mat-radio-button labelPosition="after"  style="font-size: 12px;margin-left: 2%" [value]="null">N/A</mat-radio-button>
                              </mat-radio-group>
                            </div>
                            <div class="col-auto" style="width: 100%">
                                <label>Higiene de Manos:</label>
                                    <mat-radio-group formControlName="higieneBucal" style="width: 100%" >
                                      <mat-radio-button labelPosition="after"  style="font-size: 12px;margin-left: 2%" [value]="true">Si</mat-radio-button>
                                      <mat-radio-button labelPosition="after"  style="font-size: 12px;margin-left: 2%" [value]="false">No</mat-radio-button>
                                      <mat-radio-button labelPosition="after"  style="font-size: 12px;margin-left: 2%" [value]="null">N/A</mat-radio-button>
                                  </mat-radio-group>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-auto"style="width: 100%">
                                <label>Cabecera elevada entre 30° y 45°</label>
                                 <mat-radio-group formControlName="cabeceraElevada" style="width: 100%" >
                                    <mat-radio-button labelPosition="after"  style="font-size: 12px;margin-left: 2%" [value]="true">Si</mat-radio-button>
                                    <mat-radio-button labelPosition="after"  style="font-size: 12px;margin-left: 2%" [value]="false">No</mat-radio-button>
                                    <mat-radio-button labelPosition="after"  style="font-size: 12px;margin-left: 2%" [value]="null">N/A</mat-radio-button>
                                  </mat-radio-group>
                             </div>
                             
                              <div class="col-auto" style="width: 100%">
                                   <label>Aspiración subglótica</label>
                                  <mat-radio-group formControlName="aspiracionSubglotica" style="width: 100%" >
                                    <mat-radio-button labelPosition="after"  style="font-size: 12px;margin-left: 2%" [value]="true">Si</mat-radio-button>
                                    <mat-radio-button labelPosition="after"  style="font-size: 12px;margin-left: 2%" [value]="false">No</mat-radio-button>
                                    <mat-radio-button labelPosition="after"  style="font-size: 12px;margin-left: 2%" [value]="null">N/A</mat-radio-button>
                                  </mat-radio-group>
                              </div>
                          </div>
                          <div class="row">
                              <div class="col-auto"style="width: 100%">
                                  <label>Control de la presión del cuff (neumotaponamiento a 20-30 cm h2o)</label>
                                  <mat-radio-group formControlName="controlPresionCuff" style="width: 100%" >
                                    <mat-radio-button labelPosition="after"  style="font-size: 12px;margin-left: 2%" [value]="true">Si</mat-radio-button>
                                    <mat-radio-button labelPosition="after"  style="font-size: 12px;margin-left: 2%" [value]="false">No</mat-radio-button>
                                    <mat-radio-button labelPosition="after"  style="font-size: 12px;margin-left: 2%" [value]="null">N/A</mat-radio-button>
                                </mat-radio-group>
                              </div>
                              <div class="col-auto"style="width: 100%">
                                  <mat-form-field>
                                      <input matInput placeholder="Observación" formControlName="observacionPresionCuff" />
                                  </mat-form-field>
                              </div>
                          </div>
                        <div class="row">
                            <label>Suspensión de sedación:</label>
                            <div class="col-auto" style="width: 100%">
                                <mat-radio-group formControlName="suspensionSedacion" style="width: 100%" >
                                    <mat-radio-button labelPosition="after"  style="font-size: 12px;margin-left: 2%" [value]="true">Si</mat-radio-button>
                                    <mat-radio-button labelPosition="after"  style="font-size: 12px;margin-left: 2%" [value]="false">No</mat-radio-button>
                                    <mat-radio-button labelPosition="after"  style="font-size: 12px;margin-left: 2%" [value]="null">N/A</mat-radio-button>
                                </mat-radio-group>
                             </div>
                        </div>
                    </mat-expansion-panel>
                </mat-accordion>
                <br>
                <alert style="font-size: 12px"></alert>
                <button *ngIf="habilitarEditar(form.value)" style="width: 70px; font-size: 12px; float: right" (click)="deleteEvo(form)" [disabled]="loadingEvolucionEnfermeria" type="button" class="btn btn-xs btn-outline-danger">
          Borrar
        </button>
                <button *ngIf="habilitarEditar(form.value)" style="margin-right: 1%; width: 70px; font-size: 12px; float: right" (click)="submitEvo(form)" [disabled]="loadingEvolucionEnfermeria" type="button" class="btn btn-xs btn-outline-info" type="submit">
          Guardar
        </button>
            </form>
        </mat-expansion-panel>
    </mat-accordion>

    <div class="container" *ngIf="!loadingListado"></div>
</div>