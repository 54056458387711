import { Component, OnInit } from '@angular/core';
import { EvolucionService } from '../_services/evolucion.service';
import { PacienteService } from '../_services/paciente.service';
import { AlertService } from '../_services/alert.service';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { EnfermeriaEvo } from '../_models/enfermeriaEvo.model';
import { Moment } from 'moment';
import {
  MAT_MOMENT_DATE_FORMATS,
  MomentDateAdapter,
  MAT_MOMENT_DATE_ADAPTER_OPTIONS,
} from '@angular/material-moment-adapter';
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
} from '@angular/material/core';

import * as _moment from 'moment';
import { DatosEvolucionEnfermeria } from '../_models/datosEvolucionEnfermeria.model';
import { element } from 'protractor';
import { MatTableDataSource } from '@angular/material/table';
import { CharactersService } from '../_services/characters.service';
// tslint:disable-next-line:no-duplicate-imports

const moment = _moment;

@Component({
  selector: 'app-historial-evolucion-enfermeria',
  templateUrl: './historial-evolucion-enfermeria.component.html',
  styleUrls: ['./historial-evolucion-enfermeria.component.css'],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
    },
    { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS },
  ],
})
export class HistorialEvolucionEnfermeriaComponent implements OnInit {
  currentPatient: any;
  evolucionesForms: UntypedFormGroup[] = [];
  loadingListado = false;
  loadingEvolucionEnfermeria = false;
  opcionesOxigeno = ['mascara', 'canula'];
  maxDate: Date;
  fechaSeleccionada: Moment;
  gradovalores = [
    "1" ,
    "2" ,
    "3"  ,
    "4"  ,
  ];
  //modificacion de upp
  otroValor = false;
  otraZona:String;
  // zonaAux :String;
  listaZonas: any = [
    { value: 'Sacro' } ,
    { value: 'Talones' } ,
    { value: 'Occipital' } ,
    { value: 'Miembros Superiores' } ,
    { value: 'Miembros Inferiores' } ,
    { value: 'Gluteos' } ,
    { value: 'Otros' }
  ]
  columnasUpp = ['zona', 'grado', 'riesgo', 'accion'];
  listaViasUbicacion = ['YAD', 'YAI', 'YPD', 'YPI', 'SCD', 'SCI', 'FD', 'FI', 'CYSCD', 'CYSCI'];

  constructor(
    private pacienteService: PacienteService,
    private alertService: AlertService,
    private formBuilder: UntypedFormBuilder,
    private evolucionService: EvolucionService,
    private _dateAdapter: DateAdapter<any>,
    private characterService: CharactersService
  ) {
    this.maxDate = new Date();
    this.fechaSeleccionada = moment(new Date());
    // this.zonaAux = sessionStorage.getItem('otraZona');
    // this.otraZona = sessionStorage.getItem('zonaIngresada');
    this.pacienteService.currentPatient.subscribe((x) => {
      this.currentPatient = x;
    });
  }

  ngOnInit() {
    this._dateAdapter.setLocale('es_AR');
    this.cargarEvos(this.fechaSeleccionada.format('DD/MM/YYYY'));
    
  }
  onDateChange(event) {
    this.fechaSeleccionada = event.value;
    this.cargarEvos(this.fechaSeleccionada.format('DD/MM/YYYY'));
  }

  cargarEvos(fecha) {
    this.evolucionesForms = [];
    this.loadingListado = true;
    this.evolucionService
      .obtenerHistorialEvolucionEnfermeria({nroCarpeta: this.currentPatient.carpeta, fecha})
      .subscribe((x) => {
        x.forEach((element) => {

    
          const tieneUPP = !element.evolucionEnfermeria.upp;
          
         
          this.evolucionesForms.push(            
            this.formBuilder.group({
              idNota: [element.id],
              versionNota: [element.version],
              id: [element.evolucionEnfermeria.id],
              version: [element.evolucionEnfermeria.version],
              usuario: [element.nombreMedico],
              fecha: [element.fecha],
              toleranciaOral: [element.evolucionEnfermeria.toleranciaOral],
              tto_vo: [element.evolucionEnfermeria.tto_vo],
              avp: [element.evolucionEnfermeria.avp],
              deambulacion: [element.evolucionEnfermeria.deambulacion],
              heridaQx: [element.evolucionEnfermeria.heridaQx],
              lugarHeridaQx: [element.evolucionEnfermeria.lugarHeridaQx],
              peritaltismo: [element.evolucionEnfermeria.peritaltismo],
              evolucion: [this.characterService.reconvertirCaracteresEspeciales(element.evolucionEnfermeria.evolucion)],
              dolor: [element.evolucionEnfermeria.dolor],
              estado: [element.evolucionEnfermeria.estado],
              otros: [element.evolucionEnfermeria.otros],              
              upp:  [element.evolucionEnfermeria.upp],
              zona: [{value: element.evolucionEnfermeria.zona, disabled: tieneUPP }],
              grado: [{value: element.evolucionEnfermeria.grado, disabled: tieneUPP }],
              riesgo: [{value: element.evolucionEnfermeria.riesgo, disabled: tieneUPP }],
              sv: [element.evolucionEnfermeria.sv],
              lavado: [element.evolucionEnfermeria.lavado],
              sng: [element.evolucionEnfermeria.sng],
              k108: [element.evolucionEnfermeria.k108],
              sg: [element.evolucionEnfermeria.sg],
              so: [element.evolucionEnfermeria.so],
              fechaTraqueotomia: [
                this.nuevoMoment(element.evolucionEnfermeria.fechaTraqueotomia),
              ],
              oxigeno: [element.evolucionEnfermeria.oxigeno],
              fechaARM: [this.nuevoMoment(element.evolucionEnfermeria.fechaARM)],
              modoVentilatorio: [element.evolucionEnfermeria.modoVentilatorio],
              vt: [element.evolucionEnfermeria.vt],
              peep: [element.evolucionEnfermeria.peep],
              pi: [element.evolucionEnfermeria.pi],
              fio2: [element.evolucionEnfermeria.fio2],
              higieneManos: [element.evolucionEnfermeria.higieneManos],
              cabeceraElevada: [element.evolucionEnfermeria.cabeceraElevada],
              controlPresionCuff: [element.evolucionEnfermeria.controlPresionCuff],
              observacionPresionCuff: [this.characterService.reconvertirCaracteresEspeciales(element.evolucionEnfermeria.observacionPresionCuff)],
              higieneBucal: [element.evolucionEnfermeria.higieneBucal],
              aspiracionSubglotica: [element.evolucionEnfermeria.aspiracionSubglotica],
              listaUPP: [element.evolucionEnfermeria.listaUPP],
              listaUPPmostrar: [element.evolucionEnfermeria.listaUPP],
              suspensionSedacion:[element.evolucionEnfermeria.suspensionSedacion],
              avc: [element.evolucionEnfermeria.avc],
              cateterDialisis:[element.evolucionEnfermeria.cateterDialisis],
              dataAvc:[element.evolucionEnfermeria.dataAvc],
              dataCateterDialisis:[element.evolucionEnfermeria.dataCateterDialisis],
              formAvc:[],
              formCateterDialisis:[],
            })
             
          );
         
        });

        this.evolucionesForms.forEach((evo) => {

          const lista = evo.controls.listaUPP.value;
          // Para poder distinguir entre las upp ya cargadas y las posibles nuevas upp
          for (let i = 0; i < lista.length; i ++) {
            lista[i].esOriginal=true;
          }
          const listaMostrar = lista.filter(x => !x.borrado);
          evo.controls.listaUPPmostrar.setValue(lista);
          evo.controls.listaUPPmostrar.setValue(listaMostrar);

          if (evo.value.avc) {
            if(evo.value.dataAvc) {
              const fAvc = [
                (evo.value.dataAvc.yai ? 'YAI' : ''),
                (evo.value.dataAvc.yad ? 'YAD' : ''),
                (evo.value.dataAvc.ypd ? 'YPD' : ''),
                (evo.value.dataAvc.ypi ? 'YPI' : ''),
                (evo.value.dataAvc.scd ? 'SCD' : ''),
                (evo.value.dataAvc.sci ? 'SCI' : ''),
                (evo.value.dataAvc.fd ? 'FD' : ''),
                (evo.value.dataAvc.fi ? 'FI' : ''),
                (evo.value.dataAvc.cyscd ? 'CYSCD' : ''),
                (evo.value.dataAvc.cysci ? 'CYSCI' : ''),
              ]
              evo.controls.formAvc.setValue(fAvc);
            }
            else {
              evo.controls.formAvc.setValue([]);
            }
            
          }

          if (evo.value.cateterDialisis) {
            const fCpd = [
              (evo.value.dataCateterDialisis.yai ? 'YAI' : ''),
              (evo.value.dataCateterDialisis.yad ? 'YAD' : ''),
              (evo.value.dataCateterDialisis.ypd ? 'YPD' : ''),
              (evo.value.dataCateterDialisis.ypi ? 'YPI' : ''),
              (evo.value.dataCateterDialisis.scd ? 'SCD' : ''),
              (evo.value.dataCateterDialisis.sci ? 'SCI' : ''),
              (evo.value.dataCateterDialisis.fd ? 'FD' : ''),
              (evo.value.dataCateterDialisis.fi ? 'FI' : ''),
              (evo.value.dataCateterDialisis.cyscd ? 'CYSCD' : ''),
              (evo.value.dataCateterDialisis.cysci ? 'CYSCI' : ''),
            ]
            evo.controls.formCateterDialisis.setValue(fCpd);
          }



        })

        
        this.loadingListado = false;
      });      
  }

  nuevoMoment(fecha) {
    if (fecha != undefined) {
      return moment(fecha);
    } else {
      return null;
    }
  }

  prettyPrintFecha(fecha: string){
   return moment(fecha, 'MMM DD, YYYY, HH:mm:ss A').format('DD/MM/YYYY HH:mm');
  }

  javaFormat(date: Moment) {
    if (date != null) {
      const prueba = new Date(date.year(), date.month(), date.date(), 0, 0, 0);

      return (
        prueba.toLocaleString('en-US', { month: 'short' }) +
        ' ' +
        date.format('DD, YYYY hh:mm:ss A')
      );
    }
    // Jun 10, 2020 12:21:56 PM se saco una coma despues del año porque rompia el back
  }

  habilitarEditar(nota) {
    const diferenciaHoraria =
      (Date.now().valueOf() - new Date(nota.fecha).valueOf()) / 3600000;
    return (
      JSON.parse(sessionStorage.getItem('currentUser')).usuario ==
        nota.usuario && diferenciaHoraria < 24
    );
  }

  submitEvo(form: UntypedFormGroup) {
    if (form.invalid) {
      this.loadingEvolucionEnfermeria = false;
      return;
    }
    
    const id = form.value.idNota;
    form.removeControl('idNota');
    const version = form.value.versionNota;
    form.removeControl('versionNota');

    form.value.fechaTraqueotomia = this.javaFormat(
      form.value.fechaTraqueotomia
    );
    
    form.value.fechaARM = this.javaFormat(
      form.value.fechaARM
    );
    
    // cambio upp a false si la lista esta vacia
    if (form.value.upp && form.value.listaUPPmostrar.length == 0) {
      form.controls.upp.setValue(false);
    }


    if (form.value.avc) {
      let datosAvc;
      if (form.value.dataAvc) { // si ya habia un registro con los campos lo actualizo con lo nuevo
        datosAvc = form.value.dataAvc; 
        datosAvc.yad = form.value.formAvc.includes('YAD');
        datosAvc.yai = form.value.formAvc.includes('YAI');
        datosAvc.ypd = form.value.formAvc.includes('YPD');
        datosAvc.ypi = form.value.formAvc.includes('YPI');
        datosAvc.scd = form.value.formAvc.includes('SCD');
        datosAvc.sci = form.value.formAvc.includes('SCI');
        datosAvc.fd = form.value.formAvc.includes('FD');
        datosAvc.fi = form.value.formAvc.includes('FI');
        datosAvc.cyscd = form.value.formAvc.includes('CYSCD');
        datosAvc.cysci = form.value.formAvc.includes('CYSCI');
      }
      else { // si no lo habia entonces lo creo 
        datosAvc = {
          tipoVia: "avc",
          yad: form.value.formAvc.includes('YAD'),
          yai: form.value.formAvc.includes('YAI'),
          ypd: form.value.formAvc.includes('YPD'),
          ypi: form.value.formAvc.includes('YPI'),
          scd: form.value.formAvc.includes('SCD'),
          sci: form.value.formAvc.includes('SCI'),
          fd: form.value.formAvc.includes('FD'),
          fi: form.value.formAvc.includes('FI'),
          cyscd: form.value.formAvc.includes('CYSCD'),
          cysci: form.value.formAvc.includes('CYSCI'),
        };
      }
      form.controls.dataAvc.setValue(datosAvc);
    }
    

    if (form.value.cateterDialisis) {
      let datosCpd;
      if (form.value.dataCateterDialisis) {
        datosCpd = form.value.dataCateterDialisis;
        datosCpd.yad = form.value.formCateterDialisis.includes('YAD');
        datosCpd.yai = form.value.formCateterDialisis.includes('YAI');
        datosCpd.ypd = form.value.formCateterDialisis.includes('YPD');
        datosCpd.ypi = form.value.formCateterDialisis.includes('YPI');
        datosCpd.scd = form.value.formCateterDialisis.includes('SCD');
        datosCpd.sci = form.value.formCateterDialisis.includes('SCI');
        datosCpd.fd = form.value.formCateterDialisis.includes('FD');
        datosCpd.fi = form.value.formCateterDialisis.includes('FI');
        datosCpd.cyscd = form.value.formCateterDialisis.includes('CYSCD');
        datosCpd.cysci = form.value.formCateterDialisis.includes('CYSCI');
      }
      else {
        datosCpd = {
          tipoVia: "cateterDialisis",
          yad: form.value.formCateterDialisis.includes('YAD'),
          yai: form.value.formCateterDialisis.includes('YAI'),
          ypd: form.value.formCateterDialisis.includes('YPD'),
          ypi: form.value.formCateterDialisis.includes('YPI'),
          scd: form.value.formCateterDialisis.includes('SCD'),
          sci: form.value.formCateterDialisis.includes('SCI'),
          fd: form.value.formCateterDialisis.includes('FD'),
          fi: form.value.formCateterDialisis.includes('FI'),
          cyscd: form.value.formCateterDialisis.includes('CYSCD'),
          cysci: form.value.formCateterDialisis.includes('CYSCI'),
        }
      }
      form.controls.dataCateterDialisis.setValue(datosCpd);
  }

    const ef = new EnfermeriaEvo(form.value, id, version);
    this.setDatosGenerales(ef);
    this.evolucionService.enviarEvolucionEnfermeria(ef).subscribe(
      (x) => {
        //console.log(x);
        this.alertService.success('La evolucion se edito correctamente');
        this.loadingEvolucionEnfermeria = false;
      }, (error) => {
        this.alertService.error('Ocurrio un error, intente mas tarde');
      }
    );
    window.location.reload(); // reload para que no hayan errores si se quiere volver a editar
  }

  deleteEvo(form) {
    const id = form.value.idNota;
    form.removeControl('idNota');
    const version = form.value.versionNota;
    form.removeControl('versionNota');
    form.value.fechaTraqueotomia = this.javaFormat(
      form.value.fechaTraqueotomia
    );
    const ef = new EnfermeriaEvo(form.value, id, version);
    this.evolucionService.borrarEvolucion(ef).subscribe(
      (x) => {
        //console.log(x);
        this.cargarEvos(this.fechaSeleccionada.format('DD/MM/YYYY'));
      },
      (error) => {
        console.log(error);
      }
    );
  }

  setDatosGenerales(evolucion) {
  
    evolucion.nombreMedico = JSON.parse(
      sessionStorage.getItem('currentUser')
    ).nombreCompleto;
    evolucion.numeroDocumento = this.currentPatient.dniPaciente;
    evolucion.tipoDocumento = this.currentPatient.tipoDniPaciente;
    evolucion.carpeta = this.currentPatient.carpeta;
  }

    changed(index){
        if(this.evolucionesForms[index].controls.upp.value){
          //Activo los input
          //console.log(this.evolucionesForms[index].get('zona'))
          this.evolucionesForms[index].controls.zona.enable();
          this.evolucionesForms[index].controls.grado.enable();
          this.evolucionesForms[index].controls.riesgo.enable();
          this.evolucionesForms[index].controls.upp.setValue(true);
        } else {
          //Limpio los input y los deshabilito 
          this.evolucionesForms[index].controls.zona.disable();
          this.evolucionesForms[index].controls.zona.setValue('');
          this.evolucionesForms[index].controls.grado.disable();
          this.evolucionesForms[index].controls.grado.setValue('');
          this.evolucionesForms[index].controls.riesgo.disable();
          this.evolucionesForms[index].controls.riesgo.setValue('');
          const lista = this.evolucionesForms[index].value.listaUPP.slice();
          for (let i = 0; i < lista.length; i ++) {
            if (lista[i].esOriginal) {
              lista[i].borrado = true;
            }
          }
          this.evolucionesForms[index].controls.listaUPP.setValue(lista.filter(x => x.esOriginal));
          this.evolucionesForms[index].controls.listaUPPmostrar.setValue([]);
          this.evolucionesForms[index].controls.upp.setValue(false);
        }
    }
    //Zonas del upp, chequeo y agregado de una nueva zona
  // agregarZona(valor){
  //   let listaZonaAux: any[]=[];
  //   listaZonaAux.push({value: valor});
  //   this.listaZonas.push.apply(this.listaZonas,listaZonaAux);
  //   this.evolucionesForms[0].controls.zona.setValue(valor)
    
  //  //  console.log(this.evolucionEnfermeriaForm.get('zona'))
   
  //  }
  //  chequeoValor(valor){
  //   this.zonaAux= this.evolucionesForms[0].get('zona').value;
  //   if(this.zonaAux == 'Otros' ){
  //     this.otroValor = true;
  //     // this.otraZona = this.evolucionesForms[0].get('zona').value;
  //   }
  //   if(valor.value != 'Otros'){
  //     this.otroValor= false;
  //   }


      // let zonaValor= this.listaZonas.filter((zona) => {
      //   if (valor === zona.value) {
      //     return zona;
      //   }
      // });  
      // if(zonaValor.length > 0)   
      //   this.listaZonas= zonaValor[0]; 
      // else{
      //   this.otroValor=true;
      //   this.listaZonas.push({value: 'Otros'});


      // }

  
    //   if(valor.value == 'Otros'){

    //     this.otroValor = true;
    //   }
    //  else{
    //    this.otroValor = false;
    //  }
    //  console.log(this.otroValor)


    eliminarUpp(upp, indice) {
      const listaVieja = this.evolucionesForms[indice].controls.listaUPPmostrar.value.slice();
      const iupp = listaVieja.indexOf(upp);
      listaVieja.splice(iupp, 1);
      if (upp.esOriginal) {
        // si es una upp ya persistida cambio el valor del borrado y la saco de la lista que se muestra en pantalla
        upp.borrado = true;
      }
      else {
        // si es una upp recien agregada la busco en las dos listas y las borro en ambos lados
        const lista =  this.evolucionesForms[indice].controls.listaUPP.value.slice();
        lista.splice(lista.indexOf(upp), 1);
        this.evolucionesForms[indice].controls.listaUPP.setValue(lista);
      }
      this.evolucionesForms[indice].controls.listaUPPmostrar.setValue(listaVieja);

    }

    guardarUpp(indice) {
      if (this.evolucionesForms[indice].controls.zona.valid && this.evolucionesForms[indice].controls.riesgo.valid) {
        if (this.evolucionesForms[indice].controls.zona.value && this.evolucionesForms[indice].controls.grado.value) {
          const upp = {
            zona: this.evolucionesForms[indice].controls.zona.value,
            grado: this.evolucionesForms[indice].controls.grado.value,
            riesgo: this.evolucionesForms[indice].controls.riesgo.value,
            borrado: false,
            esOriginal: false,
          }
          const listaUpp = this.evolucionesForms[indice].controls.listaUPP.value.slice(); // todas las upp
          listaUpp.push(upp);
          const listaUppmostrar = this.evolucionesForms[indice].controls.listaUPPmostrar.value.slice(); // las upp que no estan eliminadas
          listaUppmostrar.push(upp);
          this.evolucionesForms[indice].controls.zona.setValue('');
          this.evolucionesForms[indice].controls.grado.setValue('');
          this.evolucionesForms[indice].controls.riesgo.setValue('');
          /*if (this.otroValor) {
            this.otroValor = false;
            this.otraZona = ''; 
          }*/
          this.evolucionesForms[indice].controls.listaUPP.setValue(listaUpp);
          this.evolucionesForms[indice].controls.listaUPPmostrar.setValue(listaUppmostrar);
        }
        
      }
      else {
        return;
      }
  
    }

    changeAvc(indice) {
      if (! this.evolucionesForms[indice].value.avc) {
        this.evolucionesForms[indice].controls.formAvc.setValue([]);
        this.evolucionesForms[indice].controls.formAvc.setValidators(Validators.nullValidator);
      }
      else { // este else sirve para que cuando se active el checkbox, sea necesario completar el select
        this.evolucionesForms[indice].controls.formAvc.setValidators(Validators.required);
      }
      this.evolucionesForms[indice].controls.formAvc.updateValueAndValidity();
    }

    changeCpd(indice) {
      if (! this.evolucionesForms[indice].value.cateterDialisis) {
        this.evolucionesForms[indice].controls.formCateterDialisis.setValue([]);
        this.evolucionesForms[indice].controls.formCateterDialisis.setValidators(Validators.nullValidator);
      }
      else {
        this.evolucionesForms[indice].controls.formCateterDialisis.setValidators(Validators.required);
      }
      this.evolucionesForms[indice].controls.formCateterDialisis.updateValueAndValidity();
    }



   }

