<footer *ngIf="!currentUser" class="footer">    
    <div class="container" style="text-align:center;font-size: 13px; height: 40px;   ">Copyright ® 2019 | Sitio de enfermeria v1.0</div>
</footer>
<mat-toolbar *ngIf="currentUser" >

    <span class="example-spacer"></span>
    <span>
            <button  mat-button  ><mat-icon>person</mat-icon></button>
    </span>
</mat-toolbar>

